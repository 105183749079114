import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { InvestmentsService } from "@services/investments.service";
import { Asset, Investment, PriceSheet, setLocalStorageItem } from "@structs";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "price-sheets-list",
  templateUrl: "./price-sheets-list.component.html",
  styleUrls: ["./price-sheets-list.component.scss"],
})
export class PriceSheetsListComponent implements OnInit {
  @Input() asset: Asset;
  @Input() investment: Investment;
  public priceSheets$: Observable<PriceSheet[]>;
  public selectedPriceSheetId: number;
  @Input() addMode: boolean = false;

  constructor(
    private investmentsApi: InvestmentsService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.priceSheets$ = this.investmentsApi.getPriceSheets().pipe(
      map((priceSheets) =>
        priceSheets.filter(
          (priceSheet) =>
            this.asset && priceSheet.asset_type === this.asset.assetType.id
        )
      ),
      tap((priceSheets) => this.initSelectedPriceSheet(priceSheets))
    );
  }

  private initSelectedPriceSheet(priceSheets: PriceSheet[]) {
    if (this.investment.priceSheet) {
      const foundPriceSheet: PriceSheet = priceSheets.find(
        (priceSheet) => priceSheet.id === this.investment.priceSheet
      );
      if (foundPriceSheet) {
        this.selectedPriceSheetId = foundPriceSheet.id;
      }
    } else if (this.addMode) {
      const defaultPriceSheet: PriceSheet = priceSheets.find(
        (priceSheet) => priceSheet.is_default
      );
      if (defaultPriceSheet) {
        this.selectedPriceSheetId = defaultPriceSheet.id;
        this.save(this.selectedPriceSheetId);
      }
    }
  }

  selectPriceSheet(priceSheetId: number) {
    if (priceSheetId === this.selectedPriceSheetId) {
      this.selectedPriceSheetId = null;
    } else {
      if (
        this.investment.investmentBudgetOrigin &&
        this.investment.investmentBudgetOrigin.price_list
      ) {
        this.showUdatePriceAlert();
      }
      this.selectedPriceSheetId = priceSheetId;
    }

    this.save(this.selectedPriceSheetId);
  }

  async showUdatePriceAlert() {
    const updatePriceAlert = await this.alertCtrl.create({
      message: this.translate.instant(
        "Please update the replacement price with the new price sheet"
      ),
      buttons: ["Ok"],
    });
    await updatePriceAlert.present();
  }

  public openPriceSheet(priceSheet: PriceSheet, event) {
    event.preventDefault();
    this.router.navigate(["price-sheet-detail", priceSheet.id]);
  }

  save(selectedPriceSheetId: number) {
    if (this.addMode) {
      setLocalStorageItem("priceSheetOfNewInvestment", selectedPriceSheetId);
    } else {
      let data = {};
      data["price_sheet"] = selectedPriceSheetId;
      this.investmentsApi
        .patchInvestment(this.asset, this.investment, data)
        .subscribe(
          (investment) => {
            console.log("updated invest", investment);
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  /**
   * We show the quantity only if the unit is different from "unit"
   * and quantity is greater that 1.
   */
  showQuantity(priceSheet: PriceSheet): boolean {
    return (
      this.asset.assetType.quantity_unit &&
      !this.asset.assetType.quantity_unit.is_default &&
      priceSheet.quantity &&
      priceSheet.quantity > 1
    );
  }
}
