import { Injectable } from "@angular/core";
import { OfflineService } from "./offline.service";
import { SynchronizationService } from "./synchronization.service";
import { AuthService } from "./auth.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { BackendService } from "./backend.service";
import { filter, map, switchMap, tap } from "rxjs/operators";
import {
  AssetEnergyConsumption,
  AssetEnergyConsumptionPayload,
  MultiPerimeterEnergyTrajectory,
  PerimeterEnergyTrajectory,
} from "../structs/initiative";
import {
  makeChange,
  putAssetEnergyConsumption,
} from "../structs/synchronization";

@Injectable()
export class InitiativeService {
  private readonly perimetersEnergyTrajectories = new BehaviorSubject<
    MultiPerimeterEnergyTrajectory[]
  >([]);
  perimetersEnergyTrajectories$ =
    this.perimetersEnergyTrajectories.asObservable();

  constructor(
    private offlineService: OfflineService,
    private syncService: SynchronizationService,
    private authService: AuthService,
    private backendService: BackendService
  ) {
    this.offlineService
      .getPerimeterEnergyTrajectories()
      .pipe(
        tap((trajectories) =>
          this.perimetersEnergyTrajectories.next(trajectories)
        )
      )
      .subscribe();
  }

  updateAssetConsumption(
    multiPerimeterId: number,
    monoPerimeterId: number,
    trajectory: number,
    perimeterConsumptionId: number,
    data: AssetEnergyConsumptionPayload
  ): Observable<AssetEnergyConsumptionPayload> {
    const url = `/initiatives/api/asset-energy-consumption/${perimeterConsumptionId}/`;
    const change = makeChange(putAssetEnergyConsumption, url, "post", data);
    return this.syncService.addChange(change).pipe(
      switchMap(() =>
        this.updateAssetConsumptionLocal(
          multiPerimeterId,
          monoPerimeterId,
          trajectory,
          perimeterConsumptionId,
          {
            ...data,
            perimeter_energy_consumption_id: perimeterConsumptionId,
          }
        )
      ),
      switchMap(() => this.syncService.signalOfflineChanges()),
      map(() => data)
    );
  }

  /**
   * handle nested logic to update (offline) one asset energy consumption
   * Read this before make the change:
   * https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#updating-an-item-in-an-array
   */
  private updateAssetConsumptionLocal(
    multiPerimeterId: number,
    monoPerimeterId: number,
    trajectory: number,
    perimeterConsumptionId: number,
    data: AssetEnergyConsumption
  ): Observable<boolean> {
    const updatedEnergyTrajectory = this.perimetersEnergyTrajectories.value.map(
      (multiPerimeter) => {
        function addOrUpdateAssetConsumption(
          assetConsumptions: AssetEnergyConsumption[],
          assetConsumption: AssetEnergyConsumption
        ) {
          const matchedConsumption = assetConsumptions.findIndex(
            (consumption) => {
              return (
                (consumption.asset_id &&
                  consumption.asset_id === assetConsumption.asset_id) ||
                (consumption.assetOfflineId &&
                  consumption.assetOfflineId ===
                    assetConsumption.assetOfflineId)
              );
            }
          );
          if (matchedConsumption > -1) {
            assetConsumptions[matchedConsumption] = assetConsumption;
            return assetConsumptions;
          }
          return [assetConsumption, ...assetConsumptions];
        }

        if (multiPerimeter.multi_perimeter_id !== multiPerimeterId) {
          return multiPerimeter;
        }

        return {
          ...multiPerimeter,
          perimeters: multiPerimeter.perimeters.map((monoPerimeter) => {
            if (monoPerimeter.id !== monoPerimeterId) return monoPerimeter;

            return {
              ...monoPerimeter,
              trajectories: monoPerimeter.trajectories.map(
                (perimeterEnergyTrajectory) => {
                  if (perimeterEnergyTrajectory.id !== trajectory)
                    return perimeterEnergyTrajectory;
                  return {
                    ...perimeterEnergyTrajectory,
                    perimeter_consumptions:
                      perimeterEnergyTrajectory.perimeter_consumptions.map(
                        (perimeterConsumption) => {
                          if (
                            perimeterConsumption.id !== perimeterConsumptionId
                          )
                            return perimeterConsumption;
                          const asset_consumptions =
                            addOrUpdateAssetConsumption(
                              perimeterConsumption.asset_consumptions,
                              data
                            );
                          return {
                            ...perimeterConsumption,
                            asset_consumptions,
                          };
                        }
                      ),
                  };
                }
              ),
            };
          }),
        };
      }
    );
    return this.offlineService
      .setPerimeterEnergyTrajectories(updatedEnergyTrajectory)
      .pipe(
        tap(() =>
          this.perimetersEnergyTrajectories.next(updatedEnergyTrajectory)
        )
      );
  }

  energyTrajectoryForMultiPerimeters$(
    perimeterId: number
  ): Observable<MultiPerimeterEnergyTrajectory> {
    return this.perimetersEnergyTrajectories$.pipe(
      filter((pets) => !!pets),
      map((pets) => pets.find((pet) => pet.multi_perimeter_id === perimeterId))
    );
  }

  energyTrajectoryForMonoPerimeter$(
    multiPerimeterId: number,
    monoPerimeterId: number
  ): Observable<PerimeterEnergyTrajectory[]> {
    return this.energyTrajectoryForMultiPerimeters$(multiPerimeterId).pipe(
      filter((multiPerim) => !!multiPerim),
      filter((multiPerime) => !!multiPerime.perimeters),
      map((ETMulti) =>
        ETMulti.perimeters.filter((perim) => perim.id === monoPerimeterId)
      ),
      filter((ETMonoList) => !!ETMonoList),
      map((ETMonoList) =>
        ETMonoList.reduce((acc, val) => acc.concat(val.trajectories), [])
      )
    );
  }

  /**
   * Safely fetch the newest version of PerimetersEnergyTrajectory[]
   * and save it locally.
   * It is ensured to have empty the PendingChanges
   */
  fetchPerimetersEnergyTrajectoryAndSaveLocally(): Observable<boolean> {
    return this.syncService.pushOfflineChanges().pipe(
      switchMap((allChangesProcessed) => {
        if (!allChangesProcessed) {
          return of(false);
        }
        return this.fetchEnergyTrajectoryForAllPerimeters().pipe(
          tap((energyTrajectory) =>
            this.perimetersEnergyTrajectories.next(energyTrajectory)
          ),
          switchMap((energyTrajectory) =>
            this.offlineService.setPerimeterEnergyTrajectories(energyTrajectory)
          )
        );
      })
    );
  }

  private fetchEnergyTrajectoryForAllPerimeters(): Observable<
    MultiPerimeterEnergyTrajectory[]
  > {
    const url = "/initiatives/api/energy-consumption/";
    return this.backendService.get(url);
  }

  fetchEnergyTrajectoryForSinglePerimeter(
    perimeterId: number
  ): Observable<MultiPerimeterEnergyTrajectory[]> {
    const url = `${perimeterId}`;
    return this.backendService.get(url);
  }
}
