import * as R from "ramda";

import { AppUser } from "./base";
import {
  getPerimeterElementsReferenceData,
  Perimeter,
  PerimeterReferenceData,
  ReferenceDataValue,
  ReferenceField,
  ReferenceFieldDataFiltering,
  ReferenceFieldDataType,
} from "./assets";
import {
  DataType,
  FilterType,
  PerimeterFilter,
  PerimeterFilterRangeValue,
  PerimeterPredicate,
} from "./perimeter-filter";

/**
 * Represent a perimeter filter associated to a reference data.
 */
export class PerimeterReferenceDataFilter extends PerimeterFilter {
  public referenceField: ReferenceField;

  constructor(
    referenceField: ReferenceField,
    perimeters: Perimeter[],
    users?: AppUser[]
  ) {
    super();

    this.referenceField = referenceField;
    this.label = this.referenceField.label;

    switch (this.referenceField.dataType) {
      case ReferenceFieldDataType.DATA_TYPE_FK:
        this.dataType = DataType.DATA_TYPE_FK;
        break;
      case ReferenceFieldDataType.DATA_TYPE_NUMBER:
        this.dataType = DataType.DATA_TYPE_NUMBER;
        break;
      case ReferenceFieldDataType.DATA_TYPE_STRING:
        this.dataType = DataType.DATA_TYPE_STRING;
        break;
      case ReferenceFieldDataType.DATA_TYPE_USER:
        this.dataType = DataType.DATA_TYPE_USER;
        break;
      default:
        this.dataType = null;
        break;
    }

    switch (this.referenceField.filtering) {
      case ReferenceFieldDataFiltering.FILTER_RANGE:
        this.filtering = FilterType.FILTER_RANGE;
        break;
      case ReferenceFieldDataFiltering.FILTER_VALUE:
        this.filtering = FilterType.FILTER_VALUE;
        break;
      default:
        this.filtering = null;
        break;
    }

    // Get all values present on those perimeters for this field
    const refDataElements = perimeters.reduce(
      (elements, perimeter) => [
        ...elements,
        ...getPerimeterElementsReferenceData(perimeter, referenceField.varName),
      ],
      []
    );
    const refDataElementsValues = R.reject(
      Array.isArray,
      R.pluck("value", refDataElements)
    ); // Remove multi-valued values coming from summed fields

    if (refDataElementsValues.length > 0) {
      // Compute possible values for the filter
      if (
        referenceField.filtering === ReferenceFieldDataFiltering.FILTER_VALUE
      ) {
        switch (referenceField.dataType) {
          // String/number data type: take all possible values
          case ReferenceFieldDataType.DATA_TYPE_STRING:
          case ReferenceFieldDataType.DATA_TYPE_NUMBER:
            this.availableValues = R.uniq(refDataElementsValues).sort();
            break;
          // Foreign key data type: take the pre-defined values
          case ReferenceFieldDataType.DATA_TYPE_FK:
            this.availableValues = this.referenceField.values;
            break;
          // User data type: take users as possible values
          case ReferenceFieldDataType.DATA_TYPE_USER:
            this.availableValues = users;
            break;
        }
        // Range filtering: take the min and max of the present values
      } else if (
        referenceField.filtering === ReferenceFieldDataFiltering.FILTER_RANGE
      ) {
        // If the refDataElementsValues contains empty values, we add a "not defined" range
        let hasEmptyValue = refDataElementsValues.includes("");
        this.availableValuesRange = PerimeterFilterRangeValue.getRanges(
          Math.min(...refDataElementsValues),
          Math.max(...refDataElementsValues),
          3,
          hasEmptyValue
        );
      }
    }
  }

  public getPredicate(): PerimeterPredicate {
    const currentYear = new Date().getFullYear();
    const referenceDataPredicate = this.getReferenceDataPredicate();

    return (perimeter: Perimeter) => {
      const perimeterRefData = perimeter.refData
        // Get the reference data for this field, less or equal to the current year
        .filter(
          (perimeterRefData) =>
            perimeterRefData.varName === this.referenceField.varName &&
            perimeterRefData.year <= currentYear
        )
        // Sort by year in descending order
        .sort((a, b) => b.year - a.year)[0]; // Take the first one (the closest to the current year)
      return perimeterRefData && referenceDataPredicate(perimeterRefData);
    };
  }

  private getReferenceDataPredicate(): (r: PerimeterReferenceData) => boolean {
    return (perimeterRefData: PerimeterReferenceData) => {
      if (
        this.referenceField.filtering ===
        ReferenceFieldDataFiltering.FILTER_VALUE
      ) {
        switch (this.referenceField.dataType) {
          case ReferenceFieldDataType.DATA_TYPE_STRING:
          case ReferenceFieldDataType.DATA_TYPE_NUMBER:
            return R.includes(perimeterRefData.value, this.selectedValues);
          case ReferenceFieldDataType.DATA_TYPE_FK:
            return R.includes(
              perimeterRefData.filterValue,
              this.selectedValues.map(
                (refDataValue: ReferenceDataValue) => refDataValue.id
              )
            );
          case ReferenceFieldDataType.DATA_TYPE_USER:
            return R.includes(
              perimeterRefData.filterValue,
              this.selectedValues.map((user: AppUser) => user.get_user_id)
            );
        }
      } else if (
        this.referenceField.filtering ===
        ReferenceFieldDataFiltering.FILTER_RANGE
      ) {
        const bounds = this.selectedValueRange;
        if (bounds.label !== "") {
          return (
            perimeterRefData.value !== "" && // We don't return empty values unless the filter is set to empty
            perimeterRefData.value >= bounds.lower &&
            perimeterRefData.value <= bounds.upper
          );
        } else {
          return perimeterRefData.value === "";
        }
      }
    };
  }
}
