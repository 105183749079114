import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import lunr from "lunr";
import { Observable, combineLatest } from "rxjs";
import { AssetType, SubCategory } from "../structs/assets";

import { Chapter, Roadmap } from "../structs/roadmap";
import { cleanFilterString } from "../structs/utils";
import { AssetsService } from "./assets.service";
import { FullTextSearchService } from "./full-text-search.service";

interface IndexedChapter {
  id: number;
  subject: string;
  label: string;
  items: string;
  itemsInformation: string;
}

@Injectable()
export class RoadmapSearchService {
  private subCategories: SubCategory[] = [];
  private assetTypes: AssetType[] = [];

  constructor(
    private fullTextSearchService: FullTextSearchService,
    private assetApi: AssetsService
  ) {
    this.assetApi.getCategories().subscribe((categories) => {
      categories.forEach((category) => {
        category.children.forEach((subCategory) => {
          this.subCategories.push(subCategory);
          subCategory.children.forEach((assetType) => {
            this.assetTypes.push(assetType);
          });
        });
      });
    });
  }

  /**
   * Turns a roadmap chapter into an indexable chapter.
   *
   * @param chapter The chapter to transform.
   *
   * @returns An indexable chapter.
   */
  private static getIndexedChapter(chapter: Chapter): IndexedChapter {
    return {
      id: chapter.id,
      subject: chapter.subject,
      label: chapter.label,
      items: chapter.items.map((item) => item.text).join(" "),
      itemsInformation: chapter.items.map((item) => item.information).join(" "),
    };
  }

  /**
   * Loads a saved index from storage.
   * If not found, creates the index.
   *
   * @param roadmap The roadmap to create an index for.
   *
   * @returns An `Observable` resolving to a Lunr index.
   */
  public loadIndex(roadmap: Roadmap): Observable<lunr.Index> {
    const indexKey = this.getIndexKey(roadmap);
    return this.fullTextSearchService
      .loadIndex(indexKey)
      .pipe(catchError(() => this.createIndex(roadmap)));
  }

  /**
   * Creates an index for the roadmap.
   *
   * @param roadmap The roadmap to create an index for.
   *
   * @returns An `Observable` resolving to a Lunr index.
   */
  public createIndex(roadmap: Roadmap): Observable<lunr.Index> {
    const indexKey = this.getIndexKey(roadmap);
    const fullTextSearchService = this.fullTextSearchService;

    return new Observable((observer) => {
      const index = lunr(function () {
        fullTextSearchService.configureLunrIndex(this);

        this.ref("id");
        this.field("subject");
        this.field("label");
        this.field("items");
        this.field("itemsInformation");

        for (const chapter of roadmap.chapters) {
          this.add(RoadmapSearchService.getIndexedChapter(chapter));
        }
      });

      observer.next(index);
      observer.complete();

      fullTextSearchService.saveIndex(indexKey, index);
    });
  }

  /**
   * Performs a search on a specific roadmap.
   *
   * @param perimeterId The perimeter id. of the roadmap to search in.
   * @param query The user query.
   *
   * @returns A list of matching Chapter ids ordered by relevance.
   */
  public search(roadmap: Roadmap, query: string): Observable<number[]> {
    const indexKey = this.getIndexKey(roadmap);
    let assetTypeSearchObservable = this.getMatchingChaptersIdsFromAssetTypes(
      roadmap,
      query
    );
    return new Observable((observer) => {
      combineLatest(
        this.fullTextSearchService.search(indexKey, query),
        assetTypeSearchObservable
      ).subscribe(([fullTextSearch, assetTypeSearch]) => {
        observer.next([].concat(fullTextSearch, assetTypeSearch));
        observer.complete();
      });
    });
  }

  /**
   * Generates a key for a roadmap index.
   *
   * @param roadmap The roadmap concerned by the index.
   *
   * @returns The index key.
   */
  private getIndexKey(roadmap: Roadmap): string {
    return `roadmap-search-index:${roadmap.perimeterId}`;
  }

  /**
   * Searching asset types from a keyword
   * @param query
   * @returns
   */
  private searchAssetTypes(query: string): AssetType[] {
    const searchText = cleanFilterString(query);
    let matchingAssetTypes = this.assetTypes.filter((assetType) => {
      let matchingKeywords = assetType.keywords.find((keyword) => {
        return keyword.name.includes(searchText);
      });
      if (!!matchingKeywords) return assetType;
    });
    return matchingAssetTypes;
  }

  /**
   * Getting a list of roadmap chapters ids from assetTypes keywords
   * @param roadmap
   * @param query asset types keywords
   * @returns
   */
  public getMatchingChaptersIdsFromAssetTypes(
    roadmap: Roadmap,
    query: string
  ): Observable<number[]> {
    return new Observable((observer) => {
      // We search the asset types matching the keywords
      let assetTypesFromKeywords = this.searchAssetTypes(query);
      let assetTypesIdsFromKeywords: number[] = [];
      let subCategoriesIds: number[] = [];

      assetTypesFromKeywords.map((assetType) => {
        assetTypesIdsFromKeywords.push(assetType.id);
        // For each asset type, we get the subCategories that include it.
        this.subCategories
          .filter((subCategory) => subCategory.children.includes(assetType))
          .map((subCat) => subCategoriesIds.push(subCat.id));
      });
      // Searching the chapters containing items where the instructionAssetType matches
      // the assetTypes list that we already have (assetTypesIdsFromKeywords).
      let matchingChapters = roadmap.chapters
        .filter((chapter) => {
          let matchingItems = [];
          chapter.items.map((item) => {
            if (item.instructionAssetType) {
              if (
                assetTypesIdsFromKeywords.includes(item.instructionAssetType.id)
              ) {
                matchingItems.push(item);
              }
            } else if (item.instructionSubCategory) {
              // If no instructionAssetType is set for this item, we take the
              // instructionSubCategory field and we search for a match in the subCategories list
              // that we already have.
              if (subCategoriesIds.includes(item.instructionSubCategory.id)) {
                matchingItems.push(item);
              }
            }
          });
          return matchingItems.length > 0;
        })
        .map((chapter) => chapter.id);
      observer.next(matchingChapters);
      observer.complete();
    });
  }
}
