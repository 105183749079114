<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBackToDocumentPage()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}{{ 'Add a document' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="full">
    <input-item [legend]="'Label' | translate">
      <ion-textarea
        auto-grow
        [(ngModel)]="label"
        placeholder="-"
      ></ion-textarea>
    </input-item>

    <input-item [legend]="'Document type' | translate">
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        [(ngModel)]="selectedDocumentTypeId"
        (ngModelChange)="somethingChanged()"
        [disabled]="docTypeId !== null"
      >
        <ion-select-option
          *ngFor="let docType of documentTypes"
          [value]="docType.id"
          >{{ docType.label }}</ion-select-option
        >
      </ion-select>
    </input-item>

    <input-item
      *ngIf="!investmentId && !assetId"
      [legend]="'Perimeter' | translate"
    >
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        [(ngModel)]="selectedPerimeterId"
        (ngModelChange)="somethingChanged()"
        [disabled]="!!fixedPerimeterId"
      >
        <ion-select-option
          *ngFor="let perimeter of perimeters"
          [value]="perimeter.id"
          >{{ perimeter.name }}</ion-select-option
        >
      </ion-select>
    </input-item>

    <input-item [legend]="'Link' | translate">
      <ion-input [(ngModel)]="link" placeholder="-"></ion-input>
    </input-item>

    <input-item [legend]="'Created by' | translate ">
      <ion-input
        readonly
        value="{{ created_by }} {{ created | date:'mediumDate' }}"
      >
      </ion-input>
    </input-item>

    <ion-row class="grey-divider">
      <ion-col>
        <ion-note color="primary">{{ "Optional" | translate }}</ion-note>
      </ion-col>
    </ion-row>

    <ion-item>
      <ion-input
        [(ngModel)]="expiry_date"
        id="expiry_date"
        class="ion-text-end"
        type="date"
        ><ion-label color="primary"
          >{{ 'Expiry date' | translate }}</ion-label
        ></ion-input
      >
      <!-- <ion-input
        value="{{ expiry_date | date: 'dd.MM.yyyy' }}"
        id="expiry_date"
        class="ion-text-end"
      >
      </ion-input>
      <ion-popover
        trigger="expiry_date"
        aligment="start"
        side="top"
        size="auto"
      >
        <ng-template>
          <ion-content>
            <ion-datetime
              displayFormat="MM YYYY"
              presentation="date"
              [min]="getMinDate()"
              [max]="getMaxDate()"
              doneText="{{ 'OK' | translate }}"
              cancelText="{{ 'Cancel' | translate }}"
              [(ngModel)]="expiry_date"
            >
            </ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover> -->
    </ion-item>

    <input-item [legend]="'Comments' | translate">
      <ion-textarea
        autosize
        [(ngModel)]="comments"
        placeholder="-"
      ></ion-textarea>
    </input-item>
  </ion-list>
</ion-content>

<app-footer-next-button
  nextButton
  (next)="save($event)"
  [label]="'save'"
  [enabled]="( !!investmentId || !!selectedPerimeterId|| !!assetId ) && !!selectedDocumentTypeId && label !== '' && link !== '' && label !== null && link !== null"
  color="document"
></app-footer-next-button>
