<div class="camera-content">
  <div class="camera-container"><i class="fal fa-3x fa-camera"></i></div>
  <button
    ion-button
    color="primary"
    (click)="onCameraClicked()"
    class="camera-button"
  >
    {{
      "You need to take a picture of the equipment before proceeding. Please click to open the camera."
        | translate
    }}
  </button>
</div>

<div class="pictures-list">
  <app-picture
    *ngFor="let picture of pictures"
    [picture]="picture"
  ></app-picture>
</div>
<input
  *ngIf="inBrowser"
  #browserFileUpload
  type="file"
  (click)="browserFileUpload.value = null"
  (change)="addBrowserPicture($event)"
  style="display: none"
  accept="image/*"
  multiple
/>
