import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { forkJoin, Subscription } from "rxjs";
import { User } from "../../structs/auth";
import { Settings, SettingsService } from "../../services/settings.service";
import {
  getPicturesLoadingPermission,
  setPicturesLoadingPermission,
} from "../../structs/utils";
import {
  Change,
  SynchronizationState,
  SynchronizationStatus,
} from "../../structs/synchronization";
import { SynchronizationService } from "../../services/synchronization.service";
import { TranslateService } from "@ngx-translate/core";
import { ErrorsService } from "../../services/errors.service";
import { EventService } from "../../services";
import { SuccessToastService } from "../../services/success-toast.service";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { MenuController, Platform, ToastController } from "@ionic/angular";
import { BrowserService } from "src/app/services/browser.service";
import { InformationService } from "src/app/services/information.service";
import { EmailComposer } from "@awesome-cordova-plugins/email-composer/ngx";
import { Environment } from "src/app/app.environment";
import { switchMap, tap } from "rxjs/operators";
import * as JSZip from "jszip";
import { File, FileEntry } from "@awesome-cordova-plugins/file/ngx";
import { SaveToLocalService } from "../../services";
import { StorageService } from "@services/storage.service";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnInit, AfterViewInit {
  user: User;

  userDisplayName: string;

  settings: Settings;

  subscriptions: Subscription[] = [];

  loadPictures = false;

  synchroEnabled = true;

  fullSize = 0;

  confirmTitle: string = "";
  confirmMessage: string = "";
  okButton: string = "";
  cancelButton: string = "";
  progressMessage: string = "";
  lastHelloDelay: number;
  changesCount: number;
  forceSynchroEnabled: any;
  darkTheme: boolean = false;

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private syncApi: SynchronizationService,
    private translate: TranslateService,
    private events: EventService,
    private errors: ErrorsService,
    private successToastService: SuccessToastService,
    private router: Router,
    private menuCtrl: MenuController,
    private browserService: BrowserService,
    private informationService: InformationService,
    private emailComposer: EmailComposer,
    private saveToLocal: SaveToLocalService,
    private platform: Platform,
    private storage: StorageService,
    protected toastCtrl: ToastController
  ) {}

  ngOnInit(): void {
    this.authService.onCurrentUserChanged().subscribe((user) => {
      this.user = user;
      if (user) {
        this.userDisplayName = this.user.getDisplayName();
      }
    });
    this.settingsService.getAppSettings().subscribe((settings) => {
      this.settings = settings;
      this.synchroEnabled = settings.synchroEnabled;
      this.fullSize = settings.offlineStorageSize;
      this.lastHelloDelay = settings.lastHelloDelay;
    });
    this.getChanges();
    this.loadPictures = getPicturesLoadingPermission();

    this.translate.get("Confirmation").subscribe((title) => {
      this.confirmTitle = title;
    });
    this.translate
      .get("Do you want to delete all local storage?")
      .subscribe((text) => {
        this.confirmMessage = text;
      });
    this.translate.get("Ok").subscribe((okButton) => {
      this.okButton = okButton;
    });
    this.translate.get("Cancel").subscribe((cancelButton) => {
      this.cancelButton = cancelButton;
    });
    this.translate.get("In progress...").subscribe((text) => {
      this.progressMessage = text;
    });
    this.events.subscribe("updateChangesCount", () => {
      this.getChanges();
    });
    this.events.subscribe("toggleSynchro", (synchroEnabled) => {
      this.synchroEnabled = synchroEnabled;
      this.loadPictures = this.synchroEnabled;
      this.picturesToggle();
      if (this.synchroEnabled) {
        this.forceSynchro();
      }
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.forEach(
      (subscription) => subscription && subscription.unsubscribe()
    );
  }

  public picturesToggle() {
    this.events.publish("picturesToggle", this.loadPictures);
    setPicturesLoadingPermission(this.loadPictures);
  }

  public getChanges() {
    this.syncApi.getChanges().subscribe((changes: Change[]) => {
      if (changes) {
        this.changesCount = changes.length;
        this.forceSynchroEnabled = true;
      } else {
        this.changesCount = 0;
      }
    });
    // FIXME: the subscription doesn't work if we use this subscription array.
    // The main menu fails to get the number of pending changes.

    // this.subscriptions.push(
    this.syncApi
      .watchSynchronizationState()
      .subscribe((synchronizationState: SynchronizationState) => {
        if (synchronizationState.status === SynchronizationStatus.STARTED) {
          this.forceSynchroEnabled = false;
        } else {
          this.syncApi.getChanges().subscribe(
            (changes: Change[]) => {
              this.changesCount = changes.length;
              if (
                synchronizationState.status === SynchronizationStatus.DONE ||
                synchronizationState.status ===
                  SynchronizationStatus.POSTPONED ||
                synchronizationState.status === SynchronizationStatus.PUSH
              ) {
                this.forceSynchroEnabled = this.changesCount > 0;
              }
            },
            (err) => {
              this.errors.signalError(err);
            }
          );
        }
        this.lastHelloDelay = this.settingsService.getLastHelloDelay();
      });
    // );
  }

  forceSynchro(): void {
    if (this.forceSynchroEnabled) {
      this.syncApi.makeSynchronizationVerbose();
      this.syncApi.pushOfflineChanges(false, true).subscribe(
        () => {},
        (err) => {
          this.errors.signalError(err);
        },
        () => {}
      );
    }
  }

  supportEmail(): void {
    const inBrowser = this.browserService.inBrowser();
    const kpiSupport = Environment.getSupportEmail();

    forkJoin([
      this.translate.get("CAPEX Audit - Support request"),
      this.informationService.recoverApplicationDetails(),
    ]).subscribe(([subject, body]) => {
      if (inBrowser) {
        this.browserService.openUrl(
          "mailto:" + kpiSupport + "?subject=" + subject + "&body=" + body
        );
      } else {
        this.emailComposer
          .open({
            to: kpiSupport,
            subject,
            body,
            isHtml: true,
          })
          .then();
      }
    });
  }

  sendDiagnostic(): void {
    this.getDebugInformation();
    this.getDiagnosticInformation();
  }

  async getDebugInformation() {
    const keys = Object.keys(localStorage);
    const zip = new JSZip();
    for (const key of keys) {
      zip.file(key, localStorage.getItem(key));
    }
    const content = await zip.generateAsync({ type: "blob" }),
      filename = "KPI-debug.dbg";
    await this.saveFile(filename, content);
  }

  // Get the whole local database (websql/indexeddb) and zip it
  async getDiagnosticInformation() {
    const zip = new JSZip();
    const keys = await this.storage.getAllDatabaseKeys();
    let value;
    for (const key of keys) {
      value = await this.storage.get(key);
      zip.file(key, value);
    }
    const content = await zip.generateAsync({ type: "blob" }),
      filename = "KPI-diagnotics.dbg";
    await this.saveFile(filename, content);
  }

  // save file to local and show a toast
  private async saveFile(filename: string, content: string | Blob) {
    try {
      const file = await this.saveToLocal.saveToDownload(filename, content);
      this.translate
        .get("your device")
        .pipe(
          switchMap((msg) =>
            this.translate.get(
              "The debug files are saved to {{filepath}}. Please send them to support",
              { filepath: file?.fullPath || msg }
            )
          )
        )
        .pipe(tap((msg) => this.showToastAfterFileSaved(msg, 5000)))
        .subscribe();
      console.log("Saved to local", file);
    } catch (error) {
      console.warn("Error saving file", error);
      await this.showToastAfterFileSaved(
        this.translate.instant("Could not save debug file"),
        2000
      );
    }
  }

  private async showToastAfterFileSaved(msg: string, duration = 1000) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration,
      position: "bottom",
    });

    await toast.present();
  }

  async logout(): Promise<void> {
    this.authService.logout();
    await this.successToastService.showSuccessToast(1, "Logged out");
    await this.router.navigate(["/"]).then(() => window.location.reload());
  }

  showSyncChanges() {
    this.menuCtrl.close();
    this.router.navigate(["sync-changes"]);
  }

  legalInformation() {
    this.browserService.openUrl(
      "https://www.kpi-intelligence.com/home/legal-terms/"
    );
  }

  public showMoreSettings() {
    this.menuCtrl.close();
    this.router.navigate(["app-settings"]);
  }
}
