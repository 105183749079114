import { Component, DoCheck, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ComingSoonService } from "src/app/services/coming-soon.service";

import { Investment, InvestmentSlice } from "../../../structs/investments";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "investment-item-budget",
  templateUrl: "./investment-item-budget.component.html",
  styleUrls: ["./investment-item-budget.component.scss"],
})
export class InvestmentItemBudgetComponent implements DoCheck {
  @Input() public investment: Investment;
  @Input() public showStatus: boolean = true;
  @Input() public showOrigin: boolean = true;
  @Output() public sliceUpdated = new EventEmitter<boolean>();
  public slices: InvestmentSlice[] = [];

  private previousStateId: number;

  constructor(
    private comingSoonService: ComingSoonService,
    private translate: TranslateService
  ) {}

  /**
   * Implements our own change-detection hook
   * to force component to update when slices change.
   */
  public ngDoCheck(): void {
    if (!this.investment || !this.investment.status) {
      return;
    }

    // The status has changed ?
    if (this.investment.status.id !== this.previousStateId) {
      this.update();
      return;
    }

    const currentStatusSlices = this.investment.getCurrentStatusSlices();

    // Slices have been added/deleted ?
    if (currentStatusSlices.length !== this.slices.length) {
      this.update();
      return;
    }

    // Slices have been modified ?
    for (let i = 0; i < currentStatusSlices.length; i++) {
      const {
        year: newYear,
        price: newPrice,
        additionalPrice: newAdditionalPrice,
      } = currentStatusSlices[i];
      const { year, price, additionalPrice } = this.slices[i];

      if (
        newYear !== year ||
        newPrice !== price ||
        additionalPrice !== newAdditionalPrice
      ) {
        this.update();
        return;
      }
    }
  }

  private update(): void {
    this.slices = this.investment.getCurrentStatusSlices();
    this.sliceUpdated.emit(true);
    this.previousStateId = this.investment.status
      ? this.investment.status.id
      : null;
  }

  public showCarbonInitiatives() {
    const title = this.translate.instant("Connection to carbon initiatives");
    this.comingSoonService.showComingSoonAlert(title);
  }
}
