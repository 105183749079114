<ion-item-sliding #item>
  <ion-item (click)="onDocumentClick($event)">
    <ion-label>
      <div class="legend text-style2 text-overflow-ellipsis">{{ legend }}</div>
      <div class="content text-style1">{{ title }}</div>
    </ion-label>
  </ion-item>
  <ion-item-options side="start">
    <ion-item-option
      class="delete-button"
      color="critical"
      (click)="delete(item)"
      ><i class="fal fa-lg fa-trash-alt"></i
    ></ion-item-option>
  </ion-item-options>
</ion-item-sliding>
