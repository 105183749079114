import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, Observer, Subject } from "rxjs";
import { concatMap, filter, map, switchMap, tap } from "rxjs/operators";

import * as moment from "moment";

import { Asset, Perimeter, setAsset } from "../structs/assets";
import {
  AuditBuilding,
  AuditSynthesis,
  AuditSynthesisChange,
} from "../structs/audit";
import { StorageService } from "./storage.service";
import { Investment } from "../structs/investments";
import { Task } from "../structs/tasks";
import { MultiPerimeterEnergyTrajectory } from "../structs/initiative";
// import { Task } from '../structs/tasks'

const configPrefix: string = "config:";
const investmentPrefix: string = "investment:";
const synthesisKey: string = "synthesis:map";
const assetIdsMapKey: string = "assetIds:map";
const investmentIdsMapKey: string = "investmentIds:map";
const synthesisUrlPrefix: string = "synthesisUrl:";
const globalInvestmentsPrefix: string = "globalInvestments:";
const perimeterOfflineMapPrefix: string = "perimeterOfflineMap";
const energyTrajectoryKey = "energyTrajectory";

@Injectable()
export class OfflineService {
  public assetIdsMap: Map<number, number> = new Map<number, number>();
  public assetSavingInProgress$ = new BehaviorSubject<boolean>(false);

  private fullAuditRefresh$ = new BehaviorSubject<void>(null);

  private readonly assetChangedEvent: Subject<number> = new Subject<number>();
  assetChangedEvent$: Observable<number> = this.assetChangedEvent
    .asObservable()
    .pipe(filter((assetId) => !!assetId));

  constructor(private storage: StorageService) {}

  public storeItem(key: string, value: any): Observable<any> {
    return new Observable((observer) => {
      // TODO: storage.ready() decrecated ?
      // this.storage.ready().then(
      //   () => {
      let storage: any = this.storage;
      let itemAsString: string = JSON.stringify(value);
      storage.set(key, itemAsString).then(
        (results) => {
          observer.next(results);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
      // }
      // )
    });
  }

  public removeItem(key: string): Observable<any> {
    return new Observable((observer) => {
      // TODO: storage.ready() decrecated ?
      // this.storage.ready().then(
      //   () => {
      this.storage.remove(key).then(
        (results) => {
          observer.next(results);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
      //   }
      // )
    });
  }

  public getItem(key: string, failIfEmpty = false): Observable<any> {
    return new Observable(
      (observer) => {
        // TODO: storage.ready() decrecated ?
        // this.storage.ready().then(
        //   () => {
        this.storage.get(key).then(
          (value) => {
            if (value) {
              try {
                let obj: any = JSON.parse(value);
                observer.next(obj);
              } catch (exc) {
                console.log("getItem failed", key, exc, value);
                observer.error(exc);
              }
            } else {
              if (failIfEmpty) {
                observer.error("missing key " + key);
              } else {
                observer.next(null);
              }
            }
            observer.complete();
          },
          (err) => {
            console.error(">> getItem", key, "failed", err);
            observer.error(err);
            observer.complete();
          }
        );
      }
      //   )
      // }
    );
  }

  /**
   * store full config
   */
  public storeConfig(config: any): Observable<any> {
    return this.storeObject(config, configPrefix).pipe(
      tap(() => this.fullAuditRefresh$.next(null))
    );
  }

  public getConfig(key: string, failIfEmpty = false): Observable<any> {
    return this.fullAuditRefresh$.pipe(
      switchMap(() => this.getItem(configPrefix + key, failIfEmpty))
    );
  }

  /*
   * so we're offline then go online, we don't want to keep being notified of offline updates thank you very much
   */
  public getConfigNoRefresh(key: string, failIfEmpty = false): Observable<any> {
    return this.getItem(configPrefix + key, failIfEmpty);
  }

  getMainPerimeter(errorOnMissing: boolean = true): Observable<Perimeter> {
    return new Observable((observer) => {
      // Look for the perimeter corresponding to the given Id
      this.getItem("currentMultiPerimeter").subscribe((mainPerimeter) => {
        if (mainPerimeter) {
          observer.next(mainPerimeter);
          observer.complete();
        } else {
          if (errorOnMissing) {
            observer.error("no main perimeter");
          } else {
            observer.next(null);
          }
          observer.complete();
        }
      });
    });
  }

  getSynthesisYear(): Observable<number> {
    return new Observable((observer) => {
      let year: number = moment().year();
      observer.next(year);
    });
  }

  /**
   * store the investments of an asset
   * @param {number} assetId
   * @param {Investment[]} investments
   * @returns {Observable<Asset>}
   */
  public storeAssetInvestments(
    asset: Asset,
    investments: Investment[]
  ): Observable<Asset> {
    return new Observable((observer) => {
      this.loadAsset(asset.id, asset.offlineId).subscribe(
        (asset) => {
          asset.investments = investments;
          this.storeAsset(asset).subscribe(
            () => {
              observer.next(asset);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public removeAsset(asset: Asset): Observable<any> {
    return new Observable((observer) => {
      this.getAuditSynthesisEx().subscribe(
        (auditSynthesis: AuditSynthesis) => {
          let listOfAssets = auditSynthesis.assets;
          let foundIndex: number = -1;
          let assetId = 0;
          let assetOfflineId = 0;
          const parentStillExists = listOfAssets.find(
            (a) =>
              asset.parent &&
              (a.id === asset.parent.id ||
                a.offlineId === asset.parent.offlineId)
          );

          if (asset.parent && parentStillExists) {
            assetId = asset.parent.id;
            assetOfflineId = asset.parent.offlineId;
          } else {
            assetId = asset.id;
            assetOfflineId = asset.offlineId;
          }

          for (
            let i = 0, l = listOfAssets.length;
            i < l && foundIndex < 0;
            i++
          ) {
            if (assetId > 0 && listOfAssets[i].id === assetId) {
              foundIndex = i;
            } else if (
              assetOfflineId > 0 &&
              listOfAssets[i].offlineId === assetOfflineId
            ) {
              foundIndex = i;
            }
          }
          if (asset.parent && parentStillExists) {
            // If the asset has a parent : we don't add its audit element to the main list
            // but we add it as a child of it parent audit element
            if (foundIndex >= 0) {
              let parentAuditElement = listOfAssets[foundIndex];
              let foundChild = -1;
              assetId = asset.id;
              assetOfflineId = asset.offlineId;

              for (
                let i = 0, l = parentAuditElement.children.length;
                i < l && foundChild < 0;
                i++
              ) {
                if (
                  assetId > 0 &&
                  parentAuditElement.children[i].id === assetId
                ) {
                  foundChild = i;
                } else if (
                  assetOfflineId > 0 &&
                  parentAuditElement.children[i].offlineId === assetOfflineId
                ) {
                  foundChild = i;
                }
              }
              if (foundChild >= 0) {
                parentAuditElement.children.splice(foundChild, 1);
              }
              const childrenInAssetList = listOfAssets.findIndex(
                (a) => a.id === assetId
              );
              if (childrenInAssetList > -1) {
                listOfAssets.splice(childrenInAssetList, 1);
              }
            }
          } else {
            if (foundIndex >= 0) {
              listOfAssets.splice(foundIndex, 1);
            }
          }

          this.storeAuditElementsEx(auditSynthesis).subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * get an asset
   */
  public getAsset(
    assetId: number,
    failIfEmpty: boolean = false
  ): Observable<Asset> {
    return this.loadAsset(assetId);
  }

  /**
   *
   * Force asset to be reloaded
   * @param asset
   * @returns {Observable<Asset>}
   */
  public refreshAsset(asset: Asset): Observable<Asset> {
    return this.loadAsset(asset.id, asset.offlineId);
  }

  /**
   * return the list of global investments for a building
   */
  public getGlobalInvestments(buildingId: any): Observable<Investment[]> {
    return new Observable((observer) => {
      const key = globalInvestmentsPrefix + buildingId;
      this.getItem(key).subscribe(
        (globalInvestments) => {
          if (globalInvestments === null) {
            globalInvestments = [];
          }
          observer.next(
            globalInvestments.map((obj) => Object.assign(new Investment(), obj))
          );
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public renameGlobalInvestmentsAfterSync(
    buildingId: number,
    perimeterLocalId: string
  ): Observable<boolean> {
    return new Observable((observer) => {
      const oldKey = globalInvestmentsPrefix + perimeterLocalId;
      const newKey = globalInvestmentsPrefix + buildingId;
      this.getItem(oldKey).subscribe(
        (globalInvestments) => {
          if (globalInvestments === null) {
            globalInvestments = [];
          }
          this.storeItem(newKey, globalInvestments).subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public storeGlobalInvestments(building: AuditBuilding): Observable<boolean> {
    const handleGlobalInvestmentCallback = (
      globalInvestments: Investment[],
      investment: Investment
    ): Investment[] => {
      const index = globalInvestments.findIndex(
        (i) => i.localId === investment.localId
      );
      if (index >= 0) {
        globalInvestments[index] = investment;
      } else {
        globalInvestments.push(investment);
      }
      return globalInvestments;
    };

    return from(building.globalInvestments).pipe(
      concatMap((investment: Investment) =>
        this._handleGlobalInvestment(investment, handleGlobalInvestmentCallback)
      ),
      map(() => {
        return true;
      })
    );
  }

  /**
   * add a new investment to the list of global investments
   */
  public addGlobalInvestment(investment: Investment): Observable<Investment> {
    return this._handleGlobalInvestment(
      investment,
      (globalInvestments, theInvestment) => {
        globalInvestments.push(theInvestment);
        return globalInvestments;
      }
    );
  }

  /**
   * modify an investment into the list of global investments
   */
  public patchGlobalInvestment(investment: Investment): Observable<Investment> {
    return this._handleGlobalInvestment(
      investment,
      (globalInvestments, theInvestment) => {
        const index = globalInvestments
          .map((elt) => elt.localId)
          .indexOf(theInvestment.localId);
        if (index >= 0) {
          globalInvestments[index] = theInvestment;
        } else {
          console.warn(
            "patchGlobalInvestment: investment not found",
            theInvestment
          );
        }
        return globalInvestments;
      }
    );
  }

  /**
   * delete an investment into the list of global investments
   */
  public deleteGlobalInvestment(
    investment: Investment
  ): Observable<Investment> {
    return this._handleGlobalInvestment(
      investment,
      (globalInvestments, theInvestment) => {
        const index = globalInvestments
          .map((elt) => elt.localId)
          .indexOf(theInvestment.localId);
        if (index >= 0) {
          globalInvestments.splice(index, 1);
        } else {
          console.warn(
            "deleteGlobalInvestment: investment not found",
            theInvestment
          );
        }
        return globalInvestments;
      }
    );
  }

  /**
   * store list of investments
   */
  public storeInvestments(investments: Investment[]): Observable<any> {
    return new Observable((observer) => {
      // this.storage.ready().then(
      //   () => {
      // store each investment in storage
      let investmentsObjs = from(investments);
      investmentsObjs
        .pipe(
          concatMap((investment) => {
            return this._doStoreInvestment(investment);
          })
        )
        .subscribe(
          (investment) => {
            observer.next(investment);
          },
          () => {},
          () => {
            observer.complete();
          }
        );
      //   }
      // )
    });
  }

  /**
   * Storing an array of global investments locally. We make sure that the previous investment
   * is properly saved before going on with the next one.
   * @param investments
   * @returns
   */
  public storeGlobalInvestmentsArray(
    investments: Investment[]
  ): Observable<any> {
    return Observable.create((observer) => {
      // this.storage.ready().then(
      //   () => {
      // store each investment in storage
      let investmentsObjs = from(investments);
      investmentsObjs
        .pipe(
          concatMap((investment) => {
            return this.addGlobalInvestment(investment);
          })
        )
        .subscribe(
          (investment) => {
            observer.next(investment);
          },
          () => {},
          () => {
            observer.complete();
          }
        );
      //   }
      // )
    });
  }

  /**
   * get an investment
   */
  public getInvestment(
    investmentId: number,
    failIfEmpty: boolean = false
  ): Observable<Investment> {
    return new Observable((observer) => {
      // this.storage.ready().then(
      //   () => {
      this.storage.get(investmentPrefix + investmentId).then(
        (value) => {
          if (value) {
            try {
              let obj: any = JSON.parse(value);
              observer.next(Object.assign(new Investment(), obj));
            } catch (exc) {
              console.error(exc);
              observer.error(exc);
            }
          } else {
            if (failIfEmpty) {
              observer.error("missing investment " + investmentId);
            } else {
              observer.next(null);
            }
          }
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        }
      );
      //   }
      // )
    });
  }

  /**
   * store audit synthesis
   */
  public storeAuditElements(audit: AuditSynthesisChange): Observable<boolean> {
    let key = audit.perimeter.id + "/" + audit.year;
    return new Observable((observer: Observer<boolean>) => {
      this.getItem(synthesisKey, false).subscribe(
        (auditSynthesisMap: Map<string, AuditSynthesis>) => {
          if (!auditSynthesisMap) {
            auditSynthesisMap = new Map<string, AuditSynthesis>();
          }
          auditSynthesisMap[key] = audit.auditSynthesis;
          this.storeItem(synthesisKey, auditSynthesisMap).subscribe(
            () => {
              observer.next(true);
            },
            (err) => {
              observer.error(err);
              observer.complete();
            },
            () => observer.complete()
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getAuditSynthesis(
    perimeter: Perimeter,
    year: number
  ): Observable<AuditSynthesis> {
    return new Observable((observer: Observer<AuditSynthesis>) => {
      this.getItem(synthesisKey, false).subscribe(
        (auditSynthesisMap: Map<string, AuditSynthesis>) => {
          if (perimeter) {
            let auditSynthesis: AuditSynthesis = null;
            let key = "" + perimeter.id + "/" + year;
            if (auditSynthesisMap) {
              auditSynthesis = auditSynthesisMap[key];
            }
            if (auditSynthesis) {
              observer.next(auditSynthesis);
            } else {
              console.error("auditSynthesisMap missing for key", key);
              observer.error(key);
            }
          } else {
            observer.error("Audit synthesis: No perimeter");
          }
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getAuditSynthesisEx(): Observable<AuditSynthesis> {
    return new Observable((observer: Observer<AuditSynthesis>) => {
      this.getMainPerimeter().subscribe(
        (perimeter) => {
          this.getItem(synthesisKey, false).subscribe(
            (auditSynthesisMap: Map<string, AuditSynthesis>) => {
              if (perimeter) {
                let auditSynthesis: AuditSynthesis = null;
                let key = "" + perimeter.id + "/" + moment().year();
                if (auditSynthesisMap) {
                  auditSynthesis = auditSynthesisMap[key];
                }
                auditSynthesis.assets = auditSynthesis.assets.map((asset) =>
                  setAsset(asset)
                );
                if (auditSynthesis) {
                  observer.next(auditSynthesis);
                } else {
                  console.error("auditSynthesisMap missing for key", key);
                  observer.error(key);
                }
              } else {
                observer.error("Audit synthesis: No perimeter");
              }
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private storeAuditElementsEx(
    auditSynthesis: AuditSynthesis
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.getMainPerimeter().subscribe((perimeter) => {
        let key = perimeter.id + "/" + moment().year();
        this.getItem(synthesisKey, false).subscribe(
          (auditSynthesisMap: Map<string, AuditSynthesis>) => {
            if (!auditSynthesisMap) {
              auditSynthesisMap = new Map<string, AuditSynthesis>();
            }
            auditSynthesisMap[key] = auditSynthesis;
            this.storeItem(synthesisKey, auditSynthesisMap).subscribe(
              () => {
                observer.next(true);
                this.assetSavingInProgress$.next(false);
              },
              (err) => {
                observer.error(err);
                observer.complete();
              },
              () => observer.complete()
            );
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
      });
    });
  }

  public getAuditSynthesisMap(): Observable<Map<string, AuditSynthesis>> {
    return this.getItem(synthesisKey, true);
  }

  public storeAuditSynthesisMap(
    map: Map<string, AuditSynthesis>
  ): Observable<any> {
    return this.storeItem(synthesisKey, map);
  }

  public getNextOfflineId(): Observable<number> {
    return new Observable((observer) => {
      let value = Math.round(Date.now());
      observer.next(value);
      observer.complete();
    });
  }

  public getAssetIdsMap(): Observable<any> {
    return new Observable((observer) => {
      this.getItem(assetIdsMapKey, false).subscribe(
        (mapObj: any) => {
          if (!mapObj) {
            mapObj = {};
          }
          observer.next(mapObj);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public addToAssetIdsMap(elements: Array<any>): Observable<boolean> {
    return new Observable((observer) => {
      if (elements.length === 0) {
        observer.next(true);
        observer.complete();
      } else {
        this.getAssetIdsMap().subscribe(
          (assetIdsMap: any) => {
            for (let i = 0, l = elements.length; i < l; i++) {
              let elt = elements[i];
              assetIdsMap[elt.localId] = elt.assetId;
            }
            this.storeItem(assetIdsMapKey, assetIdsMap).subscribe(
              () => {
                observer.next(true);
                observer.complete();
              },
              (err) => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
      }
    });
  }

  public clearAssetIdsMap(
    offlineId: number,
    assetId: number
  ): Observable<boolean> {
    return new Observable((observer) => {
      this.storage.set(assetIdsMapKey, {}).then(
        () => {
          observer.next(true);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private getFullListOfAssets(assets): Asset[] {
    // create a flat list of all assets : parent and children
    return assets.reduce((acc, item) => {
      acc.push(item);
      acc = acc.concat(item.children);
      return acc;
    }, []);
  }

  public loadAsset(assetId: number, offlineId: number = 0): Observable<Asset> {
    return new Observable((observer) => {
      this.getAuditSynthesisEx().subscribe(
        (auditSynthesis: AuditSynthesis) => {
          let listOfAssets = this.getFullListOfAssets(auditSynthesis.assets);
          let foundAsset: Asset = null;
          let assetOfflineId = offlineId || assetId;
          for (let asset of listOfAssets) {
            // offlineId and onlineId are very different. We should not have a collision
            if (asset.id > 0 && asset.id === assetId) {
              foundAsset = asset;
            } else if (
              asset.offlineId > 0 &&
              asset.offlineId === assetOfflineId
            ) {
              foundAsset = asset;
            }
            if (foundAsset) {
              break;
            }
          }
          if (foundAsset) {
            foundAsset = setAsset(foundAsset);
            foundAsset.children = foundAsset.children.map((child) =>
              setAsset(child)
            );
            observer.next(foundAsset);
          } else {
            console.log("Asset not found", assetId, offlineId, listOfAssets);
            observer.error("Asset not found");
          }
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public storeAsset(asset: Asset): Observable<Asset> {
    return new Observable((observer) => {
      this.getAuditSynthesisEx().subscribe(
        (auditSynthesis: AuditSynthesis) => {
          let listOfAssets = auditSynthesis.assets;
          let foundIndex: number = -1;
          let assetId = 0;
          let assetOfflineId = 0;

          if (asset.parent) {
            assetId = asset.parent.id;
            assetOfflineId = asset.parent.offlineId;
          } else {
            assetId = asset.id;
            assetOfflineId = asset.offlineId;
          }

          for (
            let i = 0, l = listOfAssets.length;
            i < l && foundIndex < 0;
            i++
          ) {
            if (assetId > 0 && listOfAssets[i].id === assetId) {
              foundIndex = i;
            } else if (
              assetOfflineId > 0 &&
              listOfAssets[i].offlineId === assetOfflineId
            ) {
              foundIndex = i;
            }
          }

          if (asset.parent) {
            // If the asset has a parent : we don't add its audit element to the main list
            // but we add it as a child of it parent audit element
            if (foundIndex >= 0) {
              let parentAuditElement = listOfAssets[foundIndex];
              let foundChild = -1;
              assetId = asset.id;
              assetOfflineId = asset.offlineId;

              for (
                let i = 0, l = parentAuditElement.children.length;
                i < l && foundChild < 0;
                i++
              ) {
                if (
                  assetId > 0 &&
                  parentAuditElement.children[i].id === assetId
                ) {
                  foundChild = i;
                } else if (
                  assetOfflineId > 0 &&
                  parentAuditElement.children[i].offlineId === assetOfflineId
                ) {
                  foundChild = i;
                }
              }
              if (foundChild >= 0) {
                parentAuditElement.children[foundChild] = asset;
              } else {
                parentAuditElement.children.push(asset);
              }
            } else {
              // The parent should already be in the synthesis
              // If for some unknown reason this is not the case
              listOfAssets.push(asset);
            }
          } else {
            if (foundIndex >= 0) {
              listOfAssets[foundIndex] = asset;
            } else {
              listOfAssets.push(asset);
            }
          }

          this.storeAuditElementsEx(auditSynthesis).subscribe(
            () => {
              this.signalAnAssetChanged(asset.id);
              observer.next(asset);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public clearAuditCache(): Observable<boolean> {
    return new Observable((observer) => {
      this.storeItem(synthesisKey, null).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getInvestmentIdsMap(): Observable<any> {
    return new Observable((observer) => {
      this.getItem(investmentIdsMapKey, false).subscribe(
        (mapObj: any) => {
          if (!mapObj) {
            mapObj = {};
          }
          observer.next(mapObj);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * This map is a way to get the investment Id from its localId
   * @param elements
   */
  public addToInvestmentIdsMap(elements: Array<any>): Observable<boolean> {
    return new Observable((observer) => {
      if (elements.length === 0) {
        // Nothing to add
        observer.next(true);
        observer.complete();
      } else {
        this.getInvestmentIdsMap().subscribe(
          (mapObj: any) => {
            for (let i = 0, l = elements.length; i < l; i++) {
              let elt: any = elements[i];
              mapObj[elt.localId] = elt.investmentId;
            }
            this.storeItem(investmentIdsMapKey, mapObj).subscribe(
              () => {
                observer.next(true);
                observer.complete();
              },
              (err) => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
      }
    });
  }

  public getFromInvestmentIdsMap(localId: string): Observable<number> {
    return new Observable((observer) => {
      this.getInvestmentIdsMap().subscribe(
        (mapObj) => {
          if (mapObj.hasOwnProperty(localId)) {
            observer.next(mapObj[localId]);
          } else {
            observer.next(0);
          }
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public clearInvestmentIdsMap(): Observable<boolean> {
    return new Observable((observer) => {
      this.storage.set(investmentIdsMapKey, {}).then(
        () => {
          observer.next(true);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   returns Observable on the current audit synthesis data
   */
  public getAuditSynthesisLastRefresh(
    perimeter: Perimeter
  ): Observable<number> {
    return new Observable((observer: Observer<number>) => {
      if (perimeter) {
        let auditUrlKey = synthesisUrlPrefix + perimeter.id;
        this.getItem(auditUrlKey, false).subscribe(
          (value) => {
            let lastRefresh: number = 0;
            if (value !== null) {
              lastRefresh = parseInt(value);
            }
            observer.next(lastRefresh);
            observer.complete();
          },
          (err) => {
            observer.next(0);
            observer.complete();
          }
        );
      } else {
        observer.next(0);
        observer.complete();
      }
    });
  }

  public setAuditSynthesisLastRefresh(
    perimeter: Perimeter,
    lastRefresh: number
  ): Observable<number> {
    return new Observable((observer: Observer<number>) => {
      let auditUrlKey = synthesisUrlPrefix + perimeter.id;
      this.storeItem(auditUrlKey, "" + lastRefresh).subscribe(
        () => {
          observer.next(lastRefresh);
          observer.complete();
        },
        (err) => {
          observer.next(0);
          observer.complete();
        }
      );
    });
  }

  private storeObject(obj: any, prefix = ""): Observable<any> {
    return new Observable((observer) => {
      let storage: any = this.storage;
      let objectProperties = Object.keys(obj);
      objectProperties = objectProperties.map((propertyName: string) => {
        let objAsString: string = JSON.stringify(obj[propertyName]);
        let keyName = prefix + propertyName;
        return storage.set(keyName, objAsString); /*.then(
              (results) => {
                console.log("storeObject() results", results)
                observer.next(results);
              },
              (error) => {
                console.log("storeObject error", error)
                console.error(error);
                observer.error(error);
              }
            )*/
      });

      Promise.all(objectProperties).then((res) => {
        res.map((r) => observer.next(r));
        observer.complete();
      });
    });
  }

  private _doStoreInvestment(investment: Investment): Observable<Investment> {
    return new Observable((observer) => {
      let key = investmentPrefix + investment.id;
      let objAsString: string = JSON.stringify(investment);
      this.storage.set(key, objAsString).then(
        (results) => {
          observer.next(investment);
          observer.complete();
        },
        (error) => {
          console.log(error);
          observer.next(investment);
          observer.complete();
        }
      );
    });
  }

  private _handleGlobalInvestment(
    investment: Investment,
    callback: any
  ): Observable<Investment> {
    return new Observable((observer) => {
      const buildingId =
        investment.buildingId > 0
          ? investment.buildingId
          : investment.monoPerimeterLocalId;
      const key = globalInvestmentsPrefix + buildingId;
      this.getItem(key).subscribe(
        (globalInvestments) => {
          if (globalInvestments === null) {
            globalInvestments = [];
          }
          globalInvestments = callback(globalInvestments, investment);
          this.storeItem(key, globalInvestments).subscribe(
            () => {
              observer.next(investment);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );

          // Store also the investment on its own to retrieve it more easily by id.
          this._doStoreInvestment(investment).subscribe();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getPerimeterOfflineMap(): Observable<any> {
    return this.getItem(perimeterOfflineMapPrefix, false).pipe(
      map((jsonData: any) => {
        if (jsonData === null) {
          jsonData = {
            buildings: {},
            perimeters: {},
          };
        }
        return jsonData;
      })
    );
  }

  public setPerimeterOfflineMap(data: any): Observable<any> {
    return new Observable((observer) => {
      this.storeItem(perimeterOfflineMapPrefix, data).subscribe(
        (jsonData: any) => {
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public storeAssignedTasks(tasks: Task[]): Observable<void> {
    return this.storeItem("assignedTasks", tasks);
  }

  public getAssignedTasks(): Observable<Task[]> {
    return this.getItem("assignedTasks", true);
  }

  public patchTask(taskLocalId: string, taskId: number): Observable<void> {
    return this.getAssignedTasks().pipe(
      switchMap((tasks) => {
        const taskIndex = tasks.findIndex(
          (task) => task.local_id && taskLocalId === task.local_id
        );
        if (taskIndex >= 0) {
          tasks[taskIndex].id = taskId;
        }
        return this.storeAssignedTasks(tasks);
      })
    );
  }

  public signalAnAssetChanged(assetId: number): void {
    this.assetChangedEvent.next(assetId);
  }

  /**
   *
   * @param perimeterEnergyTrajectories: PerimeterEnergyTrajectory[]
   */
  setPerimeterEnergyTrajectories(
    perimeterEnergyTrajectories: MultiPerimeterEnergyTrajectory[]
  ): Observable<boolean> {
    return this.storeItem(energyTrajectoryKey, perimeterEnergyTrajectories);
  }

  getPerimeterEnergyTrajectories(): Observable<
    MultiPerimeterEnergyTrajectory[]
  > {
    return this.getItem(energyTrajectoryKey);
  }
}
