import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { AuditQuestion, NoteEvent } from "../../../structs/audit";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "on-off-note",
  templateUrl: "./on-off-note.component.html",
})
export class OnOffNoteComponent implements OnInit {
  @Input() question: AuditQuestion = null;
  @Output() clicked: EventEmitter<NoteEvent> = new EventEmitter<NoteEvent>();
  @Input() readOnly: boolean = false;
  notes = {};

  constructor() {}

  ngOnInit() {
    for (let i = 0; i < this.question.items.length; i++) {
      this.notes[this.question.items[i].id] =
        this.question.items[i].current_note === 1;
    }
  }

  setNote(value, itemId): void {
    let note: number = value ? 1 : 0;
    this.clicked.emit({ itemId, note });
  }

  showHelpText(event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
