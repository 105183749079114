import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  ActionSheetController,
  NavController,
  AlertController,
  ModalController,
} from "@ionic/angular";

import { ComingSoonService } from "../../../services/coming-soon.service";
import { Perimeter, Asset, AssetTypeLevel } from "../../../structs/assets";
import { DocumentsService } from "../../../services/documents.service";
import { DocumentRequirement } from "../../../structs/documents";
import { ErrorsService } from "../../../services/errors.service";
import {
  BarcodeScanner,
  BarcodeScannerOptions,
} from "@awesome-cordova-plugins/barcode-scanner/ngx";
import {
  getBarcodeScannerOptions,
  SCAN_QR_CODE,
  SCAN_BAR_CODE,
} from "../../../structs/base";
import { AssetEditService } from "../../../services/asset-edit.service";
import { first } from "rxjs/operators";
import { AssetsService } from "../../../services/assets.service";
import { Events } from "../../../services/events.service";
import { InvestmentModalComponent } from "../investment-modal/investment-modal.component";
import { NavigationExtras, Router } from "@angular/router";
import { DocumentsAddPage } from "../../perimeter/documents-add/documents-add";
import { ScopeService } from "@services/scope.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-add-action-sheet",
  template: "",
})
export class AssetAddActionSheetComponent {
  private addTypes = [
    {
      type: "investment",
      label: this.translate.instant("Investment"),
    },
    {
      type: "document",
      label: this.translate.instant("Document"),
    },
    {
      type: "childAsset",
      label: this.translate.instant("Child asset"),
    },
    {
      type: "photo",
      label: this.translate.instant("Photo"),
    },
    {
      type: "qrCode",
      label: this.translate.instant("QR Code"),
    },
    {
      type: "barcode",
      label: this.translate.instant("Barcode"),
    },
  ];
  private title: string;
  private multiperimeter: Perimeter = null;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private comingSoon: ComingSoonService,
    private docService: DocumentsService,
    private errors: ErrorsService,
    private navCtrl: NavController,
    private translate: TranslateService,
    private barcodeScanner: BarcodeScanner,
    private assetEditService: AssetEditService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    public event: Events,
    private assetsApi: AssetsService,
    private router: Router,
    private scope: ScopeService
  ) {
    this.addTypes.forEach((addType, index) => {
      this.translate
        .get(addType.label)
        .subscribe((label) => (this.addTypes[index] = { ...addType, label }));
    });
    this.translate
      .get("Add to the asset")
      .subscribe((title) => (this.title = title));
    this.scope.getCurrentMultiPerimeter().subscribe((site) => {
      this.multiperimeter = site;
    });
  }

  public open(perimeter: Perimeter, asset: Asset) {
    let assetLevel = -1;
    if (this.assetEditService.asset && this.assetEditService.asset.assetType) {
      assetLevel = this.assetEditService.asset.assetType.level;
    }

    this.assetsApi
      .getChildAssetTypes(asset.assetType)
      .pipe(first())
      .subscribe(async (allowedAssetTypes) => {
        // If the asset is a child, it can't have children so we remove the create child option
        if (
          allowedAssetTypes.length === 0 ||
          (assetLevel !== AssetTypeLevel.LEVEL_COLLECTION &&
            assetLevel !== AssetTypeLevel.LEVEL_UNIT)
        ) {
          let childAssetIndex = this.addTypes.findIndex(
            (addType) => addType.type === "childAsset"
          );
          if (childAssetIndex !== -1) {
            this.addTypes.splice(childAssetIndex, 1);
          }
        }
        const actionSheet = await this.actionSheetCtrl.create({
          header: this.title,
          buttons: [
            ...this.addTypes.map((addType) => ({
              text: addType.label,
              handler: () => {
                this.addHandler(addType.type, perimeter, asset);
              },
            })),
          ],
        });

        await actionSheet.present();
      });
  }

  private async addHandler(type: string, perimeter: Perimeter, asset: Asset) {
    switch (type) {
      case "investment":
        let investmentModal = await this.modalCtrl.create({
          component: InvestmentModalComponent,
          componentProps: {
            perimeter: perimeter,
            asset: asset,
          },
          showBackdrop: true,
        });
        await investmentModal.present();
        const { data } = await investmentModal.onDidDismiss();
        if (data.newInvestment) {
          const navExtras: NavigationExtras = {
            state: {
              asset: asset,
              addMode: true,
            },
          };
          this.router.navigate(
            ["perimeters", this.multiperimeter.id, "investment-detail", 0],
            navExtras
          );
        }
        break;
      case "document":
        this.docService.getDefaultDocumentTypesForAsset(asset.id).subscribe(
          async (docTypes: Array<DocumentRequirement>) => {
            const modal = await this.modalCtrl.create({
              component: DocumentsAddPage,
              swipeToClose: true,
              componentProps: {
                assetId: asset.id,
                allRequirements: docTypes,
                assetName: asset.label,
              },
            });
            return await modal.present();
          },
          (err) => {
            this.errors.signalError(err);
          }
        );
        break;
      case "qrCode":
        this.scanQrCodeAndAdd();
        break;
      case "barcode":
        this.scanBarCodeAndAdd();
        break;
      case "childAsset":
        this.addChild();
        break;
      case "photo":
        this.event.publish("addPicture");
        break;
      default:
        await (await this.comingSoon.create()).present();
        break;
    }
  }

  private scanQrCodeAndAdd(): void {
    const prompt = this.translate.instant(
      "Place a QR code inside the scan area"
    );
    const options: BarcodeScannerOptions = getBarcodeScannerOptions(
      SCAN_QR_CODE,
      prompt
    );
    this.barcodeScanner
      .scan(options)
      .then((barcodeData) => {
        let value = barcodeData.text;
        if (value) {
          this.assetEditService.asset.qrcode = value;
          this.assetEditService.saveQrCode();
        }
      })
      .catch((err) => {
        this.errors.signalError(err);
      });
  }

  private scanBarCodeAndAdd(): void {
    const prompt = this.translate.instant(
      "Place a barcode inside the scan area"
    );
    const options: BarcodeScannerOptions = getBarcodeScannerOptions(
      SCAN_BAR_CODE,
      prompt
    );
    this.barcodeScanner
      .scan(options)
      .then(async (barcodeData) => {
        let value = barcodeData.text;
        if (value) {
          this.assetEditService.asset.barcode = value;
          if (this.assetEditService.asset.showMaintenanceCode) {
            let confirm = await this.alertCtrl.create({
              header: this.translate.instant("Barcode scanning"),
              message: this.translate.instant(
                "Do you want to use the code {{code}} as maintenance code",
                { code: value }
              ),
              backdropDismiss: false,
              buttons: [
                {
                  text: this.translate.instant("Ok"),
                  handler: () => {
                    this.assetEditService.saveBarCode(true);
                  },
                },
                {
                  text: this.translate.instant("Cancel"),
                  handler: () => {
                    this.assetEditService.saveBarCode(false);
                  },
                },
              ],
            });
            await confirm.present();
          } else {
            this.assetEditService.saveBarCode(false);
          }
        }
      })
      .catch((err) => {
        this.errors.signalError(err);
      });
  }

  private addChild() {
    let perimeter = this.assetEditService.getPerimeter();
    const navExtras: NavigationExtras = {
      state: {
        perimeter: perimeter,
        parent: this.assetEditService.asset,
      },
    };
    this.router.navigate(["asset-add"], navExtras).then(() => {
      // This is to avoid creating a fake pending change in the parent's page
      this.event.publish("ignorePendingChanges");
    });
  }
}
