import { Injectable } from "@angular/core";
import { deburr } from "lodash";
import * as R from "ramda";
import { Observable, of } from "rxjs";

import { Perimeter } from "../structs/assets";
import {
  AccessLevel,
  Chapter,
  ControlPointStatuses,
  Roadmap,
  Theme,
} from "../structs/roadmap";
import { RoadmapSearchService } from "./roadmap-search.service";
import { map } from "rxjs/operators";

export interface ChaptersGroup<T extends { name: string }> {
  group: T;
  acknowledged: number;
  pending: number;
  unacknowledged: number;
  total: number;
  perimetersCount: number;
  chapters: Chapter[];
  hasLevelChildren?: boolean;
}

export enum ChaptersAccessLevel {
  ALL_CHAPTERS,
  READ_WRITE_CHAPTERS,
  READ_ONLY_CHAPTERS,
}

@Injectable()
export class RoadmapChaptersService {
  constructor(private roadmapSearchService: RoadmapSearchService) {}

  public static isPerimeterChaptersGroup(
    chaptersGroup: ChaptersGroup<any>
  ): chaptersGroup is ChaptersGroup<Perimeter> {
    return R.has("is_monosite", chaptersGroup.group);
  }

  public static getMatchingChapters(
    chapters: Chapter[],
    matchingChaptersIds: number[]
  ): Chapter[] {
    if (matchingChaptersIds !== null) {
      return chapters.filter((chapter) =>
        R.includes(chapter.id, matchingChaptersIds)
      );
    } else {
      return chapters;
    }
  }

  private getRoadmapChapters(
    roadmap: Roadmap,
    accessLevel: ChaptersAccessLevel
  ) {
    return roadmap.chapters.filter((chapter: Chapter) => {
      if (accessLevel === ChaptersAccessLevel.ALL_CHAPTERS) {
        return true;
      }
      if (accessLevel === ChaptersAccessLevel.READ_WRITE_CHAPTERS) {
        return !chapter.readOnly;
      }
      if (accessLevel === ChaptersAccessLevel.READ_ONLY_CHAPTERS) {
        return chapter.readOnly;
      }
    });
  }

  public getChaptersGroupsPerimeters(
    roadmap: Roadmap,
    perimeter: Perimeter,
    query?: string,
    accessLevel: ChaptersAccessLevel = ChaptersAccessLevel.ALL_CHAPTERS
  ): Observable<ChaptersGroup<Perimeter>[]> {
    const search = query
      ? this.roadmapSearchService.search(roadmap, query)
      : of(null);
    return search.pipe(
      map((matchingChaptersIds) => {
        const roadmapChapters = this.getRoadmapChapters(roadmap, accessLevel);
        const matchingChapters = RoadmapChaptersService.getMatchingChapters(
          roadmapChapters,
          matchingChaptersIds
        );
        const matchingPerimeterPredicate =
          RoadmapChaptersService.getMatchingGroupPredicate(query);
        return perimeter.sub_perimeters.reduce((groups, subPerimeter) => {
          // We want to display the group either if its name matches or if the content of the chapter matches
          const subPerimeterMatching = matchingPerimeterPredicate(subPerimeter);
          // If the name matches, compute the score with every chapters (we really want it), otherwise, use only matching chapters
          const chapters = subPerimeterMatching
            ? roadmapChapters
            : matchingChapters;
          const [acknowledged, pending, total, perimetersCount] =
            RoadmapChaptersService.getChaptersPerimetersScore(
              roadmap,
              chapters,
              [subPerimeter]
            );
          if (total > 0) {
            return [].concat(groups, [
              {
                group: subPerimeter,
                acknowledged,
                pending,
                unacknowledged: total - acknowledged - pending,
                total,
                perimetersCount: perimetersCount,
                chapters: roadmapChapters,
              },
            ]);
          } else {
            return groups;
          }
        }, []);
      })
    );
  }

  public getChaptersGroupsThemes(
    roadmap: Roadmap,
    perimeter: Perimeter,
    query?: string,
    accessLevel: ChaptersAccessLevel = ChaptersAccessLevel.ALL_CHAPTERS
  ): Observable<ChaptersGroup<Theme>[]> {
    const search = query
      ? this.roadmapSearchService.search(roadmap, query)
      : of(null);
    return search.pipe(
      map((matchingChaptersIds) => {
        const roadmapChapters = this.getRoadmapChapters(roadmap, accessLevel);
        const matchingChapters = RoadmapChaptersService.getMatchingChapters(
          roadmapChapters,
          matchingChaptersIds
        );
        const matchingThemePredicate =
          RoadmapChaptersService.getMatchingGroupPredicate(query);
        return roadmap.themes.reduce((groups, theme) => {
          // We want to display the group either if its name matches or if the content of the chapter matches
          const themeMatching = matchingThemePredicate(theme);
          // If the name matches, compute the score with every theme chapters (we really want it), otherwise, use only matching chapters
          let themeChapters = roadmapChapters.filter(
            (chapter) =>
              chapter.themes.length === 0 ||
              R.includes(theme.id, chapter.themes)
          );
          let matchingThemeChapters = matchingChapters.filter(
            (chapter) =>
              chapter.themes.length === 0 ||
              R.includes(theme.id, chapter.themes)
          );
          const chapters = themeMatching
            ? themeChapters
            : matchingThemeChapters;
          // We can search on the whole site or on a single monoperimeter
          let perimeters = perimeter.is_monosite
            ? [perimeter]
            : perimeter.sub_perimeters;
          const [acknowledged, pending, total, perimetersCount] =
            RoadmapChaptersService.getChaptersPerimetersScore(
              roadmap,
              chapters,
              perimeters
            );
          if (total > 0) {
            return [].concat(groups, [
              {
                group: theme,
                acknowledged,
                pending,
                unacknowledged: total - acknowledged - pending,
                total,
                perimetersCount,
                chapters: themeChapters,
              },
            ]);
          } else {
            return groups;
          }
        }, []);
      })
    );
  }

  public getChaptersGroupsAccessLevels(
    roadmap: Roadmap,
    perimeter: Perimeter,
    query?: string,
    accessLevel: ChaptersAccessLevel = ChaptersAccessLevel.ALL_CHAPTERS
  ): Observable<ChaptersGroup<AccessLevel>[]> {
    const search = query
      ? this.roadmapSearchService.search(roadmap, query)
      : of(null);
    return search.pipe(
      map((matchingChaptersIds) => {
        const roadmapChapters = this.getRoadmapChapters(roadmap, accessLevel);
        const matchingChapters = RoadmapChaptersService.getMatchingChapters(
          roadmapChapters,
          matchingChaptersIds
        );
        const matchingAccessLevelPredicate =
          RoadmapChaptersService.getMatchingGroupPredicate(query);
        return roadmap.accessLevels.reduce((groups, accessLevel) => {
          // We want to display the group either if its name matches or if the content of the chapter matches
          const accessLevelMatching = matchingAccessLevelPredicate(accessLevel);
          // If the name matches, compute the score with every access level chapters (we really want it), otherwise, use only matching chapters
          const accessLevelsChapters = roadmapChapters.filter(
            (chapter) =>
              chapter.accessLevels.length === 0 ||
              R.includes(accessLevel.id, chapter.accessLevels)
          );
          const matchingAccessLevelsChapters = matchingChapters.filter(
            (chapter) =>
              chapter.accessLevels.length === 0 ||
              R.includes(accessLevel.id, chapter.accessLevels)
          );
          const chapters = accessLevelMatching
            ? accessLevelsChapters
            : matchingAccessLevelsChapters;
          const [acknowledged, pending, total, perimetersCount] =
            RoadmapChaptersService.getChaptersPerimetersScore(
              roadmap,
              chapters,
              perimeter.sub_perimeters
            );
          if (total > 0) {
            return [].concat(groups, [
              {
                group: accessLevel,
                acknowledged,
                pending,
                unacknowledged: total - acknowledged - pending,
                total,
                perimetersCount,
                chapters: accessLevelsChapters,
              },
            ]);
          } else {
            return groups;
          }
        }, []);
      })
    );
  }

  public getChaptersGroupsByPerimeter(
    roadmap: Roadmap,
    perimeter: Perimeter,
    roadmapChapters: Chapter[],
    query?: string
  ): Observable<ChaptersGroup<Perimeter>[]> {
    const search = query
      ? this.roadmapSearchService.search(roadmap, query)
      : of(null);
    return search.pipe(
      map((matchingChaptersIds) => {
        const matchingChapters = RoadmapChaptersService.getMatchingChapters(
          roadmapChapters,
          matchingChaptersIds
        );
        const matchingPerimeterPredicate =
          RoadmapChaptersService.getMatchingGroupPredicate(query);
        return perimeter.sub_perimeters.reduce((groups, subPerimeter) => {
          // We want to display the group either if its name matches or if the content of the chapter matches
          const subPerimeterMatching = matchingPerimeterPredicate(subPerimeter);
          // If the name matches, compute the score with every chapters (we really want it), otherwise, use only matching chapters
          const chapters = subPerimeterMatching
            ? roadmapChapters
            : matchingChapters;
          const [acknowledged, pending, total, perimetersCount] =
            RoadmapChaptersService.getChaptersPerimetersScore(
              roadmap,
              chapters,
              [subPerimeter]
            );
          if (total > 0) {
            return [].concat(groups, [
              {
                group: subPerimeter,
                acknowledged,
                pending,
                unacknowledged: total - acknowledged - pending,
                total,
                perimetersCount: perimetersCount,
                chapters: roadmapChapters,
              },
            ]);
          } else {
            return groups;
          }
        }, []);
      })
    );
  }

  private static getChaptersPerimetersScore(
    roadmap: Roadmap,
    chapters: Chapter[],
    perimeters: Perimeter[]
  ): [number, number, number, number] {
    let acknowledged = 0;
    let pending = 0;
    let total = 0;
    let perimetersCount = 0;
    for (const perimeter of perimeters) {
      let hasPerimeter = false;
      for (const chapter of chapters) {
        if (
          !roadmap.isControlPointSkipped(perimeter, chapter) &&
          !roadmap.isControlPointOnStatus(
            perimeter,
            chapter,
            ControlPointStatuses.NOT_RELEVANT
          )
        ) {
          hasPerimeter = true;
          total++;
          if (
            roadmap.isControlPointOnStatus(
              perimeter,
              chapter,
              ControlPointStatuses.ACKNOWLEDGED
            )
          ) {
            acknowledged++;
          }
          if (
            roadmap.isControlPointOnStatus(
              perimeter,
              chapter,
              ControlPointStatuses.PENDING
            )
          ) {
            pending++;
          }
        }
      }
      if (hasPerimeter) {
        perimetersCount++;
      }
    }

    return [acknowledged, pending, total, perimetersCount];
  }

  private static getMatchingGroupPredicate<T extends { name: string }>(
    query?: string
  ): (group: T) => boolean {
    if (!query) {
      return () => true;
    }

    const deburredQuery = deburr(query.toLowerCase());
    return (group: T) =>
      R.includes(deburredQuery, deburr(group.name.toLowerCase()));
  }
}
