<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="backButtonClicked()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ title }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="picture-container">
    <app-picture [picture]="picture" [thumbnail]="false"></app-picture>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-list lines="none">
      <ion-item class="left-footer" *ngIf="perimeter">
        <ion-checkbox
          slot="start"
          [checked]="origin === 'PERIMETER_LIST' && isDefaultCoverImage"
          (click)="
            makePerimeterCover(
              isDefaultCoverImage
                ? null
                : { pictureId: picture.id, pictureLocalId: picture.localId }
            )
          "
        ></ion-checkbox>
        <ion-label *ngIf="isDefaultCoverImage">{{
          "Default image" | translate
        }}</ion-label>
        <ion-label *ngIf="!isDefaultCoverImage">{{
          "Make cover image" | translate
        }}</ion-label>
      </ion-item>
    </ion-list>
    <ion-buttons *ngIf="!readOnly" slot="end">
      <ion-button icon-only (click)="deletePicture()" [disabled]="inProgress">
        <i class="fal fa-trash-alt"></i>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
