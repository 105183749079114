import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  BarcodeScanner,
  BarcodeScannerOptions,
} from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { TranslateService } from "@ngx-translate/core";
import {
  ActionSheetController,
  AlertController,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import * as R from "ramda";
import { Subscription, forkJoin, combineLatest } from "rxjs";
import {
  Asset,
  AssetFloor,
  AssetPicture,
  AssetType,
  AssetTypeLevel,
  getAvailableZones,
  HeatTransferCoefficientU,
  OwnershipType,
  Perimeter,
  PerimeterType,
  PowerSource,
  RefrigerantType,
  ThermalResistanceCoefficientR,
  Zone,
  SourceInfoHeatTransferCoefficientU,
  SourceInfoThermalResistanceCoefficientR,
  StepsIds,
} from "../../../structs/assets";
import {
  AppSettings,
  getBarcodeScannerOptions,
  SCAN_BAR_CODE,
  SCAN_QR_CODE,
} from "../../../structs/base";
import { DocumentAssetSummary } from "../../../structs/documents";
// import { AssetChildrenComponent } from '../../pages/asset-children/asset-children.component'
import { OfflineService } from "../../../services/offline.service";
import { ScopeService } from "../../../services/scope.service";
import { PerimetersService } from "../../../services/perimeters.service";
import { AssetEditService } from "src/app/services/asset-edit.service";
import { AssetsService } from "src/app/services/assets.service";
import { ErrorsService } from "src/app/services/errors.service";
import { ImportStatusService } from "src/app/services/import-status.service";
import * as moment from "moment";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AssetTypeSelectorPage } from "src/app/components/segments/asset-detail/asset-type-selector/asset-type-selector.page";
import { PictureDetailComponent } from "../../picture-detail/picture-detail.component";
import { DocumentsService } from "src/app/services/documents.service";
import { PicturesViewerComponent } from "src/app/pages/pictures-viewer/pictures-viewer.component";
import { first, map, switchMap } from "rxjs/operators";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-detail-segment",
  templateUrl: "./asset-detail.component.html",
  styleUrls: ["./asset-detail.component.scss"],
})
export class AssetDetailSegmentComponent implements OnInit, OnDestroy {
  @Input() public addMode: boolean = false;
  @Output() public changed = new EventEmitter<any>();
  @Output() public pageChanged = new EventEmitter<boolean>();
  @Output() public childrenOpened = new EventEmitter<boolean>();
  public showImportStatus: boolean = false;
  public importStatusLabel: string;
  public zones: Zone[] = [];
  public selectedZoneId: number = null;
  public perimeters: Perimeter[] = [];
  public perimeterTypes: PerimeterType[] = [];
  public selectedMonoPerimeterId: string | number | null = null;
  public documentCount: number = 0;
  public mandatoryCount: number = 0;
  public mandatoryCompletedCount: number = 0;
  public documentPercent: number = 0;
  public unit: string = "";
  public measur_unit: string = "";
  public isAssetAffiliationEnabled: boolean = false;
  public isNewAsset: boolean = false;
  public powerSources: PowerSource[] = [];
  public heatTransferCoefficients: HeatTransferCoefficientU[] = [];
  public thermalResistanceCoefficients: ThermalResistanceCoefficientR[] = [];
  public sourceInfoHeatTransferCoefficients: SourceInfoHeatTransferCoefficientU[] =
    [];
  public sourceInfoThermalResistanceCoefficients: SourceInfoThermalResistanceCoefficientR[] =
    [];
  public selectedHeatTransferCoefficientId: number = null;
  public selectedThermalResistanceCoefficientId: number = null;
  public selectedSourceInfoHeatTransferCoefficientId: number = null;
  public selectedSourceInfoThermalResistanceCoefficientId: number = null;
  public refrigerantTypes: RefrigerantType[] = [];
  public selectedPowerSourceId: number = null;
  public selectedRefrigerantTypedId: number = null;
  public ownershipTypes: OwnershipType[];
  public selectedOwnershipTypeId: number = null;
  public zoneDetails: string;
  private subscriptions: Subscription[] = [];
  private allZones: Zone[] = [];
  private selectedAssetType: AssetType;
  private rememberMe: Asset = null;
  parent: Asset = null;
  private currentYear: number = 0;
  children: Asset[] = [];
  public oldLabel: string;
  public defaultQuantity: number = 1;
  public nullValue = null;

  /* Keep track of the current assetFloor ID*/
  selectedAssetFloor: number = null;

  /** Display the floor form input */
  get show_floor(): boolean {
    if (this.assetEditService.addMode) {
      return (
        this.assetEditService.getAssetType() &&
        this.assetEditService.getAssetType().show_floor
      );
    }
    return this.selectedAssetType && this.selectedAssetType.show_floor;
  }

  /** List of available assetFloor for this asset */
  assetFloor: AssetFloor[] = [];

  constructor(
    private actionSheetCtrl: ActionSheetController,
    public assetEditService: AssetEditService,
    private assetsApi: AssetsService,
    private scope: ScopeService,
    private barcodeScanner: BarcodeScanner,
    private docService: DocumentsService,
    private errors: ErrorsService,
    private importStatusService: ImportStatusService,
    private params: NavParams,
    private perimetersService: PerimetersService,
    private navCtrl: NavController,
    private translate: TranslateService,
    private offlineApi: OfflineService,
    private scopeService: ScopeService,
    private router: Router,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private alertCtrl: AlertController
  ) {}

  public ngOnInit(): void {
    if (this.assetEditService.asset.floor) {
      this.selectedAssetFloor = this.assetEditService.asset.floor.id;
    }
    if (this.assetEditService.asset.label) {
      this.oldLabel = this.assetEditService.asset.label;
    }
    this.isNewAsset = this.assetEditService.addMode;

    if (this.isNewAsset) {
      this.saveFloor();
    }

    this.selectedAssetType = this.assetEditService.getAssetType();
    this.currentYear = moment().year();

    this.assetsApi.getPowerSources().subscribe((powerSources) => {
      this.powerSources = powerSources;
      if (this.assetEditService.asset.powerSource) {
        this.selectedPowerSourceId = this.assetEditService.asset.powerSource;
      }
    });

    this.assetsApi
      .getHeatTransferCoefficientU()
      .subscribe((heatTransferCoefficients) => {
        this.heatTransferCoefficients = heatTransferCoefficients;
        if (this.assetEditService.asset.heatTransferCoefficientU !== null) {
          this.selectedHeatTransferCoefficientId =
            this.assetEditService.asset.heatTransferCoefficientU;
        }
      });

    this.assetsApi
      .getThermalResistanceCoefficientR()
      .subscribe((thermalResistanceCoefficients) => {
        this.thermalResistanceCoefficients = thermalResistanceCoefficients;
        if (
          this.assetEditService.asset.thermalResistanceCoefficientR !== null
        ) {
          this.selectedThermalResistanceCoefficientId =
            this.assetEditService.asset.thermalResistanceCoefficientR;
        }
      });

    this.assetsApi
      .getSourceInfoHeatTransferCoefficientU()
      .subscribe((sourceInfoHeatTransferCoefficients) => {
        this.sourceInfoHeatTransferCoefficients =
          sourceInfoHeatTransferCoefficients;
        if (
          this.assetEditService.asset.sourceInfoHeatTransferCoefficientU !==
          null
        ) {
          this.selectedSourceInfoHeatTransferCoefficientId =
            this.assetEditService.asset.sourceInfoHeatTransferCoefficientU;
        }
      });

    this.assetsApi
      .getSourceInfoThermalResistanceCoefficientR()
      .subscribe((sourceInfoThermalResistanceCoefficients) => {
        this.sourceInfoThermalResistanceCoefficients =
          sourceInfoThermalResistanceCoefficients;
        if (
          this.assetEditService.asset
            .sourceInfoThermalResistanceCoefficientR !== null
        ) {
          this.selectedSourceInfoThermalResistanceCoefficientId =
            this.assetEditService.asset.sourceInfoThermalResistanceCoefficientR;
        }
      });

    this.assetsApi.getRefrigerantTypes().subscribe((refrigerantTypes) => {
      this.refrigerantTypes = refrigerantTypes;

      if (this.assetEditService.asset.refrigerantType) {
        this.selectedRefrigerantTypedId =
          this.assetEditService.asset.refrigerantType;
      }
    });

    this.perimetersService
      .getPerimeterTypes()
      .subscribe((perimeterTypes: PerimeterType[]) => {
        this.perimeterTypes = perimeterTypes;
      });

    this.subscriptions.push(
      combineLatest([
        this.scopeService.getCurrentMultiPerimeter(),
        this.assetEditService.assetTypeSourceItem$,
        this.assetsApi.getZones(),
        this.assetsApi.getOwnershipTypes(),
      ]).subscribe(([multiPerimeter, assetType, zones, ownershipTypes]) => {
        this.perimeters = multiPerimeter.sub_perimeters;
        const asset = this.assetEditService.asset;
        if (asset && asset.building && asset.building.monosite_perimeter) {
          // If building defined for the asset : select the corresponding mono perimeter
          const assetPerimeterLocalId =
            asset.building.monosite_perimeter.localId;
          const assetPerimeterId = asset.building.monosite_perimeter.id;

          // In some situation asset.building.monosite_perimeter can be empty while perimeter.localId is set
          // This can cause empty value for perimeter
          for (const perimeter of this.perimeters) {
            if (
              (assetPerimeterLocalId &&
                perimeter.localId === assetPerimeterLocalId) ||
              (assetPerimeterId && perimeter.id === assetPerimeterId)
            ) {
              this.selectedMonoPerimeterId = perimeter.localId || perimeter.id;
            }
          }
          this.perimeterChanged(true);
        } else if (this.perimeters.length === 1) {
          // If no building defined for the asset (new asset?)
          // auto select, if we have just one choice
          this.selectedMonoPerimeterId =
            this.perimeters[0].localId || this.perimeters[0].id;
          this.perimeterChanged(true);
        }

        this.generateLabel();

        this.allZones = zones;
        this.zones = this.filterZones();
        if (this.assetEditService.asset.zone) {
          this.selectedZoneId = this.assetEditService.asset.zone.id;
        }
        this.updateZone(this.assetEditService.getAssetType());
        // When the asset type changes we update the available zones
        if (assetType) {
          this.updateZone(assetType);
        }

        this.ownershipTypes = ownershipTypes;

        if (
          assetType &&
          (!this.assetEditService.asset.assetType ||
            this.assetEditService.asset.assetType.id !== assetType.id ||
            this.isNewAsset)
        ) {
          if (assetType.defaultOwnershipType) {
            this.selectedOwnershipTypeId = assetType.defaultOwnershipType.id;
            this.ownershipTypeChanged();
          } else {
            this.selectedOwnershipTypeId = null;
          }
        } else {
          if (this.assetEditService.asset.ownershipType) {
            // For the ownershipType, we should have either an id, or an ownershipType object
            const asset = this.assetEditService.asset;
            if (typeof asset.ownershipType === "number") {
              this.selectedOwnershipTypeId = asset.ownershipType;
            } else {
              this.selectedOwnershipTypeId = asset.ownershipType.id;
            }
          } else {
            this.selectedOwnershipTypeId = null;
          }
        }
      })
    );

    this.assetsApi.getAppSettings().subscribe((appSettings: AppSettings) => {
      if (appSettings) {
        this.isAssetAffiliationEnabled = !!appSettings.enable_asset_affiliation;
      }
    });

    // Get the ownership types to let the choice to the user
    this.subscriptions.push(
      this.assetsApi.getPowerSources().subscribe((powerSources) => {
        this.powerSources = powerSources;
        if (this.assetEditService.asset.powerSource) {
          this.selectedPowerSourceId = this.assetEditService.asset.powerSource;
        }
      }),

      this.assetsApi.getRefrigerantTypes().subscribe((refrigerantTypes) => {
        this.refrigerantTypes = refrigerantTypes;

        if (this.assetEditService.asset.refrigerantType) {
          this.selectedRefrigerantTypedId =
            this.assetEditService.asset.refrigerantType;
        }
      }),

      this.perimetersService
        .getPerimeterTypes()
        .subscribe((perimeterTypes: PerimeterType[]) => {
          this.perimeterTypes = perimeterTypes;
        }),

      this.scopeService
        .getCurrentMultiPerimeter()
        .subscribe((multiPerimeter: Perimeter) => {
          this.perimeters = multiPerimeter.sub_perimeters;
          const asset = this.assetEditService.asset;
          if (asset && asset.building && asset.building.monosite_perimeter) {
            // If building defined for the asset : select the corresponding mono perimeter
            const assetPerimeterLocalId =
              asset.building.monosite_perimeter.localId;
            const assetPerimeterId = asset.building.monosite_perimeter.id;

            // In some situation asset.building.monosite_perimeter can be empty while perimeter.localId is set
            // This can cause empty value for perimeter
            for (const perimeter of this.perimeters) {
              if (
                (assetPerimeterLocalId &&
                  perimeter.localId === assetPerimeterLocalId) ||
                (assetPerimeterId && perimeter.id === assetPerimeterId)
              ) {
                this.selectedMonoPerimeterId =
                  perimeter.localId || perimeter.id;
              }
            }
            this.perimeterChanged(true);
          } else if (this.perimeters.length === 1) {
            // If no building defined for the asset (new asset?)
            // auto select, if we have just one choice
            this.selectedMonoPerimeterId =
              this.perimeters[0].localId || this.perimeters[0].id;
            this.perimeterChanged(true);
          }

          this.generateLabel();
        }),

      this.assetsApi.getAppSettings().subscribe((appSettings: AppSettings) => {
        if (appSettings) {
          this.isAssetAffiliationEnabled =
            !!appSettings.enable_asset_affiliation;
        }
      })

      // Get the ownership types to let the choice to the user
    );

    // since we're a segment we have to fake this as the parent calls this too early
    this.ionViewDidEnter();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    if (!this.assetEditService.asset.label) {
      this.assetEditService.asset.label = this.oldLabel;
    }
  }

  public ionViewDidEnter(): void {
    if (this.rememberMe) {
      // restore the asset to edit. It may be changed when looking at its children.
      this.assetEditService.setAsset(
        this.rememberMe,
        this.assetEditService.readOnly
      );
      this.rememberMe = null;
    }

    if (this.assetEditService.asset.parent) {
      let parent = this.assetEditService.asset.parent;
      const offline = parent.id === 0;
      this.offlineApi.loadAsset(parent.id, parent.offlineId).subscribe(
        (asset) => {
          this.parent = asset;
          this.selectedMonoPerimeterId =
            this.parent.building.monosite_perimeter.localId ||
            this.parent.building.monosite_perimeter.id;
          this.perimeterChanged(true);
        },
        (err) => {
          this.errors.signalError(err);
        }
      );
    }
    this.children = this.getChildren(this.assetEditService.asset);

    // refresh the unit based on the asset type potentially changing
    const selectedAssetType = this.assetEditService.getAssetType();

    if (this.selectedAssetType != null) {
      const unitName = R.path(["quantity_unit", "name"], selectedAssetType);
      this.unit = unitName ? `(${unitName})` : `(u)`;
      const MunitName = R.path(
        ["measurement_units", "name"],
        selectedAssetType
      );
      this.measur_unit = MunitName ? `(${MunitName})` : `(mu)`;
      if (this.selectedAssetType.id !== selectedAssetType.id) {
        this.selectedAssetType = selectedAssetType;
        this.detailChanged();
      }
    }
    this.zones = this.filterZones();

    // An asset type has a default quantity (US 3800)
    // If the default quantity is 0, we don't set a value
    if (!this.assetEditService.asset.quantity) {
      this.assetEditService.asset.quantity = selectedAssetType.default_quantity
        ? selectedAssetType.default_quantity
        : null;
      this.saveQuantity(null);
    }

    this.documentCount = 0;
    this.mandatoryCount = 0;
    this.mandatoryCompletedCount = 0;
    this.documentPercent = 0;

    if (this.assetEditService.asset.id) {
      this.subscriptions.push(
        this.docService
          .getDocumentSummaryForAsset(this.assetEditService.asset.id)
          .subscribe(
            (summary: DocumentAssetSummary) => {
              this.documentCount = summary.documentCount;
              this.mandatoryCount = summary.mandatoryCount;
              this.mandatoryCompletedCount = summary.mandatoryCompletedCount;

              if (this.mandatoryCount > 0) {
                this.documentPercent = Math.round(
                  (100 * this.mandatoryCompletedCount) / this.mandatoryCount
                );
              } else {
                this.documentPercent = 100;
              }
            },
            (err) => {
              this.errors.signalError(err);
            }
          )
      );

      this.updateImportStatusLabel();
      if (
        !this.importStatusService.isValidated(
          this.assetEditService.asset.importStatuses
        )
      ) {
        this.showImportStatus = true;
      }
      this.assetEditService.updateLastAccess();
    }
  }

  /**
   * To make sure the quantity is an integer > 0
   * @param ev
   */
  public quantityChecker(ev) {
    const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
    const numberToString = inputNumber.toString();
    if (numberToString.length > 6) {
      this.assetEditService.asset.quantity = parseInt(
        numberToString.substr(0, 6)
      );
    } else {
      this.assetEditService.asset.quantity = inputNumber;
    }
    this.saveQuantity(ev);
  }

  public heightChecker(ev) {
    if (ev.target.value) {
      const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
      const numberToString = inputNumber.toString();
      if (numberToString.length > 3) {
        this.assetEditService.asset.height = parseInt(
          numberToString.substr(0, 3)
        );
      } else {
        this.assetEditService.asset.height = inputNumber;
      }
    } else {
      this.assetEditService.asset.height = null;
    }
    this.saveHeight(ev);
  }

  /**
   * To make sure the equipment count is an integer > 0
   * @param ev
   */
  public equipmentCountChecker(ev: any) {
    const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
    const numberToString = inputNumber.toString();
    if (numberToString.length > 6) {
      this.assetEditService.asset.equipment_count = parseInt(
        numberToString.substr(0, 6)
      );
    } else {
      this.assetEditService.asset.equipment_count = inputNumber;
    }
    this.saveEquipmentCount();
  }

  public latestRefrigerantDeclarationChecker(ev) {
    const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
    const numberToString = inputNumber.toString();
    if (numberToString.length > 6) {
      this.assetEditService.asset.latestRefrigerantDeclaration = parseInt(
        numberToString.substr(0, 6)
      );
    } else {
      this.assetEditService.asset.latestRefrigerantDeclaration = inputNumber;
    }
    this.saveLatestRefrigerantDeclaration();
  }

  public energyYieldChecker(ev) {
    if (!isNaN(ev.target.value)) {
      //limit the number to between 0 & 200
      const inputNumber = Math.min(Math.max(0, ev.target.value), 200);
      this.assetEditService.asset.latest_energy_yield_declaration = inputNumber;
      ev.target.value = inputNumber; // reset the displayed value
    }
    this.saveEnergyYield(ev);
  }

  public clickDocuments(): void {
    const navExtras: NavigationExtras = {
      state: {
        asset: this.assetEditService.asset,
      },
    };
    this.route.params.pipe(first()).subscribe((params) => {
      this.router.navigate(
        [
          "perimeters",
          params.multiPerimeterId,
          "asset-detail",
          params.assetId || 0,
          "asset-document-summary",
        ],
        navExtras
      );
    });
  }

  public saveLabel(event): void {
    if (this.assetEditService.asset?.label !== this.oldLabel) {
      this.assetEditService.saveLabel(event);
      this.detailChanged();
    }
  }

  /**
   * Removed from migration
   *
   */
  // public confirmImport(): void {
  //   this.subscriptions.push(
  //     this.importStatusService
  //       .showConfirmImportAlert(this.assetEditService.asset.importStatuses)
  //       .then((statuses) => {
  //         if (statuses) {
  //           this.assetEditService.asset.importStatuses = <
  //             ImportableAssetStatus[]
  //           >statuses;
  //           this.assetEditService.importStatusesUpdated();
  //           this.updateImportStatusLabel();
  //           this.changed.emit();
  //         }
  //       })
  //   );
  // }

  public saveQuantity(event): void {
    this.assetEditService.saveQuantity(event);
    this.detailChanged();
  }

  public saveEquipmentCount(): void {
    this.assetEditService.saveEquipmentCount();
    this.detailChanged();
  }

  public saveHeight(event): void {
    this.assetEditService.saveHeight(event);
    this.detailChanged();
  }

  public saveLatestRefrigerantDeclaration(): void {
    this.assetEditService.saveLatestRefrigerantDeclaration();
    this.detailChanged();
  }

  public saveEnergyYield(event): void {
    this.assetEditService.saveEnergyYield(event);
    this.detailChanged();
  }

  public saveFloor(): void {
    let assetFloorSelected = this.assetEditService.findAssetFloor(
      this.assetEditService.selectedAssetFloor
    );
    if (!assetFloorSelected) {
      console.log("Couldn't find the selected AssetFloor");
      return;
    }
    this.assetEditService.saveFloor(assetFloorSelected);
    this.detailChanged();
  }

  public zoneChanged(): void {
    let selectedZone: Zone = null;
    for (let i = 0; i < this.zones.length; i++) {
      if (this.zones[i].id === this.selectedZoneId) {
        selectedZone = this.zones[i];
        break;
      }
    }

    this.assetEditService.zoneChanged(selectedZone);
    this.detailChanged();
  }

  public ownershipTypeChanged(): void {
    let selectedOwnershipType: OwnershipType = null;
    if (this.ownershipTypes.length > 0) {
      this.ownershipTypes.forEach((type) => {
        if (type.id === this.selectedOwnershipTypeId) {
          selectedOwnershipType = type;
        }
      });
    }

    this.assetEditService.ownershipTypeChanged(selectedOwnershipType);
    this.detailChanged();
  }

  public getPerimeter(): Perimeter | null {
    let perimeter: Perimeter = null;
    for (let i = 0; i < this.perimeters.length; i++) {
      let perimeterId = this.perimeters[i].localId || this.perimeters[i].id;
      if (perimeterId === this.selectedMonoPerimeterId) {
        perimeter = this.perimeters[i];
        return perimeter;
      }
    }
    return null;
  }

  public perimeterChanged(init: boolean): void {
    let perimeter: Perimeter = this.getPerimeter();
    let currentPerimeterId = null;
    if (perimeter === null) {
      return;
    }
    if (this.assetEditService.currentPerimeter) {
      currentPerimeterId =
        this.assetEditService.currentPerimeter.localId ||
        this.assetEditService.currentPerimeter.id;
    }
    let perimeterId = perimeter.localId || perimeter.id;
    if (currentPerimeterId !== perimeterId) {
      this.zones = this.filterZones();
      if (this.selectedZoneId) {
        let currentZoneIsStillAvailable =
          this.zones.filter((elt) => elt.id === this.selectedZoneId).length > 0;
        if (!currentZoneIsStillAvailable) {
          // If the selected zone is not available anymore : reset
          this.selectedZoneId = null;
          this.zoneChanged();
        }
      }
      if (
        perimeter &&
        perimeter.perimeterType &&
        this.isNewAsset &&
        this.zones.length > 0
      ) {
        let filteredTypes = this.perimeterTypes.filter((perimeterType) => {
          return perimeterType.id === perimeter.perimeterType;
        });
        if (filteredTypes.length > 0 && filteredTypes[0].default_zone) {
          let ham = filteredTypes[0].default_zone;
          // only select the zone if it's on the currently visible list
          if (
            this.zones.filter((zone) => {
              return zone.id === ham.id;
            }).length > 0
          ) {
            this.selectedZoneId = filteredTypes[0].default_zone.id;
            this.zoneChanged();
          }
        }
        if (
          filteredTypes.length > 0 &&
          filteredTypes[0].default_floor &&
          !this.assetEditService.selectedAssetFloor
        ) {
          this.assetEditService.selectedAssetFloor =
            filteredTypes[0].default_floor;
        }
      }

      this.assetEditService.perimeterChanged(perimeter);

      // Check that category/sub-category/asset type are still available for this perimeter
      const category = this.assetEditService.getCategory();
      const subCategory = this.assetEditService.getSubCategory();
      const assetType = this.assetEditService.getAssetType();
      if (
        (assetType &&
          assetType.onlyForClusters.length > 0 &&
          !R.includes(perimeter.cluster, assetType.onlyForClusters)) ||
        (subCategory &&
          subCategory.onlyForClusters.length > 0 &&
          !R.includes(perimeter.cluster, subCategory.onlyForClusters)) ||
        (category &&
          category.onlyForClusters.length > 0 &&
          !R.includes(perimeter.cluster, category.onlyForClusters))
      ) {
        this.assetEditService.asset.assetType = null;
        this.assetEditService.assettypeChanged(null);
        this.assetEditService.asset.subCategory = null;
        this.assetEditService.subcategoryChanged(null);
        this.assetEditService.asset.category = null;
        this.assetEditService.categoryChanged(null);
      }
      if (!init) {
        this.detailChanged();
      }
    }
  }

  public getChildren(asset: Asset): Asset[] {
    // ignore future children
    return asset.children;
  }

  public openAssetParent(parent: Asset) {
    this.rememberMe = this.assetEditService.asset;
    const offline = parent.id === 0;
    let assetId = offline ? parent.offlineId : parent.id;
    this.scope
      .getCurrentMultiPerimeter()
      .pipe(
        map((site) => site.id),
        switchMap((siteId) =>
          this.router.navigate([
            "perimeters",
            siteId,
            "asset-detail",
            assetId,
            {
              assetId: assetId,
              offlineAsset: offline,
            },
          ])
        )
      )
      .subscribe();
  }

  public powerSourceChanged(): void {
    this.assetEditService.powerSourceChanged(this.selectedPowerSourceId);
    this.detailChanged();
  }

  public heatTransferCoefficientChanged(): void {
    this.assetEditService.heatTransferCoefficientChanged(
      this.selectedHeatTransferCoefficientId
    );
    this.detailChanged();
  }

  public thermalResistanceCoefficientChanged(): void {
    this.assetEditService.thermalResistanceCoefficientChanged(
      this.selectedThermalResistanceCoefficientId
    );
    this.detailChanged();
  }

  public sourceInfoHeatTransferCoefficientChanged(): void {
    this.assetEditService.sourceInfoHeatTransferCoefficientChanged(
      this.selectedSourceInfoHeatTransferCoefficientId
    );
    this.detailChanged();
  }

  public sourceInfoThermalResistanceCoefficientChanged(): void {
    this.assetEditService.sourceInfoThermalResistanceCoefficientChanged(
      this.selectedSourceInfoThermalResistanceCoefficientId
    );
    this.detailChanged();
  }

  public refrigerantTypeChanged(): void {
    if (this.selectedRefrigerantTypedId.toString() === "-") {
      this.selectedRefrigerantTypedId = null;
      this.assetEditService.asset.latestRefrigerantDeclaration = null;
      this.saveLatestRefrigerantDeclaration();
    }
    this.assetEditService.refrigerantTypeChanged(
      this.selectedRefrigerantTypedId
    );
    this.detailChanged();
  }

  public async openAssetPicture(_picture: AssetPicture): Promise<void> {
    this.scope
      .getCurrentMultiPerimeter()
      .subscribe(async (perimeter: Perimeter) => {
        let options = {
          asset: this.assetEditService.asset,
          multiPerimeter: perimeter,
          pictureOnly: true,
          hideLinkToDetails: true,
          type: "asset",
        };

        let assetsModal = await this.modalCtrl.create({
          component: PicturesViewerComponent,
          componentProps: {
            options,
          },
        });
        await assetsModal.present();
      });
  }

  canChangeAssetType(): boolean {
    if (this.parent === null) {
      return true;
    } else {
      const parentType = this.parent.assetType;
      return (
        parentType.level === AssetTypeLevel.LEVEL_COLLECTION ||
        parentType.level === AssetTypeLevel.LEVEL_UNIT
      );
    }
  }

  private async openAssetTypeSelector(parentAssetType = null) {
    // Remember to put those restrictions back when we will
    // make the asset-adding process

    // this.navCtrl.push(AssetTypeSelectorPage, {
    //   assetTypeId: this.params.get('assetTypeId'),
    //   subCategoryId: this.params.get('subCategoryId'),
    //   categoryId: this.params.get('categoryId'),
    //   parentAssetType: parentAssetType,
    // });
    let assetTypeModal = await this.modalCtrl.create({
      component: AssetTypeSelectorPage,
      componentProps: {
        parentAssetType: parentAssetType,
      },
    });
    assetTypeModal.onDidDismiss().then(() => {
      this.ionViewDidEnter();
    });
    await assetTypeModal.present();
  }

  public clickAssetType(event?): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.canChangeAssetType()) {
      if (this.parent) {
        this.openAssetTypeSelector(this.parent.assetType);
      } else {
        this.openAssetTypeSelector();
      }
    }
  }

  private scanQrCodeAndUpdate(): void {
    const prompt = this.translate.instant(
      "Place a QR code inside the scan area"
    );
    const options: BarcodeScannerOptions = getBarcodeScannerOptions(
      SCAN_QR_CODE,
      prompt
    );
    this.barcodeScanner
      .scan(options)
      .then((barcodeData) => {
        let value = barcodeData.text;
        if (value) {
          this.assetEditService.asset.qrcode = value;
          this.assetEditService.saveQrCode();
          this.detailChanged();
        }
      })
      .catch((err) => {
        this.errors.signalError(err);
      });
  }

  public manageQrCode(): void {
    if (this.assetEditService.asset.qrcode) {
      forkJoin(
        this.translate.get("QR Code"),
        this.translate.get("Delete"),
        this.translate.get("Modify")
      ).subscribe(async ([title, deleteLabel, modifyLabel]) => {
        // Propose to delete or modify
        const actionSheet = await this.actionSheetCtrl.create({
          header: title,
          buttons: [
            {
              text: deleteLabel,
              role: "destroy",
              handler: () => {
                this.assetEditService.asset.qrcode = null;
                this.assetEditService.saveQrCode();
                this.detailChanged();
              },
            },
            {
              text: modifyLabel,
              handler: () => {
                this.scanQrCodeAndUpdate();
              },
            },
          ],
        });
        await actionSheet.present();
      });
    }
  }

  public saveComments($event: FocusEvent) {
    const comment = (<HTMLTextAreaElement>$event.target).value;
    this.assetEditService.saveComments(comment);
    this.detailChanged();
  }

  public saveZoneDetails($event: FocusEvent) {
    const zoneDetails = (<HTMLTextAreaElement>$event.target).value;
    this.assetEditService.saveZoneDetails(zoneDetails);
    this.detailChanged();
  }

  public saveMaintenanceCode($event: FocusEvent) {
    const maintenanceCode = (<HTMLTextAreaElement>$event.target).value;
    this.assetEditService.saveMaintenanceCode(maintenanceCode);
    this.detailChanged();
  }

  public saveBrand($event: FocusEvent) {
    const brand = (<HTMLIonInputElement>$event.target).value;
    this.assetEditService.saveBrand(brand);
    this.detailChanged();
  }

  public saveModel($event: FocusEvent) {
    const model = (<HTMLIonInputElement>$event.target).value;
    this.assetEditService.saveModel(model);
    this.detailChanged();
  }

  public savePower($event: FocusEvent) {
    const power = (<HTMLIonInputElement>$event.target).value;
    this.assetEditService.savePower(power);
    this.detailChanged();
  }

  public saveServedZone($event: FocusEvent) {
    const zone = (<HTMLIonInputElement>$event.target).value;
    this.assetEditService.saveServedZone(zone);
    this.detailChanged();
  }

  private scanBarCodeAndUpdate(): void {
    const prompt = this.translate.instant(
      "Place a barcode inside the scan area"
    );
    const options: BarcodeScannerOptions = getBarcodeScannerOptions(
      SCAN_BAR_CODE,
      prompt
    );
    this.barcodeScanner
      .scan(options)
      .then((barcodeData) => {
        let value = barcodeData.text;
        if (value) {
          this.assetEditService.asset.barcode = value;
          // TODO : ask if this is also the maintenance code
          this.assetEditService.saveBarCode(false);
        }
      })
      .catch((err) => {
        this.errors.signalError(err);
      });
  }

  public manageBarCode(): void {
    if (this.assetEditService.asset.barcode) {
      forkJoin(
        this.translate.get("Barcode"),
        this.translate.get("Delete"),
        this.translate.get("Modify")
      ).subscribe(async ([title, deleteLabel, modifyLabel]) => {
        // Propose to delete or modify
        const actionSheet = await this.actionSheetCtrl.create({
          header: title,
          buttons: [
            {
              text: deleteLabel,
              role: "destroy",
              handler: () => {
                this.assetEditService.asset.barcode = null;
                this.assetEditService.saveBarCode(false);
                this.detailChanged();
              },
            },
            {
              text: modifyLabel,
              handler: () => {
                this.scanBarCodeAndUpdate();
              },
            },
          ],
        });
        await actionSheet.present();
      });
    }
  }

  public hasBrandSupport(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.brandSupport;
  }

  public hasModelSupport(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.modelSupport;
  }

  public hasPowerSourceSupport(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.powerSourceSupport;
  }

  public hasThermalResistanceCoefficientR(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.showThermalResistanceCoefficientR;
  }

  public hasHeatTransferCoefficientU(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.showHeatTransferCoefficientU;
  }

  public hasRefrigerantType(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.showRefrigerantType;
  }

  public hasPowerSupport(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.powerSupport;
  }

  public hasServedZoneSupport(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.servedZoneSupport;
  }

  public hasEnergyYieldSupport(): boolean {
    let assetType: AssetType = this.assetEditService.getAssetType();
    return assetType && assetType.manage_latest_energy_yield_declaration;
  }

  /**
   * Filters the zones using the assetType
   * When the asset is not restricted to precise zones, then the asset can be defined on any zones
   *
   * @param zones
   * @param assetType
   */
  private filterZoneByAssetType(zones: Zone[], assetType: AssetType): Zone[] {
    if (assetType.onlyTheseZones && assetType.onlyTheseZones.length > 0) {
      return zones.filter(
        (zone) => assetType.onlyTheseZones.indexOf(zone.id) > -1
      );
    }
    return zones;
  }

  /**
   * Filters the zones using the cluster
   * When the cluster is not restricted to precise zones, then the asset can be defined on any zones
   *
   * @param zones
   * @param assetType
   */
  private filterZoneByCluster(zones: Zone[]): Zone[] {
    if (this.selectedMonoPerimeterId) {
      const perimeter = this.getPerimeter();
      if (perimeter) {
        return getAvailableZones(zones, perimeter.cluster);
      }
    }
    return zones;
  }

  private filterZones(assetType: AssetType = null): Zone[] {
    let zones = this.allZones;
    let assetTypeFilter = assetType;
    if (assetTypeFilter === null) {
      assetTypeFilter =
        this.assetEditService.getAssetType() ||
        this.assetEditService.asset.assetType;
    }
    if (assetTypeFilter) {
      zones = this.filterZoneByAssetType(zones, assetTypeFilter);
    }
    zones = this.filterZoneByCluster(zones);
    return zones;
  }

  private updateImportStatusLabel(): void {
    this.importStatusService
      .getDisplayStatus(this.assetEditService.asset.importStatuses)
      .subscribe((importStatusLabel) => {
        this.importStatusLabel = importStatusLabel;
      });
  }

  private allowChildren(): boolean {
    if (this.assetEditService.asset.parent === null) {
      // level component and "ensemble de" are not allowed for a child
      return !(
        this.selectedAssetType.level === AssetTypeLevel.LEVEL_COMPONENT ||
        this.selectedAssetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM
      );
    }
    return false;
  }

  /**
   * Generate the asset label with the asset type and the monoperimeter
   */
  private generateLabel() {
    if (this.assetEditService.addMode && !this.assetEditService.asset.label) {
      let assetType = this.assetEditService.getAssetType();
      if (assetType) {
        this.assetEditService.asset.label =
          assetType.friendly_name && assetType.friendly_name !== ""
            ? assetType.friendly_name
            : assetType.name;
      }
    }
  }

  private updateZone(assetType: AssetType) {
    this.zones = this.filterZones(assetType);

    // If only one zone is available, we select it by default
    if (this.zones.length === 1) {
      if (this.selectedZoneId !== this.zones[0].id) {
        this.selectedZoneId = this.zones[0].id;
        this.zoneChanged();
      }
    } else {
      if (this.isNewAsset) {
        // no zone - let's pick a default if we're creating a new asset
        if (assetType && assetType.defaultZone) {
          this.selectedZoneId = assetType.defaultZone.id;
          this.zoneChanged();
        } else {
          let perimeter: Perimeter = this.getPerimeter();
          if (perimeter && perimeter.perimeterType) {
            let filteredTypes = this.perimeterTypes.filter((perimeterType) => {
              return perimeterType.id === perimeter.perimeterType;
            });
            if (filteredTypes.length > 0 && filteredTypes[0].default_zone) {
              this.selectedZoneId = filteredTypes[0].default_zone.id;
              this.zoneChanged();
            }
          }
        }
      }
      // We verify if the selected zone is still in the available zones
      const zoneSelected = this.zones.find(
        (zone) => zone.id === this.selectedZoneId
      );
      if (!zoneSelected) {
        console.log("the selected zone isn't authorised");
        this.selectedZoneId = null;
        this.zoneChanged();
      }
    }
  }

  private detailChanged() {
    // poke the lifecycle page so it goes and picks up the notation
    // information. But before that, we need to make sure there
    // is an asset type or the getAuditQuestions() function will fail
    if (!!this.assetEditService.getAssetType()) {
      this.subscriptions.push(
        this.assetEditService
          .getAuditQuestionsAndLifetimeDeviationReasons()
          .subscribe(() => {
            this.changed.emit({
              nextStep: StepsIds.LIFECYCLE,
              goNext: false,
              nextLabel: "next",
              stepValid: this.assetEditService.isDetailValid(),
            });
          })
      );
    }
  }
}
