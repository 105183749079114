import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, Subscription } from "rxjs";
import { combineLatest } from "rxjs";

import { AlertController, IonSelect, IonToggle } from "@ionic/angular";
import { AssetEditService } from "../../../services/asset-edit.service";
import {
  ConsistencyTestService,
  SuggestedLifetime,
} from "../../../services/consistency-test.service";
import {
  Asset,
  AssetConfidenceChoice,
  AssetType,
  Category,
  ImportableAssetStatus,
  InstallationSource,
  makeNewSiteReplacementStrategy,
  MissingNotationReason,
  NewSiteReplacementStrategy,
  SubCategory,
  Year,
  NotationMissingComment,
  AssetTypeLevel,
  StepsIds,
} from "../../../structs/assets";
import {
  AuditNotation,
  LEVELS_MAP,
  NotationState,
  RatingReason,
  STATE_LABELS,
  STATE_LIMITS,
} from "../../../structs/audit";
import { ScopeService } from "../../../services/scope.service";
import { AuditService } from "../../../services/audit.service";
import { ImportStatusService } from "../../../services/import-status.service";
import { AssetsService } from "../../../services/assets.service";
import { Investment } from "../../../structs/investments";
import { OfflineService } from "../../../services/offline.service";
import { NameAndValue } from "../../../structs/base";
import { UsersService } from "../../../services/users.service";
import { UsernamePipe } from "../../../pipes/username/username.pipe";
import { DatePipe } from "@angular/common";
import { AuthService } from "../../../services/auth.service";
import { firstLetterUpperCase } from "../../../structs/utils";
import { Events } from "src/app/services/events.service";
import { map } from "rxjs/operators";
import { InvestmentsService } from "@services/investments.service";

export interface Duration {
  value: number;
  name: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-lifecycle-segment",
  templateUrl: "./asset-lifecycle.component.html",
  styleUrls: ["./asset-lifecycle.component.scss"],
})
export class AssetLifecycleSegmentComponent implements OnInit, OnDestroy {
  constructor(
    public assetEditService: AssetEditService,
    private assetsApi: AssetsService,
    private auditApi: AuditService,
    private consistencyTestService: ConsistencyTestService,
    private importStatusService: ImportStatusService,
    private scope: ScopeService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private offlineApi: OfflineService,
    private userService: UsersService,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) public locale: string,
    public event: Events,
    private authService: AuthService,
    private investmentsApi: InvestmentsService
  ) {}

  @Input() public technicalStateInputRefresh: Observable<void>;
  @Input() public fromRoadmap: boolean = false;
  @Output() public changed = new EventEmitter<any>();
  @ViewChild("missingNotationSelect") missingNotationSelect: IonSelect;
  @ViewChild("hasNotationToggle") hasNotationToggle: IonToggle;
  @ViewChild("ratingReasonsSelect") ratingReasonsSelect: IonSelect;

  private subscriptions: Subscription[] = [];

  public notationStates: NotationState[] = [];
  public ratingReasons: RatingReason[] = [];
  public ratingReasonsForState: RatingReason[] = [];
  public selectedRatingReasonIds: number[] = [];
  public asetState: any;
  public installationYears: Year[] = [];
  public selectedInstallationYear: number = null;
  public lifetimeForDisplay: string = "";
  public theoreticalLifetimeForDisplay: string = "";
  public expectLifetimeForDisplay: string = "";
  public lifetimeGapForDisplay: string = "";
  public durations: Duration[] = [];
  public remainingLifetimeDuration: number = 0;
  public suggestedLifetime: SuggestedLifetime = null; // If the data doesn't match the model
  public selectedInstallationSourceId: number = null;
  public installationSources: InstallationSource[] = [];
  public confidenceChoices: AssetConfidenceChoice[] = [];
  public assetTypes: AssetType[] = [];
  public remainingLifetimeLabelCalculated: string = "";
  public remainingLifetimeLabelAuditor: string = "";
  public remainingLifetimeLabel: string = "";
  public remainingLifetimeSource: string = "";
  public currentAgeLabel: string = "";
  public investmentsImpactLabel: string = "";
  public hasReplacementLabel: string = "";
  public investments: Investment[] = [];
  public newSiteReplacementStrategies: NewSiteReplacementStrategy[] = [];
  public strategyId: number = 0;
  public notationMissingComments: NotationMissingComment[] = [];
  public commentSuffix: string = "";

  private year: number;
  private rawNote: number;
  private currentLevel: number;
  private currentStateId: number;
  private currentTheoreticalNote: number;
  private currentTheoreticalLevel: number;
  public hideShow: string = "hidden"; // A css class to hide or show the details
  public toggleDetailsLabel: string;
  private showMoreLabel: string;
  private showLessLabel: string;

  public booleanChoices = [
    { value: 1, name: this.translate.instant("Yes") },
    { value: 0, name: this.translate.instant("No") },
    { value: 2, name: this.translate.instant("I don't know") },
  ];

  public hasNotation: boolean = true;
  public reasonForMissingNotation: MissingNotationReason =
    MissingNotationReason.NOT_SET;
  public missingNotationReasons: NameAndValue[] = [];
  public updatingRatingReasons: boolean = false;
  public dangerClass: string = "";
  private investmentsWithImpact: number = 0;
  private investmentsImpact: number = 0;
  private investmentsLifetime: number = 0;
  public endOfLifeYearWithInvestForDisplay: string;
  public enfOfLifeYearNoImpactForDisplay: string;
  private endOfLifeYearNoInvest: number;
  public hasImpact: boolean = false;
  public currentNoteLabel: string = "";
  private alphaCoefficient: number = 2;
  private MAX_RESALE_VALUE = 2147483647;
  // ignorePendingChange is used if we change the asset's notation elsewhere while this
  // page is still open. The technical-state-input component will detect the new note
  // and move the slider, which is ok, but it will be taken as a pending change and make
  // the validate button appear. We don't want that if we already saved the new note in another
  // place.
  private ignorePendingChanges: boolean = false;
  @Input() hasEnergyTrajectory: boolean = false;
  private modelSuggestion: number = null;

  public ngOnInit(): void {
    if (
      !this.assetEditService.asset.installationYear &&
      this.assetEditService.addMode
    ) {
      this.assetEditService.asset.installationYear =
        this.assetEditService.getPerimeter().creationYear;
    }
    this.installationYears = [];
    this.selectedInstallationYear =
      this.assetEditService.asset.installationYear;
    this.subscriptions.push(
      this.translate.get(["More details", "Hide details"]).subscribe((text) => {
        this.showMoreLabel = text["More details"];
        this.showLessLabel = text["Hide details"];
        this.toggleDetailsLabel = this.showMoreLabel;
      }),
      this.assetsApi
        .getNotationAlphaCoefficient()
        .subscribe((notationAlphaCoefficient) => {
          this.alphaCoefficient = this.assetEditService.asset.assetType
            .alphaCoefficient
            ? this.assetEditService.asset.assetType.alphaCoefficient
            : notationAlphaCoefficient
            ? notationAlphaCoefficient
            : 2;
        })
    );

    const asset = this.assetEditService.asset;
    const hasNote: boolean =
      Object.keys(asset.notes)?.length &&
      Object.keys(asset.notes).some((key) => asset.notes[key] !== null);

    if (asset.notationMissingReason && !hasNote) {
      this.hasNotation = false;
      this.reasonForMissingNotation =
        this.assetEditService.asset.notationMissingReason;
      this.assetEditService.hasNotation = false;
    } else {
      this.assetEditService.hasNotation = true;
    }

    this.missingNotationReasons = [];

    // Had to do this because missing reasons are hard coded and
    // we had to add some missing reasons at first and last position...
    const missingNotationOrder = [5, 7, 8, 2, 1, 3, 4, 6];
    missingNotationOrder.forEach((index) => {
      const elt = new NameAndValue(
        index,
        this.assetsApi.getMissingNotationReasonLabel(index)
      );
      this.missingNotationReasons.push(elt);
    });

    if (
      !this.assetEditService.asset.plannedRecycle &&
      this.assetEditService.getAssetType().plannedRecycle
    ) {
      this.assetEditService.asset.plannedRecycle = 2;
      this.plannedRecycleChanged();
    }
    if (
      !this.assetEditService.asset.plannedResale &&
      this.assetEditService.getAssetType().plannedResale
    ) {
      this.assetEditService.asset.plannedResale = 2;
      this.plannedResaleChanged();
    }

    this.getCurrentNoteAndState();

    this.subscriptions.push(
      combineLatest(
        this.scope.getSynthesisYear(),
        this.auditApi.getAuditQuestions(this.assetEditService.asset),
        this.assetsApi.getInstallationSources(),
        this.assetsApi.getConfidenceChoices(),
        this.auditApi.getNotationStates(),
        this.auditApi.getRatingReasons(),
        this.assetsApi.getNotationMissingComments()
      ).subscribe(
        ([
          year,
          questions,
          sources,
          choices,
          states,
          reasons,
          notationMissingComments,
        ]) => {
          this.year = year;
          this.notationStates = states;
          this.ratingReasons = reasons.sort((a, b) => {
            return a.ordering - b.ordering;
          });
          this.updateCurrentTheoriticalNoteAndLevel();
          this.selectedRatingReasonIds =
            this.assetEditService.asset.ratingReasons;
          this.notationMissingComments = notationMissingComments;

          const question = questions.find(
            (q) => q.mnemonic === "technical_state"
          );
          if (question) {
            const item = question.items[0];
            this.rawNote = LEVELS_MAP[item.current_note];
            this.currentLevel = this.getStateLevelForNote(this.rawNote);
            this.currentStateId = this.getStateId(this.rawNote);
            this.getRatingReasonsByState(this.currentStateId, true);
            this.verifyDataConsistency(this.rawNote);
          }
          this.installationSources = sources;
          this.confidenceChoices = choices;
          if (this.assetEditService.asset.installationSource) {
            this.selectedInstallationSourceId =
              this.assetEditService.asset.installationSource.id;
          } else if (this.installationSources.length > 0) {
            // getting the default installationSource
            let defaultSource = this.installationSources.find(
              (source) => source.isDefault
            );
            if (defaultSource) {
              this.selectedInstallationSourceId = defaultSource.id;
              this.installationSourceChanged(true);
              this.selectDefaultConfidence();
            }
          }
          this.updateDurations();
          this.getLastAuditSentence();
          if (this.fromRoadmap && !this.hasNotation) {
            setTimeout(() => {
              this.hasNotationToggle.checked = true;
            }, 100);
          }
          this.event.subscribe("ignorePendingChanges", () => {
            this.ignorePendingChanges = true;
          });

          // since we're a segment we have to fake this as the parent calls this too early
          this.ionViewDidEnter();
        }
      ),
      this.offlineApi
        .getConfig("newSiteReplacementStrategies")
        .subscribe((elts: any) => {
          if (elts) {
            this.newSiteReplacementStrategies = elts.map((elt) =>
              makeNewSiteReplacementStrategy(elt)
            );
            // use the
            if (this.assetEditService.asset.newSiteReplacementStrategy) {
              this.strategyId =
                this.assetEditService.asset.newSiteReplacementStrategy.id;
            }
          }
        }),
      this.translate.get("Remaining lifetime").subscribe((text: string) => {
        this.remainingLifetimeLabel = text;
      }),
      this.translate.get("source.calculated").subscribe((text: string) => {
        this.remainingLifetimeLabelCalculated = text;
      }),
      this.translate.get("source.audit").subscribe((text: string) => {
        this.remainingLifetimeLabelAuditor = text;
      })
    );

    let currentYear: number = moment().year();
    const numberOfYears: number = 100;
    const yearsInFuture: number = 1;
    for (let i = -yearsInFuture; i < numberOfYears; i++) {
      let year: number = currentYear - i;
      if (this.isYearAvailableForSelectedAssetType(year)) {
        this.installationYears.push({ value: year, name: "" + year });
      }
    }

    if (this.installationYears.length > 0 && !this.selectedInstallationYear) {
      this.selectedInstallationYear =
        this.installationYears[yearsInFuture].value - 5;
    }

    const now = this.datePipe.transform(
      new Date(),
      "mediumDate",
      null,
      this.locale
    );
    this.commentSuffix = this.translate.instant("see email of {{date}}", {
      date: now,
    });
  }

  updateCurrentTheoriticalNoteAndLevel() {
    this.currentTheoreticalNote =
      this.consistencyTestService.getCurrentTheoreticalNote(
        this.year,
        this.assetEditService.asset.installationYear,
        100,
        this.assetEditService.asset.installationYear,
        this.assetEditService.asset.assetType.expected_duration
      );
  }

  private getAuditNoteForAsset(): Observable<AuditNotation> {
    return new Observable((observer) => {
      if (
        this.assetEditService.asset.id !== null &&
        this.assetEditService.asset.id !== 0
      ) {
        this.subscriptions.push(
          this.auditApi.getAuditNote(this.assetEditService.asset.id).subscribe(
            (notation) => {
              observer.next(notation);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          )
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  public ionViewDidEnter(): void {
    if (this.assetEditService.addMode) {
      this.investments = this.assetEditService.asset.investments;
      this.getInvestmentsImpact();
      this.updateLifetimeLabel();
    } else {
      // We have to reload investments in case they have been changed in the
      // investments tab
      this.loadInvestments();
      this.subscriptions.push(
        this.offlineApi
          .loadAsset(
            this.assetEditService.asset.id,
            this.assetEditService.asset.offlineId
          )
          .subscribe((asset) => {
            if (asset.ratingReasons.length > 0) {
              // We also need to update the selected rating reasons, because they can get deleted
              // when coming back from a child asset that has a different technical state and
              // rating reasons that are unavailable for our state.
              this.selectedRatingReasonIds = asset.ratingReasons;
            }
          })
      );
    }
    this.hideShow = "hidden";
    this.toggleDetailsLabel = this.showMoreLabel;
    this.currentAgeLabel = this.calculateCurrentAge();
    this.hasReplacementLabel = this.calculateHasReplacementLabel();
    this.ignorePendingChanges = false;
  }

  public installationYearUpdated(): void {
    this.updateCurrentTheoriticalNoteAndLevel();
    this.updateDurations();
    this.assetEditService.installationYearUpdated();
    this.recalculateLifetimeAfterNoteHasChanged(this.rawNote);
    this.currentAgeLabel = this.calculateCurrentAge();
    this.lifecycleChanged();
  }

  /**
   *
   * @param init : If we are initializing installationSource, we don't want it to be taken as a pending
   * change and we don't want to initialize installationDateConfidence
   */
  public installationSourceChanged(init?: boolean): void {
    let selectedSource: InstallationSource = null;
    for (let i = 0; i < this.installationSources.length; i++) {
      if (
        this.installationSources[i].id === this.selectedInstallationSourceId
      ) {
        selectedSource = this.installationSources[i];
        break;
      }
    }

    this.assetEditService.installationSourceChanged(selectedSource);

    if (!init) {
      this.assetEditService.asset.installationDateConfidence =
        selectedSource?.defaultConfidence;
      this.assetEditService.installationDateConfidenceChanged();
      this.lifecycleChanged();
    }
  }

  public installationDateConfidenceChanged(): void {
    this.selectDefaultConfidence();
    this.assetEditService.installationDateConfidenceChanged();
    this.lifecycleChanged();
  }

  public plannedResaleChanged(): void {
    this.assetEditService.plannedResaleChanged();
    if (this.assetEditService.asset.plannedResale === 1) {
      this.assetEditService.asset.plannedRecycle = 0;
    }
    this.assetEditService.plannedRecycleChanged();
    this.lifecycleChanged();
  }

  public plannedRecycleChanged(): void {
    this.assetEditService.plannedRecycleChanged();
    if (this.assetEditService.asset.plannedRecycle === 1) {
      this.assetEditService.asset.plannedResale = 0;
    }
    this.assetEditService.plannedResaleChanged();
    this.lifecycleChanged();
  }

  public recycleValueChanged(ev): void {
    const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
    const numberToString = inputNumber.toString();
    if (inputNumber > this.MAX_RESALE_VALUE) {
      this.assetEditService.asset.recycleValue = parseInt(
        numberToString.substr(0, 9)
      );
    }
    this.assetEditService.recycleValueChanged();
    this.lifecycleChanged();
  }

  public resaleValueChanged(ev): void {
    const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
    const numberToString = inputNumber.toString();
    if (inputNumber > this.MAX_RESALE_VALUE) {
      this.assetEditService.asset.resaleValue = parseInt(
        numberToString.substr(0, 9)
      );
    }
    this.assetEditService.resaleValueChanged();
    this.lifecycleChanged();
  }

  public inputNumberChecker(ev) {
    const inputNumber = Math.abs(Math.trunc(parseInt(ev.target.value)));
    const numberToString = inputNumber.toString();
    if (numberToString.length > 3) {
      this.remainingLifetimeDuration = parseInt(numberToString.substr(0, 3));
      this.remainingLifetimeUpdated();
    } else {
      this.remainingLifetimeDuration = inputNumber;
      this.remainingLifetimeUpdated();
    }
  }

  public remainingLifetimeUpdated(): void {
    if (!this.ignorePendingChanges) {
      const currentYear = new Date().getFullYear();
      const assetAge =
        currentYear - this.assetEditService.asset.installationYear;
      const theoreticalRemainingLifetime =
        this.assetEditService.asset.assetType.expected_duration - assetAge;
      let durationDeviation =
        this.remainingLifetimeDuration - theoreticalRemainingLifetime;

      if (theoreticalRemainingLifetime + durationDeviation < 0) {
        // We make sure that we don't save a durationDeviation that would make the remainingLifetime negative
        durationDeviation = theoreticalRemainingLifetime;
      }

      this.assetEditService.asset.durationDeviation = durationDeviation;
      this.assetEditService.durationUpdated();
    }
    this.updateLifetimeLabel();
    this.verifyDataConsistency(this.rawNote);
    this.lifecycleChanged();
  }

  public noteUpdated(event): void {
    const newNote = LEVELS_MAP[event.note]; // note /100
    if (this.assetEditService.asset.id && typeof this.rawNote === "undefined") {
      this.rawNote = newNote;
    }

    if (this.rawNote !== newNote) {
      this.assetEditService.noteUpdated(event);
      this.rawNote = newNote;
      if (this.currentStateId !== this.getStateId(this.rawNote)) {
        // If the state has changed, we get the notation reasons for the new state
        // and update the current state
        this.currentStateId = this.getStateId(this.rawNote);
        this.getRatingReasonsByState(this.currentStateId, false);
        this.currentLevel = this.getStateLevelForNote(this.rawNote);
        const stateLevelLabel = STATE_LABELS[this.currentLevel];
        this.assetEditService.assetLevelUpdated(stateLevelLabel.toLowerCase());
      }
      if (this.ignorePendingChanges) {
        this.updateDurations();
        this.verifyDataConsistency(this.rawNote);
      } else {
        this.recalculateLifetimeAfterNoteHasChanged(this.rawNote);
      }
      // Changing the note confirms the notation status
      this.removeImportStatus(ImportableAssetStatus.NOTATION);
      // Creating the new audit sentence (user + date)
      this.event.publish("showLastAuditSentence", false);
      this.getLastAuditSentence(true);
      this.getCurrentNoteAndState(newNote);
      this.lifecycleChanged();
    }
  }

  public ratingReasonUpdated(selectedReasons: number[]): void {
    if (!this.ignorePendingChanges) {
      this.assetEditService.ratingReasonUpdated(selectedReasons);
      this.lifecycleChanged();
    }
  }

  public onSelectClicked(): void {
    // hacky way to scroll to the selected item in a list but there doesn't seem a better way
    setTimeout(() => {
      let group = document.getElementsByClassName("alert-radio-group");
      if (group) {
        if (group.length) {
          let check = group[0].querySelector('[aria-checked="true"]');
          if (check) {
            check.scrollIntoView({ block: "center" });
          }
        }
      }
    }, 500);
  }

  /** Show/hide optional fields when the user clicks on "show more / show less" */
  public toggleDetails() {
    if (this.hideShow === "hidden") {
      this.hideShow = "";
      this.toggleDetailsLabel = this.showLessLabel;
      this.updateLifetimeLabel();
    } else {
      this.hideShow = "hidden";
      this.toggleDetailsLabel = this.showMoreLabel;
    }
  }

  public async showLifecycleInfo() {
    let messageBox = await this.alertCtrl.create({
      header: this.translate.instant("Remaining lifetime"),
      message: this.translate.instant(
        "This value doesn't include future investment which may extend the asset lifetime"
      ),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {},
        },
      ],
    });
    messageBox.present();
  }

  public async showReplacementInfo() {
    let messageBox = await this.alertCtrl.create({
      header: this.translate.instant("Replacement"),
      message:
        this.translate.instant(
          "For scheduling the replacement, after creating the asset,"
        ) +
        " " +
        this.translate.instant(
          'you can attach an investment with the "replacement" type'
        ),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {},
        },
      ],
    });
    await messageBox.present();
  }

  public newSiteReplacementStrategyChanged() {
    let selectedChoices = this.newSiteReplacementStrategies.filter(
      (elt) => elt.id === this.strategyId
    );
    if (selectedChoices.length) {
      this.assetEditService.asset.newSiteReplacementStrategy =
        selectedChoices[0];
    } else {
      this.assetEditService.asset.newSiteReplacementStrategy = null;
    }
    this.assetEditService.saveNewSiteReplacementStrategy();
    this.lifecycleChanged();
  }

  private makeDuration(value: number): Observable<Duration> {
    return this.translate
      .get("asset-lifecycle.duration", { value })
      .pipe(map((name) => ({ name, value })));
  }

  public updateDurations(duration?: number): void {
    if (duration || duration === 0) {
      this.remainingLifetimeDuration = duration;
      this.remainingLifetimeUpdated();
    } else {
      this.remainingLifetimeDuration =
        this.assetEditService.getRemainingLifetime();
    }
  }

  private updateLifetimeLabel(): void {
    let duration = 0;
    let years = " " + this.translate.instant("years");
    let year = " " + this.translate.instant("years");
    let expectedTotalLifeTimeLabel = this.translate.instant(
      "expected total lifetime"
    );

    if (this.assetEditService.asset.durationDeviation) {
      duration = this.assetEditService.asset.durationDeviation;
    }
    let assetAge = this.year - this.assetEditService.asset.installationYear;
    let expectedLifetime =
      this.assetEditService.getAssetType().expected_duration;
    let lifetimeWithDeviation = expectedLifetime + duration;
    let endOfLifeYearWithInvest =
      this.assetEditService.asset.installationYear +
      assetAge +
      this.investmentsLifetime;
    let lifetimeNoDeviation =
      endOfLifeYearWithInvest - this.assetEditService.asset.installationYear;
    this.theoreticalLifetimeForDisplay =
      "" + expectedLifetime + (expectedLifetime > 1 ? years : year);
    this.lifetimeForDisplay =
      "" + lifetimeWithDeviation + (lifetimeWithDeviation > 1 ? years : year);

    if (
      this.assetEditService.asset &&
      this.assetEditService.asset.installationYear > 0
    ) {
      // Lifetime without impact
      this.endOfLifeYearNoInvest =
        this.assetEditService.asset.installationYear +
        assetAge +
        Number(this.remainingLifetimeDuration);
      this.enfOfLifeYearNoImpactForDisplay =
        this.endOfLifeYearNoInvest +
        " (" +
        expectedTotalLifeTimeLabel +
        " : " +
        this.lifetimeForDisplay +
        ")";
      // Lifetime with impact
      this.endOfLifeYearWithInvestForDisplay =
        endOfLifeYearWithInvest +
        " (" +
        expectedTotalLifeTimeLabel +
        " : " +
        lifetimeNoDeviation +
        " ";
      if (lifetimeNoDeviation > 1) {
        this.endOfLifeYearWithInvestForDisplay += years + ")";
      } else {
        this.endOfLifeYearWithInvestForDisplay += year + ")";
      }
    } else {
      this.enfOfLifeYearNoImpactForDisplay = "-";
    }
    this.investmentsImpact =
      endOfLifeYearWithInvest - this.endOfLifeYearNoInvest;
    this.hasImpact = this.investmentsImpact > 0;
    this.investmentsImpactLabel = this.makeInvestmentsImpactLabel(
      this.investmentsImpact
    );
  }

  private isYearAvailableForSelectedAssetType(year: number): boolean {
    let selectedCategory: Category = this.assetEditService.getCategory();
    let selectedSubCategory: SubCategory =
      this.assetEditService.getSubCategory();
    let selectedAssetType: AssetType = this.assetEditService.getAssetType();

    if (
      selectedCategory == null ||
      selectedSubCategory == null ||
      selectedAssetType == null
    ) {
      return false;
    }

    if (
      selectedCategory.availableFrom &&
      year < selectedCategory.availableFrom
    ) {
      return false;
    }

    if (
      selectedCategory.availableFrom &&
      year < selectedCategory.availableFrom
    ) {
      return false;
    }

    if (
      selectedSubCategory.availableFrom &&
      year < selectedSubCategory.availableFrom
    ) {
      return false;
    }

    if (
      selectedSubCategory.availableFrom &&
      year < selectedSubCategory.availableFrom
    ) {
      return false;
    }

    if (
      selectedAssetType.availableFrom &&
      year < selectedAssetType.availableFrom
    ) {
      return false;
    }

    if (selectedAssetType.availableTo && year > selectedAssetType.availableTo) {
      return false;
    }

    return true;
  }

  /**
   * FIXME: We can only do it if no audit has change the lifetime hasn't change by a previous audit
   * The actual model doesn't take into account intermediairy audit
   *
   * @param note
   * @param durationDeviation
   */
  private verifyDataConsistency(note: number): void {
    const asset = this.assetEditService.asset;

    if (note !== undefined && asset.installationYear) {
      let durationDeviation = asset.durationDeviation;
      let computationNote = note;

      const currentLifetime =
        asset.assetType.expected_duration + durationDeviation;
      let age = this.year - asset.installationYear;
      let inputLifetime = currentLifetime;
      if (note === 100) {
        // If Technical State is "New", we consider the remaining lifetime is equal to the theoretical lifetime
        // which means that its age is 0
        inputLifetime = currentLifetime - age;
        age = 0;
        computationNote = note;
      }
      const technical_state_changed_on =
        this.assetEditService.asset.technical_state_changed_on;
      let calculateYear;
      if (!technical_state_changed_on) {
        calculateYear = this.year;
      } else {
        calculateYear =
          typeof (technical_state_changed_on as any).getYear === "function"
            ? (<any>technical_state_changed_on).getFullYear()
            : new Date(technical_state_changed_on).getFullYear();
      }
      this.suggestedLifetime =
        this.consistencyTestService.areTechnicalStateAndLifetimeConsistent(
          calculateYear || this.year,
          age > 0 ? asset.installationYear : this.year,
          computationNote,
          asset.assetType.expected_duration,
          inputLifetime,
          0,
          this.alphaCoefficient
        );

      if (this.suggestedLifetime) {
        this.dangerClass = "danger-class";
      } else {
        this.dangerClass = "";
      }

      let remainingLifeTime =
        currentLifetime - (this.year - asset.installationYear);
      let suggestedRemainingLifetime =
        this.consistencyTestService.getEstimatedRemainingLifetime(
          this.year,
          age ? asset.installationYear : this.year,
          computationNote,
          asset.assetType.expected_duration,
          this.alphaCoefficient
        );
      this.modelSuggestion = suggestedRemainingLifetime;

      if (suggestedRemainingLifetime !== remainingLifeTime) {
        this.remainingLifetimeSource = this.remainingLifetimeLabelAuditor;
      } else {
        this.remainingLifetimeSource = this.remainingLifetimeLabelCalculated;
      }
    }
  }

  private recalculateLifetimeAfterNoteHasChanged(note: number): void {
    const asset = this.assetEditService.asset;
    if (note !== undefined && asset.installationYear) {
      this.currentLevel = this.getStateLevelForNote(note);
      let suggestedLifetime =
        this.consistencyTestService.getEstimatedRemainingLifetime(
          this.year,
          asset.installationYear,
          note,
          asset.assetType.expected_duration,
          this.alphaCoefficient
        );
      let age = this.year - asset.installationYear;
      if (note === 100) {
        // If the asset in "New", then consider that his remainingLifeTime is the theoreticalLifetime
        age = 0;
      }
      this.remainingLifetimeSource = this.remainingLifetimeLabelCalculated;
      this.remainingLifetimeDuration = Math.min(
        asset.assetType.expected_duration,
        suggestedLifetime
      );
      this.remainingLifetimeDuration = Math.max(
        0,
        this.remainingLifetimeDuration
      );
      this.modelSuggestion = this.remainingLifetimeDuration;
    }
    this.remainingLifetimeUpdated();
  }

  private removeImportStatus(status: ImportableAssetStatus): void {
    this.assetEditService.asset.importStatuses = <ImportableAssetStatus[]>(
      this.importStatusService.removeImportStatus(
        status,
        this.assetEditService.asset.importStatuses
      )
    );
    this.assetEditService.importStatusesUpdated();
  }

  private calculateCurrentAge(): string {
    let installation_year = this.assetEditService.asset.installationYear;
    let current_age = moment().year() - installation_year;

    if (
      this.assetEditService.asset &&
      this.assetEditService.asset.installationYear > 0
    ) {
      if (current_age === 1) {
        return "" + current_age + " " + this.translate.instant("year");
      } else {
        return "" + current_age + " " + this.translate.instant("years");
      }
    } else {
      return "-";
    }
  }

  private calculateHasReplacementLabel(): string {
    let hasReplacement: boolean = false;
    for (let investment of this.investments) {
      if (investment.investmentType.replacement) {
        hasReplacement = true;
        break;
      }
    }
    return hasReplacement
      ? this.translate.instant("Yes")
      : this.translate.instant("No");
  }

  private getInvestmentsImpact() {
    this.investmentsWithImpact = 0;
    this.investmentsLifetime = 0;
    for (let investment of this.investments) {
      let isReplacement =
        investment.investmentType && investment.investmentType.replacement;
      if (!isReplacement && !investment.hasNoImpact) {
        this.investmentsWithImpact++;
        let investmentLifetimeFromThisYear =
          investment.finalScheduleTo -
          this.year +
          investment.lifetimeAfterInvestment;
        if (investmentLifetimeFromThisYear > this.investmentsLifetime) {
          this.investmentsLifetime = investmentLifetimeFromThisYear;
        }
      }
    }
  }

  private makeInvestmentsImpactLabel(impact: Number) {
    const invest = this.translate.instant("invest.");
    const hasReplacementInvest = this.investments.find(
      (i) =>
        i.investmentType &&
        i.investmentType.replacement &&
        !i.hasNoImpact &&
        !i.status.hypothesis
    );

    if (impact > 0 && this.investmentsWithImpact > 0) {
      return this.investmentsWithImpact + " " + invest;
    } else if (hasReplacementInvest) {
      return this.translate.instant("Yes");
    } else {
      return this.translate.instant("No");
    }
  }

  private getStateLevelForNote(note: number): number {
    const stateLevel = STATE_LIMITS.findIndex((n) => n >= note);
    return stateLevel;
  }

  private getStateId(note: number): number {
    let stateId: number;
    this.notationStates.map((state) => {
      if (state.high !== 100) {
        if (note >= state.low && note < state.high) stateId = state.id;
      } else {
        if (note > state.low && note <= state.high) stateId = state.id;
      }
    });
    return stateId;
  }

  /**
   * Since the ion-select component doesn't update itself when we edit
   * the selectedRatingReasonIds, we need to hide it then display it after
   * a timeout.
   * @param state
   * @param init
   */
  public getRatingReasonsByState(state: number, init: boolean) {
    this.updatingRatingReasons = true;
    // we store the selected reasons in a temporary array
    let tempSelectedReasonsIds = this.selectedRatingReasonIds || [];
    this.selectedRatingReasonIds = [];

    this.ratingReasonsForState = [];
    this.ratingReasons.map((reason) => {
      // if the reason is available for this state
      if (reason.only_for_states.indexOf(state) !== -1) {
        this.ratingReasonsForState.push(reason);
        // Set the default reason for state selected
        if (
          tempSelectedReasonsIds.length === 0 &&
          reason.default_for_states.indexOf(state) !== -1
        ) {
          tempSelectedReasonsIds.push(reason.id);
        }
      } else if (!init) {
        // If the reason isn't available but selected, we unselect it.
        let selectedReasonIndex = tempSelectedReasonsIds.indexOf(reason.id);
        if (selectedReasonIndex !== -1) {
          tempSelectedReasonsIds.splice(selectedReasonIndex, 1);
        }
      }
    });
    this.selectedRatingReasonIds = tempSelectedReasonsIds;
    if (!init) this.ratingReasonUpdated(this.selectedRatingReasonIds);
    setTimeout(() => {
      this.updatingRatingReasons = false;
    }, 100);
  }

  public onHasNotationChanged() {
    // Reset mandatory variables
    this.reasonForMissingNotation = MissingNotationReason.NOT_SET;
    this.assetEditService.hasNotation = this.hasNotation;
    const question = this.assetEditService.technicalStateQuestion;
    const note = { itemId: question.items[0].id, note: null };
    this.assetEditService.noteHasBeenChanged = false;

    if (!this.hasNotation) {
      // Technical state switched off
      this.assetEditService.noteUpdated(note);
      this.rawNote = null;
      this.currentLevel = 0;
      this.assetEditService.assetLevelUpdated("");
      this.selectedRatingReasonIds = [];
      this.ratingReasonUpdated(this.selectedRatingReasonIds);
      // Open the missingNotationReason select
      let ev = new UIEvent("UIEvent", {});
      this.missingNotationSelect.open(ev);
    } else {
      // Technical state switched on
      this.assetEditService.asset.notationMissingReason =
        MissingNotationReason.NOT_SET;
      this.assetEditService.saveNotationMissingReason();
      const item = question.items[0];
      if (item.current_note) {
        this.rawNote = LEVELS_MAP[item.current_note];
        this.assetEditService.noteUpdated(note);
        this.currentLevel = this.getStateLevelForNote(this.rawNote);
        const stateLevelLabel = STATE_LABELS[this.currentLevel];
        this.assetEditService.assetLevelUpdated(stateLevelLabel.toLowerCase());
      } else {
        this.rawNote = null;
      }
    }
    this.lifecycleChanged();
  }

  public onMissingNotationReasonChanged() {
    this.assetEditService.asset.notationMissingReason =
      this.reasonForMissingNotation;
    this.assetEditService.saveNotationMissingReason();
    if (this.reasonForMissingNotation !== MissingNotationReason.NOT_SET) {
      this.removeImportStatus(ImportableAssetStatus.NOTATION);
    }
    const comment = this.notationMissingComments.find(
      (comment) =>
        comment.notation_missing_reason === this.reasonForMissingNotation
    );
    if (comment) {
      this.assetEditService.asset.notationMissingComment =
        comment.default_comment + " - " + this.commentSuffix;
      this.assetEditService.saveNotationMissingComment();
    } else {
      this.assetEditService.asset.notationMissingComment = "";
      this.assetEditService.saveNotationMissingComment();
    }
    this.lifecycleChanged();
  }

  public notationMissingCommentChanged() {
    this.assetEditService.saveNotationMissingComment();
    this.lifecycleChanged();
  }

  private getLastAuditSentence(newAudit?: boolean) {
    // Getting the user and the date of the last audit
    if (!newAudit) {
      let userId = this.assetEditService.asset.technical_state_changed_by;
      let lastAuditDate =
        this.assetEditService.asset.technical_state_changed_on;
      this.subscriptions.push(
        this.makeAuditSentence(userId, lastAuditDate).subscribe((sentence) => {
          if (sentence !== null) {
            this.event.publish("lastAuditSentence", sentence);
            this.event.publish("showLastAuditSentence", true);
          } else {
            this.event.publish("showLastAuditSentence", true);
          }
        })
      );
    } else {
      // Creating a new sentence for the new audit with the current user and date
      this.authService.getCurrentUser().then((usr) => {
        let currentDate = moment().format();
        this.assetEditService.updateLastAuditUserAndDate(
          usr.get_user_id,
          currentDate
        );
        this.subscriptions.push(
          this.makeAuditSentence(usr.get_user_id, currentDate).subscribe(
            (sentence) => {
              this.event.publish("lastAuditSentence", sentence);
            }
          )
        );
      });
    }
  }

  private makeAuditSentence(userId: number, date: any): Observable<string> {
    return new Observable((observer) => {
      let formatedDate = this.datePipe.transform(
        date,
        "mediumDate",
        null,
        this.locale
      );
      let auditSentence: string;
      this.subscriptions.push(
        this.userService.getUserFromId(userId).subscribe(
          (user) => {
            let userName = UsernamePipe.prototype.transform(user, true);
            const translationParams = {
              userName: userName,
              date: formatedDate,
            };
            if (userName !== "" && date !== null) {
              auditSentence = this.translate.instant(
                "Rated by {{userName}} on {{date}}",
                translationParams
              );
            } else {
              console.warn("Missing informations about the last audit");
            }
            observer.next(auditSentence);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        )
      );
    });
  }

  /**
   * Picking the default confidence for the selected installation source
   */
  private selectDefaultConfidence() {
    let selectedSource = this.installationSources.find(
      (source) => source.id === this.selectedInstallationSourceId
    );
    if (selectedSource) {
      this.assetEditService.asset.installationDateConfidence =
        selectedSource.defaultConfidence;
    }
  }

  /**
   * Calling the backend to get the current note and state for the asset.
   * Not the note/state given during the last audit.
   */
  private getCurrentNoteAndState(newNote?) {
    if (newNote) {
      const stateLevelLabel = STATE_LABELS[this.currentLevel];
      this.currentNoteLabel =
        this.translate.instant(firstLetterUpperCase(stateLevelLabel)) +
        " (" +
        newNote +
        "/100)";
    } else {
      this.subscriptions.push(
        this.getAuditNoteForAsset().subscribe(
          (notation) => {
            if (notation) {
              notation.notes.forEach((note) => {
                if (note["year"] === new Date().getFullYear()) {
                  this.currentNoteLabel =
                    this.translate.instant(
                      firstLetterUpperCase(note["level"])
                    ) +
                    " (" +
                    note["note"] +
                    "/100)";
                }
              });
            } else {
              this.currentNoteLabel =
                "(" + this.translate.instant("Error while loading data") + ")";
            }
          },
          (err) => {
            this.currentNoteLabel =
              "(" + this.translate.instant("Offline") + ")";
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private lifecycleChanged() {
    const params = this.getNextStep();
    this.changed.emit({
      nextStep: params.nextStep,
      goNext: false,
      nextLabel: params.nextLabel,
      stepValid: this.assetEditService.isLifecycleValid(),
      remainingLifetime: this.remainingLifetimeDuration,
      suggestedLifetime: this.modelSuggestion,
    });
  }

  private getNextStep() {
    let nextStep = null;
    let nextLabel: string = "next";
    if (this.assetEditService.hasExpertMode) {
      nextStep = StepsIds.EXPERT;
    } else if (this.assetEditService.hasOtherNotations) {
      nextStep = StepsIds.OTHERNOTATIONS;
    } else if (this.hasEnergyTrajectory) {
      nextStep = StepsIds.CONSUMPTION;
    } else if (this.assetEditService.needsParent()) {
      nextStep = StepsIds.PARENT;
    } else {
      nextStep = 0;
      nextLabel = "addAsset";
    }
    return { nextStep, nextLabel };
  }

  loadInvestments(refresher: any = null) {
    if (this.assetEditService.asset) {
      this.subscriptions.push(
        this.investmentsApi
          .getInvestments(this.assetEditService.asset)
          .subscribe((investments) => {
            this.investments = investments;
            // The investments count is used to update the total number in the investments tab (in asset-details)
            this.event.publish(
              "updateAssetsInvestmentsTotal",
              investments.length
            );
            for (let i = 0; i < this.investments.length; i++) {
              let investment: Investment = this.investments[i];
              let scheduleRange: Array<number> = investment.getSchedule();
              if (scheduleRange) {
                investment.finalSchedule = scheduleRange[0];
                investment.finalScheduleTo = scheduleRange[1];
              }
            }

            this.investments.sort(
              (invest1: Investment, invest2: Investment) => {
                let year1 = invest1.finalSchedule;
                let year2 = invest2.finalSchedule;
                let yearTo1 = invest1.finalScheduleTo;
                let yearTo2 = invest2.finalScheduleTo;
                if (year1 < year2) {
                  return -1;
                } else if (year1 > year2) {
                  return 1;
                } else {
                  if (yearTo1 < yearTo2) {
                    return -1;
                  } else if (yearTo1 > yearTo2) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              }
            );
            this.getInvestmentsImpact();
            this.updateLifetimeLabel();
            // this.loading = false;
            if (refresher) {
              refresher.target.complete();
            }
          })
      );
    } else {
      if (refresher) {
        refresher.target.complete();
      }
    }
  }

  public openRatingReasonsSelect() {
    let ev = new UIEvent("UIEvent", {});
    this.ratingReasonsSelect.open(ev);
  }
}
