<div
  class="footer-container background-{{ color }} text-style1"
  (click)="footerClicked($event)"
>
  <ion-row class="ion-justify-content-evenly">
    <ion-col *ngIf="hasCancel" size="6">
      <ion-button
        class="footer-button {{ color }}"
        fill="outline"
        color="{{
          color === 'mono-perimeter'
            ? 'light'
            : color === 'roadmap'
            ? ''
            : 'primary'
        }}"
        [disabled]="(!cancelEnabled && !enabled) || inProgress"
        (click)="clickCancel($event)"
        expand="block"
      >
        <ion-text>{{ cancelLabel }}</ion-text>
      </ion-button>
    </ion-col>
    <ion-col *ngIf="hasPause" size="6">
      <ion-button
        class="footer-button {{ color }}"
        fill="outline"
        color="{{
          color === 'mono-perimeter'
            ? 'light'
            : color === 'roadmap'
            ? ''
            : 'primary'
        }}"
        (click)="clickPause($event)"
        expand="block"
      >
        {{ pauseLabel }}
      </ion-button>
    </ion-col>
    <ion-col size="{{ hasPause || hasCancel ? 6 : 8 }}">
      <ion-button
        class="footer-button {{ color }}"
        fill="outline"
        color="{{
          color === 'mono-perimeter'
            ? 'light'
            : color === 'roadmap'
            ? ''
            : 'primary'
        }}"
        [disabled]="!enabled || inProgress"
        (click)="clickNext($event)"
        expand="block"
      >
        {{ displayedNextLabel }}
      </ion-button>
    </ion-col>
  </ion-row>
</div>
