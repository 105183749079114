import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, from } from "rxjs";
// import 'rxjs/add/operator/map';
import * as moment from "moment";

import { Asset, Perimeter, setAsset } from "@structs/assets";
import {
  AuditSynthesis,
  AuditSynthesisChange,
  makeAuditSynthesis,
  AuditResultItem,
  AuditState,
  AuditBuilding,
} from "@structs/audit";
import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { PicturesLoaderService } from "./pictures-loader.service";
import { ScopeService } from "./scope.service";
import { TranslateService } from "@ngx-translate/core";
import { firstLetterUpperCase } from "@structs/utils";
import { concatMap, map } from "rxjs/operators";

@Injectable()
export class SynthesisService {
  public auditSynthesis: BehaviorSubject<AuditSynthesisChange> = null;

  private synthesisPerimeter: Perimeter = null;

  constructor(
    private backend: BackendService,
    private offlineApi: OfflineService,
    private picturesLoaderService: PicturesLoaderService,
    private scope: ScopeService,
    private translate: TranslateService
  ) {
    this.auditSynthesis = new BehaviorSubject(null);
  }

  /**
   returns Observable on the current audit synthesis data
   */
  public watchSynthesis(): Observable<AuditSynthesisChange> {
    return this.auditSynthesis.asObservable();
  }

  /**
   * Returns CAPEX audit synthesis for a given perimeter and a given year (may be future year)
   * @param perimeter
   * @param year
   * @returns Observable<AuditSynthesis>
   */
  public getAuditSynthesis(
    perimeter: Perimeter,
    year: number
  ): Observable<AuditSynthesis> {
    this.synthesisPerimeter = perimeter;
    return new Observable((observer) => {
      if (perimeter) {
        let currentTimestamp: number = Math.round(+moment() / 1000);
        this.offlineApi.getAuditSynthesisLastRefresh(perimeter).subscribe(
          (lastRefresh: number) => {
            let url =
              "/audit/api/synthesis/" +
              perimeter.id +
              "/" +
              year +
              "/" +
              lastRefresh +
              "/";
            this.backend.get(url).subscribe(
              (jsonData) => {
                let auditSynthesis: AuditSynthesis =
                  makeAuditSynthesis(jsonData);
                auditSynthesis.assets = auditSynthesis.assets.map((asset) =>
                  setAsset(asset)
                );
                // Preload thumbnails of new assets and investments
                auditSynthesis.assets.map((asset) => {
                  // TODO from Luc that was already on the ion3 app : re-work audit-synthesis
                  asset.children.map((childAsset) => {
                    this.picturesLoaderService.preloadThumbnails(
                      childAsset.pictures
                    );
                  });
                  this.picturesLoaderService.preloadThumbnails(asset.pictures);
                });
                auditSynthesis.investments.map((investment) =>
                  this.picturesLoaderService.preloadThumbnails(
                    investment.pictures
                  )
                );

                if (
                  perimeter.pictures !== undefined &&
                  perimeter.pictures.length > 0
                ) {
                  this.picturesLoaderService.preloadThumbnails(
                    perimeter.pictures
                  );
                }

                // store global investments
                from(auditSynthesis.buildings)
                  .pipe(
                    concatMap((building: AuditBuilding) => {
                      return this.offlineApi.storeGlobalInvestments(building);
                    })
                  )
                  .subscribe(
                    () => {},
                    () => {},
                    () => {
                      this.offlineApi
                        .setAuditSynthesisLastRefresh(
                          perimeter,
                          currentTimestamp
                        )
                        .subscribe(
                          () => {},
                          () => {},
                          () => {
                            observer.next(auditSynthesis);
                            observer.complete();
                            // inform main app for saving it on cache
                            this.auditSynthesis.next(
                              new AuditSynthesisChange(
                                perimeter,
                                year,
                                auditSynthesis
                              )
                            );
                          }
                        );
                    }
                  );
              },
              (err) => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
      } else {
        observer.error("No perimeter");
        observer.complete();
      }
    });
  }

  /**
   * Checks if an audit exists in the cache, otherwise loads it.
   *
   * @param perimeter
   * @param year
   *
   * @returns An observable emitting `true` if the audit was in cache, `false` otherwise.
   */
  public ensureAuditSynthesis(
    perimeter: Perimeter,
    year: number
  ): Observable<boolean> {
    return new Observable((observer) => {
      this.offlineApi.getAuditSynthesis(perimeter, year).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        () => {
          this.getAuditSynthesis(perimeter, year).subscribe(
            () => {
              observer.next(false);
              observer.complete();
            },
            (err) => {
              observer.error(err);
            }
          );
        }
      );
    });
  }

  /**
   * Invalidate AuditSynthesis cache when something has changed
   * @param asset
   * @param notes : list of notes
   * @returns Observable
   */
  public updateAssetNoteInSynthesis(
    asset: Asset,
    notes: Array<AuditResultItem>
  ): Observable<Asset> {
    if (notes && notes.length > 0) {
      asset.expectedLifetime = notes[0].expectedLifetime;
    }
    return this.offlineApi.storeAsset(asset);
  }

  /**
   * Update the audit element base on
   * @param asset
   * @returns Observable
   */
  public updateAssetInSynthesis(asset: Asset): Observable<boolean> {
    return new Observable((observer) => {
      this.offlineApi.storeAsset(asset).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public updateAssetAccessInSynthesis(asset: Asset): Observable<Asset> {
    return this.offlineApi.storeAsset(asset);
  }

  public removeAssetFromSynthesis(asset: Asset): Observable<boolean> {
    return new Observable((observer) => {
      this.scope.getCurrentMultiPerimeter().subscribe(
        (perimeter) => {
          this.offlineApi.getAuditSynthesisMap().subscribe(
            (auditSynthesisMap: any) => {
              let newMap: Map<string, AuditSynthesis> =
                this._removeAssetFromMap(perimeter, asset, auditSynthesisMap);
              this.offlineApi.storeAuditSynthesisMap(newMap).subscribe(
                () => {
                  observer.next(true);
                  observer.complete();
                },
                (err) => {
                  observer.error(err);
                  observer.complete();
                }
              );
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * get the global notes for an asset
   * @param asset
   * @returns Observable<boolean> : success of the operation
   */
  public getAuditNote(asset: Asset): Observable<Array<AuditResultItem>> {
    let url = "/audit/api/notation/" + asset.id + "/";
    return new Observable((observer) => {
      // push to server
      this.backend.get(url).subscribe(
        (jsonData) => {
          observer.next(jsonData.notes);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getAuditStates(): Observable<Array<AuditState>> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("auditStates").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          // Overiding the backend translations
          data.forEach((auditState) => {
            auditState.name = this.translate.instant(
              firstLetterUpperCase(auditState.level)
            );
          });
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public searchAsset(
    perimeterId: number,
    criteria: Array<any>
  ): Observable<Array<number>> {
    return new Observable((observer) => {
      this.backend
        .post("/audit/api/search-asset/" + perimeterId + "/", criteria)
        .subscribe(
          (jsonData) => {
            observer.next(jsonData);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  public getAssets(): Observable<Asset[]> {
    return this.offlineApi
      .getAuditSynthesisEx()
      .pipe(map((synthesis) => synthesis.assets));
  }

  /**
   * Quickly get the total number of assets
   */
  public getAssetNumber(
    perimeter: Perimeter,
    year: number
  ): Observable<number> {
    return this.offlineApi.getAuditSynthesis(perimeter, year).pipe(
      map((auditSynthesis) => {
        return auditSynthesis.assets.reduce(
          // taking into account the children asset
          (accumulate, currAsset) => {
            const children = currAsset.children.filter(
              (c) => !auditSynthesis.assets.some((a) => a.id === c.id)
            );
            return accumulate + children.length + 1;
          },
          0
        );
      })
    );
  }

  // returns an observer of an asset. The subscribe will be called for every asset in the synthesis
  public browseSynthesisAssets(): Observable<Asset> {
    return new Observable((observer) => {
      this.offlineApi
        .getAuditSynthesisEx()
        .subscribe((auditSynthesis: AuditSynthesis) => {
          from(auditSynthesis.assets).subscribe(
            (asset: Asset) => {
              observer.next(asset);
            },
            (err) => {},
            () => {
              // all assets have been handled. Complete the observer
              observer.complete();
            }
          );
        });
    });
  }

  private _removeAssetFromMap(
    perimeter: Perimeter,
    asset: Asset,
    mapObj: any
  ): Map<string, AuditSynthesis> {
    let newMap: Map<string, AuditSynthesis> = new Map<string, AuditSynthesis>();

    let keys = [];
    let currentYear: number = moment().year();
    for (let i = 0; i < 5; i++) {
      keys.push(perimeter.id + "/" + currentYear + i);
    }
    Object.keys(mapObj).forEach((key: string) => {
      if (key.search("_") === 0) {
        // this can be object property and should be ignored
        return;
      }
      let auditSynthesis = mapObj[key];
      let assetEltIndex = -1;
      // Look for an element corresponding to the asset
      for (
        let i = 0, l = auditSynthesis.elements.length;
        i < l && assetEltIndex < 0;
        i++
      ) {
        let elt = auditSynthesis.elements[i];
        if (
          (elt.assetId > 0 && elt.assetId === asset.id) ||
          (elt.offlineId > 0 && elt.offlineId === asset.offlineId)
        ) {
          // found! mark it for deletion
          assetEltIndex = i;
        }
      }
      // if found : delete the element
      if (assetEltIndex >= 0) {
        auditSynthesis.elements.splice(assetEltIndex, 1);
      }
      newMap[key] = auditSynthesis;
    });
    return newMap;
  }
}
