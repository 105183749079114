<ion-searchbar
  [value]="searchText"
  (ionInput)="onSearchChanged($event)"
  (ionCancel)="onSearchCancelled()"
  placeholder="{{ 'Indicate a keyword' | translate }}"
  debounce="500"
  style="margin-bottom: -0.96rem; z-index: 1"
>
</ion-searchbar>

<ion-content>
  <ng-container *ngIf="searchMode; else manualMode">
    <cdk-virtual-scroll-viewport
      class="ion-content-scroll-host"
      [itemSize]="100"
      minBufferPx="900"
      maxBufferPx="1350"
    >
      <ion-list lines="full">
        <ion-item
          *cdkVirtualFor="let searchResult of searchResults; let i = index"
          (click)="selectSearchResult(searchResult, i)"
          [color]="selectedSearchResult === i ? 'asset-details' : null"
        >
          <ion-label>
            <ion-row>
              <ion-col> {{ searchResult.assetType.name }} </ion-col>
            </ion-row>
            <ion-row class="results-line-2">
              <ion-col class="ion-nowrap">
                {{ searchResult.category.name }} >
                {{ searchResult.subCategory.name }}
              </ion-col>
            </ion-row>
          </ion-label>
        </ion-item>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-template #manualMode>
    <ion-list lines="full" [hidden]="searchMode">
      <input-item [legend]="'Category' | translate">
        <ion-select
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [(ngModel)]="selectedCategoryId"
          placeholder="-"
          (ngModelChange)="categoryChanged(true)"
          interface="action-sheet"
          [disabled]="assetEditService.readOnly"
        >
          <ion-select-option
            *ngFor="let category of categories"
            [value]="category.id"
            >{{ category.name }}
          </ion-select-option>
        </ion-select>
      </input-item>

      <input-item [legend]="'Sub-category' | translate">
        <ion-select
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [(ngModel)]="selectedSubCategoryId"
          placeholder="-"
          (ngModelChange)="subcategoryChanged(true)"
          interface="action-sheet"
          [disabled]="assetEditService.readOnly || selectedCategoryId === null"
          #subCategorySelect
        >
          <ion-select-option
            *ngFor="let subCategory of subCategories"
            [value]="subCategory.id"
            >{{ subCategory.name }}
          </ion-select-option>
        </ion-select>
      </input-item>

      <input-item [legend]="'Asset type' | translate">
        <ion-select
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [(ngModel)]="selectedAssetTypeId"
          placeholder="-"
          (ngModelChange)="assettypeChanged()"
          interface="action-sheet"
          [disabled]="
            assetEditService.readOnly || selectedSubCategoryId === null
          "
          #assetTypeSelect
        >
          <ion-select-option
            *ngFor="let assetType of assetTypes"
            [value]="assetType.id"
            >{{ assetType.name }}
          </ion-select-option>
        </ion-select>
      </input-item>
    </ion-list>
  </ng-template>
</ion-content>
