import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";

/**
 * Service to generate a simple "coming soon feature" alert.
 */
@Injectable()
export class ComingSoonService {
  private comingSoonLabel: string;
  private okLabel: string;

  constructor(
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {
    this.translate
      .get("This feature will soon be available")
      .subscribe((comingSoonLabel) => (this.comingSoonLabel = comingSoonLabel));
    this.translate.get("Ok").subscribe((okLabel) => (this.okLabel = okLabel));
  }

  async create(
    title?: string,
    subTitle?: string
  ): Promise<HTMLIonAlertElement> {
    return this.alertCtrl.create({
      header: title ? title : this.comingSoonLabel,
      subHeader: subTitle ? subTitle : null,
      buttons: [this.okLabel],
    });
  }

  async showComingSoonAlert(title?: string, subTitle?: string) {
    let comingSoonAlert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle
        ? subTitle
        : this.translate.instant("This feature is unavailable"),
      buttons: [this.okLabel],
    });
    await comingSoonAlert.present();
  }
}
