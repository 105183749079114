<ion-item-sliding #item>
  <ion-item (click)="clickItem()">
    <ion-label color="primary">{{ perimeter.name }}</ion-label>
  </ion-item>
  <ion-item-options side="end">
    <ion-item-option color="pictures-download" (click)="exportPictures()"
      ><i class="fal fa-images"></i
    ></ion-item-option>
    <ion-item-option color="primary" (click)="exportExcel()"
      ><i class="fal fa-table"></i
    ></ion-item-option>
    <ion-item-option color="secondary" (click)="exportTechnicalPortfolio()"
      ><i class="fal fa-file-powerpoint"></i
    ></ion-item-option>
  </ion-item-options>
</ion-item-sliding>
