import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";

import {
  Building,
  makePerimeter,
  makeReferenceField,
  Perimeter,
  ReferenceField,
} from "../structs/assets";

import { BackendService } from "./backend.service";
import { ErrorsService } from "./errors.service";
import { OfflineService } from "./offline.service";
import { Cluster, makeCluster } from "../structs/cluster";
import { catchError, filter, map } from "rxjs/operators";

@Injectable()
export class ScopeService {
  synthesisYear: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  selectedPerimeter: BehaviorSubject<Perimeter> =
    new BehaviorSubject<Perimeter>(null);
  currentMultiPerimeter: BehaviorSubject<Perimeter> =
    new BehaviorSubject<Perimeter>(null);

  constructor(
    private backend: BackendService,
    private offline: OfflineService,
    private errors: ErrorsService
  ) {
    this.synthesisYear = new BehaviorSubject(null);
    this.getSynthesisYear().subscribe((year) => {
      this.synthesisYear.next(year);
    });

    this.getSelectedPerimeter().subscribe(
      (perimeter) => {
        this.selectedPerimeter.next(perimeter);
      },
      (err) => {
        console.error(err);
      }
    );

    this.currentMultiPerimeter = new BehaviorSubject<Perimeter>(null);
    this.getCurrentMultiPerimeter().subscribe(
      (perimeter) => {
        this.currentMultiPerimeter.next(perimeter);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  clearPerimetersCache(): void {
    this.offline.removeItem("perimeters").subscribe(
      () => {},
      (error) => {
        this.errors.signalError(error);
      }
    );
  }

  _reloadPerimeters(observer): void {
    this.backend.get("/audit/api/perimeters/").subscribe(
      (jsonData) => {
        let perimeters: Array<Perimeter> = [];
        for (let i = 0; i < jsonData.length; i++) {
          perimeters.push(makePerimeter(jsonData[i]));
        }
        this.offline.storeItem("perimeters", perimeters).subscribe(
          () => {
            observer.next(perimeters);
            observer.complete();
          },
          (err) => {
            console.warn("error while storing perimeters", err);
            observer.next(perimeters);
            observer.complete();
          }
        );
      },
      (err) => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  public updatePerimeters(updatedPerimeter): Observable<Perimeter[]> {
    return new Observable((observer) => {
      this.offline.getItem("perimeters").subscribe(
        (perimeters: Array<Perimeter>) => {
          if (perimeters) {
            let index = perimeters
              .map((elt) => elt.id)
              .indexOf(updatedPerimeter.id);
            if (index >= 0) {
              perimeters[index] = updatedPerimeter;
            }
            this.offline.storeItem("perimeters", perimeters).subscribe(
              () => {
                observer.next(perimeters);
                observer.complete();
              },
              (err) => {
                observer.error(err);
                observer.complete();
              }
            );
          }
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * returns Perimeters
   */
  public getPerimeters(forceRefresh = false): Observable<Array<Perimeter>> {
    return new Observable((observer) => {
      if (!forceRefresh) {
        // load perimeters from storage
        this.offline.getItem("perimeters").subscribe(
          (perimeters: Array<Perimeter>) => {
            if (perimeters) {
              observer.next(perimeters);
            } else {
              this._reloadPerimeters(observer);
            }
          },
          (err) => {
            this._reloadPerimeters(observer);
          }
        );
      } else {
        this._reloadPerimeters(observer);
      }
    });
  }

  setSelectedPerimeter(perimeter: Perimeter): Observable<void> {
    return this.offline.storeItem("selectedPerimeter", perimeter).pipe(
      catchError((err) => {
        this.errors.signalError(err);
        return throwError(err);
      })
    );
  }

  watchSelectedPerimeter(): Observable<Perimeter> {
    return this.selectedPerimeter.asObservable();
  }

  getSelectedPerimeter(): Observable<Perimeter> {
    return this.offline.getItem("selectedPerimeter");
  }

  watchCurrentMultiPerimeter(): Observable<Perimeter> {
    return this.currentMultiPerimeter.asObservable();
  }

  setCurrentMultiPerimeter(perimeter: Perimeter): Observable<void> {
    return new Observable((observer) => {
      return this.offline
        .storeItem("currentMultiPerimeter", perimeter)
        .subscribe(
          () => {
            this.currentMultiPerimeter.next(perimeter);
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getCurrentMultiPerimeter(): Observable<Perimeter> {
    return this.offline
      .getItem("currentMultiPerimeter")
      .pipe(filter((m) => !!m));
  }

  /**
   returns Observable on the current selected year for the audit synthesis
   */
  getSynthesisYear(): Observable<number> {
    return this.offline.getSynthesisYear();
  }

  /**
   returns Observable on the current selected year for the audit synthesis
   */
  watchSynthesisYear(): Observable<number> {
    return this.synthesisYear.asObservable();
  }

  /**
   set the current selected year for the audit synthesis
   */
  setSynthesisYear(year: number): void {
    this.offline.storeItem("SynthesisYear", "" + year).subscribe(
      () => {
        this.synthesisYear.next(year);
      },
      (err) => {
        this.errors.signalError(err);
      }
    );
  }

  /**
   * Get the list of ReferenceFields from the DB
   */
  getReferenceFields(): Observable<ReferenceField[]> {
    return this.offline.getConfig("refData").pipe(
      filter((rfs: any) => rfs.length > 0),
      map((referenceFieldsJson: any) =>
        referenceFieldsJson.map((rf) => makeReferenceField(rf))
      )
    );
  }

  /**
   * Get the list of Clusters from the local storage
   */
  public getClusters(): Observable<Cluster[]> {
    return this.offline.getConfig("clusters").pipe(
      filter((clusters: Cluster[]) => clusters.length > 0),
      map((jsonClusters: Cluster[]) =>
        jsonClusters.map((jsonCluster) => makeCluster(jsonCluster))
      )
    );
  }

  public getPerimeterBuilding(monoPerimeter): Observable<Building> {
    return new Observable((observer) => {
      this.getCurrentMultiPerimeter().subscribe(
        (mainPerimeter) => {
          let building = null;
          for (let perimeter of mainPerimeter.sub_perimeters) {
            const doesMatch =
              (perimeter.id && monoPerimeter.id === perimeter.id) ||
              (perimeter.localId &&
                monoPerimeter.localId === perimeter.localId);
            if (doesMatch) {
              building = Object.assign({}, perimeter.building);
              const buildingPerimeter = Object.assign({}, perimeter);
              buildingPerimeter.building = null;
              building.monosite_perimeter = buildingPerimeter;
              break;
            }
          }
          observer.next(building);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}
