import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ImportStatusService } from "src/app/services/import-status.service";
import { Asset } from "src/app/structs/assets";
import { Investment } from "src/app/structs/investments";

@Component({
  selector: "import-status-badge", // eslint-disable-line @angular-eslint/component-selector
  templateUrl: "./import-status-badge.component.html",
  styleUrls: ["./import-status-badge.component.scss"],
})
export class ImportStatusBadgeComponent implements OnInit, OnChanges {
  @Input() public asset: Asset;
  @Input() public investment: Investment;
  @Input() public cmmsSync: boolean = false;
  public status: "validated" | "ready" | "pending";
  public statusCMMS: "conflict" | "synchronized" | "none"; // Synchro avec autre appli de GMAO

  constructor(private importStatusService: ImportStatusService) {}

  public ngOnInit(): void {
    this.updateStatus();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset || changes.investment) {
      this.updateStatus();
    }
  }

  private updateStatus(): void {
    if (this.asset) {
      if (this.cmmsSync) {
        this.statusCMMS = this.importStatusService.getBadgeStatusForCMMSSync(
          this.asset
        );
      } else {
        this.status = this.importStatusService.getBadgeStatus(
          this.asset.importStatuses
        );
      }
    }
    // Disabled. For now...
    //
    // else if (this.investment) {
    //   this.status = this.importStatusService.getBadgeStatus(this.investment.importStatuses);
    // }
  }
}
