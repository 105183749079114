import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { PicturesLoaderService } from "src/app/services/pictures-loader.service";
import { Picture } from "src/app/structs/base";

@Component({
  selector: "app-picture",
  templateUrl: "./app-picture.component.html",
  styleUrls: ["./app-picture.component.scss"],
})
export class AppPictureComponent implements OnInit, OnChanges {
  @Input() public picture: Picture;
  @Input() public picturesOn: boolean = true;
  // Use thumbnail picture instead of original picture
  @Input() thumbnail = true;
  // used for image viewer page
  @Input() selected = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public error = new EventEmitter<void>();

  public readonly zoomOptions = {
    zoom: {
      maxRatio: 2,
    },
  };

  private picture$: BehaviorSubject<Picture>;

  /** Stocking picture URLS, including: thumbnail and full picture */
  public url$: Observable<{ thumbnail: SafeUrl; fullPicture: SafeUrl }>;

  constructor(
    private picturesLoaderService: PicturesLoaderService,
    private sanitizer: DomSanitizer
  ) {
    this.picture$ = new BehaviorSubject<Picture>(this.picture);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.picture) this.picture$.next(this.picture);
  }

  ngOnInit(): void {
    this.url$ = this.picture$.pipe(
      filter((picture) => !!picture),
      distinctUntilChanged(),
      switchMap((picture) => {
        return combineLatest([
          this.picturesLoaderService.getThumbnailPictureURL(picture, true),
          this.picturesLoaderService.getFullPictureURL(picture),
        ]).pipe(
          map(([thumbnail, fullPicture]) => {
            return {
              thumbnail: this.safeUrl(thumbnail),
              fullPicture: this.safeUrl(fullPicture),
            };
          })
        );
      })
    );
  }

  private safeUrl(url?: string): SafeUrl {
    if (!url) return;
    if (url.indexOf("file:///") === 0)
      url = (window as any).Ionic.WebView.convertFileSrc(url);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public backupThumbnail({ el, fullPicture }) {
    const backupUrl = fullPicture.changingThisBreaksApplicationSecurity;
    if (!backupUrl) return;
    if (!el.target || el.target.src === backupUrl) return;
    el.target.src = backupUrl;
  }
}
