import { Injectable } from "@angular/core";
// import 'rxjs/add/operator/map';
import { combineLatest, Observable, Observer } from "rxjs";
import { Asset, NotationQuestionPicture, Perimeter } from "../structs/assets";
import {
  AuditNotation,
  AuditQuestion,
  AuditQuestionItem,
  AuditQuestionSection,
  AuditSynthesis,
  makeAuditQuestionSection,
  makeNotation,
  makeNotationState,
  makeRatingReason,
  NotationState,
  RatingReason,
} from "../structs/audit";
import {
  addAuditQuestionPictureAction,
  Change,
  deleteAuditQuestionPictureAction,
  makeChange,
  setAuditExpertModeAction,
  setAuditNoteAction,
} from "../structs/synchronization";
import { AssetsService } from "./assets.service";
import { OfflineService } from "./offline.service";
import { ScopeService } from "./scope.service";
import { SynchronizationService } from "./synchronization.service";
import { Investment } from "../structs/investments";
import * as moment from "moment";
import { BackendService } from "./backend.service";
import { AuthService } from "./auth.service";
import { PicturesService } from "./pictures.service";
import { switchMap, map } from "rxjs/operators";
import { Picture } from "../structs/base";
import { of } from "rxjs/";

@Injectable()
export class AuditService {
  private technicalStateAuditQuestionItemIds: Array<number> = [];

  constructor(
    private offlineApi: OfflineService,
    private syncApi: SynchronizationService,
    private assetApi: AssetsService,
    private scopeApi: ScopeService,
    private backendApi: BackendService,
    private authService: AuthService,
    private pictureService: PicturesService
  ) {
    this.getTechnicalStateAuditQuestionItemIds().subscribe();
  }

  getTechnicalStateAuditQuestionItemIdsInstant(): Array<number> {
    return this.technicalStateAuditQuestionItemIds;
  }

  getTechnicalStateAuditQuestionItemIds(): Observable<Array<number>> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("auditQuestions").subscribe(
        (jsonData: any) => {
          let questionids: Array<number> = [];
          let data = jsonData ? jsonData : [];
          for (let i = 0; i < data.length; i++) {
            let questionData = data[i];
            if (questionData.mnemonic === "technical_state") {
              for (let j = 0; j < questionData.items.length; j++) {
                let itemData = questionData.items[j];
                questionids.push(itemData.id);
              }
            }
          }

          this.technicalStateAuditQuestionItemIds = questionids;
          observer.next(questionids);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Returns list of questions
   * @param asset : asset
   * @param investment : investment <optional>
   * @returns Observable<Array<AuditQuestion>>
   */
  getAuditQuestions(
    asset: Asset,
    investment: Investment = null
  ): Observable<Array<AuditQuestion>> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("auditQuestions").subscribe(
        (jsonData: any) => {
          let notesData = investment === null ? asset.notes : investment.notes;
          let notesCommentData = asset.notesComment;
          let notesPicturesData = asset.notesPictures;
          let assetAuditQuestions: Array<AuditQuestion> = [];
          let data = jsonData ? jsonData : [];
          for (let i = 0; i < data.length; i++) {
            let questionData = data[i];

            // filter questions : we just want the ones which applied to the current asset
            let order: number = asset.assetType.questions.indexOf(
              questionData.id
            );
            if (order >= 0) {
              let questionItems: Array<AuditQuestionItem> = [];
              for (let j = 0; j < questionData.items.length; j++) {
                // rebuild each item
                let itemData = questionData.items[j];
                questionItems.push(
                  new AuditQuestionItem(
                    itemData.id,
                    itemData.year_number,
                    itemData.id in notesData ? notesData[itemData.id] : null,
                    notesCommentData ? notesCommentData[itemData.id] : null,
                    notesPicturesData && notesPicturesData[itemData.id]
                  )
                );
              }
              // add the question to the list
              assetAuditQuestions.push(
                new AuditQuestion(
                  questionData.id,
                  questionData.text,
                  questionData.question_type,
                  questionData.help_text_subject,
                  questionData.help_text,
                  questionData.low_value_label,
                  questionData.high_value_label,
                  questionData.icon,
                  questionData.level,
                  questionData.mnemonic,
                  order,
                  questionData.mandatory,
                  questionItems
                )
              );
            }
          }

          assetAuditQuestions.sort(
            (elt1: AuditQuestion, elt2: AuditQuestion) => {
              if (elt1.order < elt2.order) {
                return -1;
              } else if (elt1.order > elt2.order) {
                return 1;
              } else {
                return 0;
              }
            }
          );

          observer.next(assetAuditQuestions);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private _doStoreAsset(
    asset: Asset,
    change: Change,
    observer: Observer<Asset>
  ) {
    this.syncApi.addChange(change).subscribe(
      () => {
        this.offlineApi.storeAsset(asset).subscribe(
          () => {
            // Push changes
            this.syncApi.signalOfflineChanges().subscribe(
              () => {},
              (err) => {},
              () => {
                observer.next(asset);
                observer.complete();
              }
            );
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
      },
      (err) => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  /**
   * create or update the note of an asset during an audit
   * @param asset
   * @param notation : list of (questionItem, note)
   * @returns Observable<Asset> : success of the operation
   */
  setAuditNote(asset: Asset, notation: AuditNotation): Observable<Asset> {
    let url = "/audit/api/notation/" + asset.id + "/";
    return new Observable((observer) => {
      // If the asset if offline (not yet on server), keep things local
      let change: Change = makeChange(
        setAuditNoteAction,
        url,
        "post",
        notation,
        asset
      );
      this._doStoreAsset(asset, change, observer);
    });
  }

  /**
   * get the note of an asset during an audit
   * @param assetId
   */
  getAuditNote(assetId: number): Observable<AuditNotation> {
    return new Observable((observer) => {
      this.backendApi.get("/audit/api/notation/" + assetId + "/").subscribe(
        (jsonData) => {
          observer.next(makeNotation(jsonData));
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getNotationStates(): Observable<NotationState[]> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("notationState").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let states: NotationState[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            states.push(makeNotationState(data[i]));
          }
          observer.next(states);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getRatingReasons(): Observable<RatingReason[]> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("ratingReason").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let reasons: RatingReason[] = [];
          for (let i = 0, l = data.length; i < l; i++) {
            reasons.push(makeRatingReason(data[i]));
          }
          observer.next(reasons);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  patchAsset(asset: Asset, data: any): Observable<Asset> {
    return new Observable((observer) => {
      this.assetApi._doPatchAsset(asset, data).subscribe(
        (asset: Asset) => {
          this.offlineApi.storeAsset(asset).subscribe(
            () => {
              // Push changes
              this.syncApi.signalOfflineChanges().subscribe(
                () => {},
                (err) => {},
                () => {
                  observer.next(asset);
                  observer.complete();
                }
              );
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getAssets(): Observable<Asset[]> {
    return new Observable((observer) => {
      let year: number = moment().year();
      this.scopeApi.getCurrentMultiPerimeter().subscribe(
        (perimeter: Perimeter) => {
          this.offlineApi.getAuditSynthesis(perimeter, year).subscribe(
            (auditSynthesis: AuditSynthesis) => {
              observer.next(auditSynthesis.assets);
              observer.complete();
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getTechnicalStateKpi(
    asset: Asset,
    investment: Investment = null
  ): Observable<Array<AuditQuestion>> {
    return new Observable((observer) => {
      this.getAuditQuestions(asset, investment).subscribe(
        (auditQuestions) => {
          observer.next(
            auditQuestions.filter(
              (question) => question.mnemonic === "technical_state"
            )
          );
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private getExpertKpiSections(): Observable<AuditQuestionSection[]> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("auditQuestionSections").subscribe(
        (jsonData: any) => {
          let sections = [];
          if (jsonData) {
            sections = jsonData.map((elt: any) =>
              makeAuditQuestionSection(elt)
            );
          }
          observer.next(sections);
          observer.complete();
        },
        (err) => {
          console.error("missing auditQuestionSections");
          observer.next([]);
          observer.complete();
        }
      );
    });
  }

  public getAssetExpertKpiSections(
    asset: Asset
  ): Observable<AuditQuestionSection[]> {
    return new Observable((observer) => {
      combineLatest(
        this.authService.getCurrentUserGroups(),
        this.getExpertKpiSections(),
        this.getAuditQuestions(asset)
      ).subscribe(
        ([membersGroups, auditQuestionSections, auditQuestions]) => {
          const sections = [];
          const kpis = auditQuestions.filter(
            (question) => question.mnemonic !== "technical_state"
          );
          for (const section of auditQuestionSections) {
            for (const kpi of kpis) {
              if (section.doesContain(kpi)) {
                for (let group of membersGroups) {
                  if (section.isAllowed(group.id)) {
                    section.addKpi(kpi);
                    break;
                  }
                }
              }
            }
            if (section.isActive()) {
              sections.push(section);
            }
          }
          observer.next(sections);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public getOtherAssetKpis(asset: Asset): Observable<AuditQuestion[]> {
    return new Observable((observer) => {
      combineLatest(
        this.getExpertKpiSections(),
        this.getAuditQuestions(asset)
      ).subscribe(
        ([auditQuestionSections, auditQuestions]) => {
          const otherKpis = [];
          const kpis = auditQuestions.filter(
            (question) => question.mnemonic !== "technical_state"
          );
          for (const kpi of kpis) {
            let isExpert = false;
            for (const section of auditQuestionSections) {
              if (section.doesContain(kpi)) {
                isExpert = true;
                break;
              }
            }
            if (!isExpert) {
              otherKpis.push(kpi);
            }
          }
          observer.next(otherKpis);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  setAuditExpertMode(asset: Asset, isOn: boolean): Observable<Asset> {
    let url = "/audit-expert/api/activate-expert-mode/";
    return new Observable((observer) => {
      // If the asset if offline (not yet on server), keep things local
      let change: Change = makeChange(
        setAuditExpertModeAction,
        url,
        "post",
        { asset: asset.id, is_on: isOn },
        asset
      );
      this._doStoreAsset(asset, change, observer);
    });
  }

  /**
   * Ask synchronization service to upload notationQuestionPicture
   * @param notationQuestionPicture
   */
  addAuditQuestionPicture(
    notationQuestionPicture: NotationQuestionPicture
  ): Observable<Change | null> {
    if (notationQuestionPicture.browserFile) {
      this.pictureService.setBrowserFile(
        notationQuestionPicture.browserFile,
        notationQuestionPicture.localId
      );
    }
    const assetId = notationQuestionPicture.assetId;
    if (!assetId) {
      // @Notes: We only add the notation picture to the asset
      // which has been created and having online id
      return of<null>(null);
    }
    return this.insertAuditQuestionPictureChange(notationQuestionPicture);
  }

  insertAuditQuestionPictureChange(
    notationQuestionPicture: NotationQuestionPicture,
    asset: Asset = null
  ): Observable<Change> {
    const kpiId = notationQuestionPicture.questionItemId;
    const assetId = notationQuestionPicture.assetId || 0;
    const change = makeChange(
      addAuditQuestionPictureAction,
      `/audit/api/asset/${assetId}/question-item/${kpiId}/pictures/`,
      "POST",
      notationQuestionPicture,
      asset
    );
    return this.syncApi.addChange(change);
  }

  /**
   * Delete the auditQuestionPicture
   *  - If the auditQuestion is already synced with the backend
   * @param notationQuestionPicture | Picture
   */
  deleteAuditQuestionPicture(
    notationQuestionPicture: NotationQuestionPicture | Picture
  ): Observable<Change[]> {
    return this.syncApi.getChanges(true).pipe(
      switchMap((changes: Change[]) => {
        const change = makeChange(
          deleteAuditQuestionPictureAction,
          `/audit/api/asset-notation-picture/localId/${notationQuestionPicture.localId}`,
          "delete",
          {}
        );
        return this.syncApi.addChange(change);
      })
    );
  }
}
