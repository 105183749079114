<ion-item-sliding #item>
  <ion-item
    [lines]="lines"
    [attr.detail]="showArrow ? true : null"
    [ngClass]="{ disabled: disabled }"
    color="{{ color }}"
  >
    <div
      [ngClass]="{
        'chevron-button-container': showChevronButtons
      }"
    >
      <ion-buttons *ngIf="hasChildren">
        <ion-button
          class="chevron-button"
          (click)="toggleMultiPerimeterChildren($event)"
        >
          <i
            class="fal fa-chevron-right"
            *ngIf="hasChildren && isCollapsed"
          ></i>
          <i
            class="fal fa-chevron-down"
            *ngIf="hasChildren && !isCollapsed"
          ></i>
        </ion-button>
      </ion-buttons>
    </div>
    <!-- Thumbnail picture -->
    <div class="picture" *ngIf="!!picture" (click)="pictureClicked.emit()">
      <app-picture [picture]="picture"></app-picture>
    </div>
    <ion-label
      class="{{ padding ? 'ion-padding-' + padding : null }}"
      (click)="onItemClicked()"
    >
      <div
        class="{{ legendClass }} text-overflow-ellipsis {{
          disabledLegend ? 'disabled-legend' : null
        }}"
      >
        {{ legend }}
        <ng-content select="[role=after-legend]"></ng-content>
      </div>
      <div
        class="content text-style1"
        [ngClass]="{ 'color-unknown': readOnly }"
      >
        <ng-content></ng-content>
      </div>
    </ion-label>
    <div item-end><ng-content select="[role=end]"></ng-content></div>
  </ion-item>
  <ion-item-options side="end" class="slide-buttons" *ngIf="itemOptions">
    <ion-item-option
      *ngIf="$any(itemOptions).addButton"
      color="{{ $any(itemOptions).addButton.color }}"
      (click)="itemAdd(item)"
    >
      <i class="fal fa-plus"></i>
    </ion-item-option>
    <ion-item-option color="mono-perimeter" (click)="itemEdit(item)">
      <i class="fas fa-pencil-alt"></i>
    </ion-item-option>
    <ion-item-option color="mono-perimeter" (click)="itemDelete(item)">
      <i class="fal fa-trash-alt"></i>
    </ion-item-option>
    <ion-item-option
      *ngIf="$any(itemOptions).unlink"
      color="{{ $any(itemOptions).unlink.color }}"
      (click)="unlink(item)"
    >
      <i class="fal fa-unlink"></i>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
