import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import { Observable, of } from "rxjs";

import {
  getLocalStorageNumValue,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../structs/utils";

import { Environment } from "../app.environment";
import { AuthService } from "./auth.service";

const HTTP_DEFAULT_TIMEOUT: number = 3 * 60 * 1000; // 3 minutes
const HELLO_DEFAULT_TIMEOUT: number = 500; // 500 milli-seconds

@Injectable()
export class BackendService {
  private backendHost: string = "";
  private isConnected: boolean = false;
  private networkStatus: string = "unknown";
  private helloTimeout: number = HELLO_DEFAULT_TIMEOUT;
  private httpTimeout: number = HTTP_DEFAULT_TIMEOUT;
  private lastHelloDelay: number = 0;

  constructor(
    private http: HttpClient,
    private authApi: AuthService,
    // private i18n: I18nService,
    private platform: Platform // private translate: TranslateService
  ) {
    this.backendHost = Environment.getBackendHost();
    this.loadTimeout();
  }

  /**
   * get HTTP header with Token for API auth
   */
  getHeaders(): Promise<HttpHeaders> {
    return new Promise<HttpHeaders>((resolve, rejected) => {
      this.authApi.getAuthorizationString().then(
        (authToken: string) => {
          const headers = new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("Accept", "application/json")
            .set("Authorization", authToken);
          // .set('Accept-Language', this.i18n.getLanguage());
          resolve(headers);
        },
        (err) => {
          rejected(err);
        }
      );
    });
  }

  setHelloTimeout(helloTimeout: number) {
    if (helloTimeout >= 0) {
      this.helloTimeout = helloTimeout;
    } else {
      this.helloTimeout = HELLO_DEFAULT_TIMEOUT;
    }
    console.info("ping.timeout_threshold", this.helloTimeout);
    localStorage.setItem("CAPEX_HELLO_TIMEOUT", "" + this.helloTimeout);
  }

  getHelloTimeout(): number {
    return this.helloTimeout;
  }

  getLastHelloDelay(): number {
    return this.lastHelloDelay;
  }

  setLastHelloDelay(delay: number) {
    this.lastHelloDelay = delay;
    localStorage.setItem("CAPEX_HELLO_DELAY", "" + delay);
  }

  setHttpTimeout(httpTimeout: number) {
    if (httpTimeout) {
      this.httpTimeout = httpTimeout;
    } else {
      this.httpTimeout = HTTP_DEFAULT_TIMEOUT;
    }
    localStorage.setItem("CAPEX_HTTP_TIMEOUT", "" + this.httpTimeout);
  }

  getHttpTimeout(): number {
    return this.httpTimeout;
  }

  loadTimeout() {
    this.setHelloTimeout(
      getLocalStorageNumValue("CAPEX_HELLO_TIMEOUT", HELLO_DEFAULT_TIMEOUT)
    );
    this.setHttpTimeout(
      getLocalStorageNumValue("CAPEX_HTTP_TIMEOUT", HTTP_DEFAULT_TIMEOUT)
    );
    this.setLastHelloDelay(getLocalStorageNumValue("CAPEX_HELLO_DELAY", 0));
  }

  /**
   * returns Url with query args
   * Add a ts arg for preventing browser cache
   */
  buildUrl(url: string, params?: any): string {
    let now = new Date();
    let paramsString = "?ts=" + now.getTime();
    if (params) {
      for (let key in params) {
        paramsString += "&" + key + "=" + params[key];
      }
    }
    return this.backendHost + url + paramsString;
  }

  /**
   * get API
   */
  get(url: string, params?: any): Observable<any> {
    return this.callApi("get", url, null, params);
  }

  /**
   * patch API
   */
  patch(url: string, data: any, params?: any): Observable<any> {
    return this.callApi("patch", url, data, params);
  }

  /**
   * patch API
   */
  postDelete(url: string, params?: any): Observable<any> {
    return this.callApi("delete", url, null, params);
  }

  /**
   * patch API
   */
  post(url: string, data: any, params?: any): any {
    return this.callApi("post", url, data, params);
  }

  setConnected(isConnected: boolean): void {
    this.isConnected = isConnected;
  }

  getConnected(): boolean {
    if (this._isBrowser()) {
      return true;
    } else {
      return this.isConnected;
    }
  }

  setNetworkStatus(status: string): void {
    this.networkStatus = status;
  }

  getNetworkStatus(): string {
    if (this._isBrowser()) {
      return "-";
    } else {
      return this.networkStatus;
    }
  }

  _isBrowser() {
    // return (this.platform.is('core') || this.platform.is('cordova'));
    return (
      this.platform.is("desktop") ||
      this.platform.is("cordova") ||
      this.platform.is("mobileweb")
    );
  }

  getBackendHost(): string {
    return this.backendHost;
  }

  _doCallApi(
    methodName: string,
    url: string,
    data: any,
    headers: any,
    params: any
  ): Observable<Object> {
    let method: Observable<Object> = null;
    let fullUrl: string = this.buildUrl(url, params);
    let options = { headers };
    if (methodName === "patch") {
      method = this.http.patch(fullUrl, data, options);
    } else if (methodName === "post") {
      method = this.http.post(fullUrl, data, options);
    } else if (methodName === "delete") {
      method = this.http.delete(fullUrl, options);
    } else if (methodName === "get") {
      method = this.http.get(fullUrl, options);
    }
    if (method === null) {
      // return Observable.throw(new Error(methodName + ' is not supported'));
      return of(new Error(methodName + " is not supported"));
    } else {
      // return method.timeout(this.getHttpTimeout());
      // observer.next(this.getHttpTimeout());
      return method;
    }
  }

  callApi(
    methodName: string,
    url: string,
    data: any,
    params?: any
  ): Observable<any> {
    let helloTimeout = this.getHelloTimeout();
    let start: Date = new Date();
    return new Observable((observer) => {
      this.getHeaders().then(
        (headers: HttpHeaders) => {
          if (helloTimeout === 0) {
            this.setLastHelloDelay(0);
            console.log("> Synchro is disabled");
            observer.error("Offline");
            observer.complete();
          } else {
            this._doCallApi(methodName, url, data, headers, params).subscribe(
              (respData) => {
                let end: Date = new Date();
                let delay: number = end.getTime() - start.getTime();
                this.setLastHelloDelay(delay);
                if (methodName === "delete" && !respData) {
                  respData = { deleted: true };
                }
                observer.next(respData);
                observer.complete();
              },
              (err) => {
                let end: Date = new Date();
                let delay: number = end.getTime() - start.getTime();
                this.setLastHelloDelay(delay);
                console.error(err);
                observer.error(err);
                observer.complete();
              }
            );
          }
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}
