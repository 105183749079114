import {
  AfterContentInit,
  Component,
  ContentChild,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IonItem, IonSelect, IonText } from "@ionic/angular";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "input-item",
  templateUrl: "./input-item.component.html",
  styleUrls: ["./input-item.component.scss"],
})
export class InputItemComponent implements AfterContentInit {
  @Input() public legend: string;
  @Input() public lines: string = "";
  @ViewChild("item", { read: IonItem }) public item: IonItem;
  @ContentChild(IonSelect) public select: IonSelect;
  @ContentChild(IonText) public textInput: IonText;
  @Input() public color: "grey-background" | "unknown";

  constructor() {}

  public ngAfterContentInit(): void {
    // Force the setting of the Item on the Select
    // so that it can generate an alert with the label as title
    if (this.select) {
      // Not working with ionic 6
      // this.select._item = this.item;
      this.select.interfaceOptions = this.item;
    }

    // Fix #1191: force this parameter to be false, otherwise
    // it generates a div.input-cover that prevents text curso
    // to work on mobile devices.
    // https://github.com/ionic-team/ionic-v3/blob/master/src/components/input/input.ts#L134-L138
    if (this.textInput) {
      // Not working with ionic 6
      // this.textInput._useAssist = false;
    }
  }
}
