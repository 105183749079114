import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { AssetPicturesService } from "src/app/services/asset-pictures.service";
import { AuditService } from "src/app/services/audit.service";
import { ImportStatusService } from "src/app/services/import-status.service";
import { SearchService } from "src/app/services/search.service";
import {
  Asset,
  AssetPicture,
  AssetTypeLevel,
  ItemOptions,
  Perimeter,
  setAsset,
} from "src/app/structs/assets";
import { User, Investment } from "@structs";
import { firstLetterUpperCase } from "src/app/structs/utils";
import { IonItemSliding } from "@ionic/angular";
import { LEVELS_MAP, STATE_LABELS, STATE_LIMITS } from "@structs";

@Component({
  selector: "asset-item", // eslint-disable-line @angular-eslint/component-selector
  templateUrl: "./asset-item.component.html",
  styleUrls: ["./asset-item.component.scss"],
})
export class AssetItemComponent implements OnChanges {
  @Input() public asset: Asset;
  @Input() public parent: Asset | null = null;
  /** Enables the ability to list the investments directly below the item. */
  @Input() public investmentsToggle: boolean = false;
  /** If true, display 'no investment' in red danger */
  @Input() public isNoInvestmentCritical: boolean = false;
  @Input() public childrenMode: boolean = true;
  @Input() public highlighted: boolean = false;
  @Input() public multiPerimeter: Perimeter = null;
  @Input() public forceHideChildren: boolean = false;
  @Input() public itemOptions: ItemOptions = null;
  @Input() public user: User = null;
  @Input() public picturesOn: boolean = true;
  /** Emits when the asset item is clicked. */
  @Output() public assetClick = new EventEmitter<Asset>();
  @Output() public childrenClicked = new EventEmitter<Asset>();
  /** Emits when an investment is clicked. */
  @Output() public investmentClick = new EventEmitter<{
    asset: Asset;
    investment: Investment;
  }>();
  @Output() childrenIsToggled = new EventEmitter();
  @Output() cloneAssetClicked = new EventEmitter<Asset>();
  @Input() showChildren: boolean = true;
  @Output() pictureClick = new EventEmitter<any>();
  @Output() deleteAssetClicked = new EventEmitter<Asset>();

  public picture: AssetPicture;
  public investmentsSum: number = 0;
  public childInvestmentTotal: number = 0;
  public numberChildInvestments: number = 0;
  public defectsCount: number = 0;
  public disableImportIcon: boolean = false;
  public cmmsSync: boolean = false; // CMMS Synchronization : Allow the user to swipe to solve conflicts

  /* Build a string which is the complete unique set of years for the slices of the investments */
  /* If I have 2 investments, one in 2022, and another with 2 slices in 2022 & 2023, create "2022, 2023" */
  public investmentSliceYears: string;

  /* Get the level for the asset type (1, 2, 3, 4 where 3 & 4 are components) */
  /* we will use this in the asset item to decide whether the "No Technical Evaluation" should be in RED or not */
  public isComponentLevel: boolean = false;

  public isCritical: boolean = false;

  private pictureReloaded: boolean = false;
  public lastAuditInformation: string;
  public loading: boolean = true;
  public assetStyle: SafeStyle = "";

  constructor(
    private assetPicturesService: AssetPicturesService,
    private auditApi: AuditService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private searchService: SearchService,
    private sanitizer: DomSanitizer,
    private importStatusService: ImportStatusService,
    private router: Router
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.searchService.assetsChildrenToggled$
      .pipe(first())
      .subscribe((assetsToggled) => {
        const isAssetManuallyToggled = assetsToggled.find(
          (a) =>
            a &&
            this.asset &&
            (a.id === this.asset.id || a.id === this.asset.id)
        );
        this.showChildren = !!isAssetManuallyToggled;
      });
    if (this.asset.pictures.length > 0) {
      this.picture = this.asset.pictures[0];
    } else {
      this.picture = null;
    }
    if (this.forceHideChildren) {
      this.showChildren = false;
    }
    const asset = setAsset(this.asset); // make sure the investments are Investment objects
    this.investmentsSum = asset.investments.reduce((sum, investment) => {
      sum += investment.getPrice();
      return sum;
    }, 0);

    // Get an inclusive list of years when at least 1 investment slice is planned
    let slicerange: number[] = [];

    for (let i = 0; i < this.asset.investments.length; i++) {
      slicerange = slicerange.concat(this.asset.investments[i].getSliceYears());
    }

    // Sort slices by year
    slicerange.sort(function (a, b) {
      return a - b;
    });
    // remove any duplicates & create a single string
    slicerange = slicerange.filter((n, i) => slicerange.indexOf(n) === i);
    this.investmentSliceYears = slicerange.join(", ");

    // Is this asset a component or an "ensemble" or a "unit"?
    // we will use this info to decide how to display the fact that there has been no audit done
    this.isComponentLevel =
      this.asset.assetType &&
      (this.asset.assetType.level === AssetTypeLevel.LEVEL_COMPONENT ||
        this.asset.assetType.level === AssetTypeLevel.LEVEL_COLLECTION_ITEM);

    let assetStyle = "";
    if (this.childrenMode && asset.parent) {
      assetStyle = "border-right: 5px solid #548235;background-color: #f9f9fb";
    }
    this.assetStyle = this.sanitizer.bypassSecurityTrustStyle(assetStyle);

    if (this.multiPerimeter && this.asset.building) {
      // The building name might have been changed (during the roadmap, for example)
      // so we get it dynamically
      let buildingName = this.getBuildingName(
        this.asset.building.monosite_perimeter.id
      );
      if (buildingName) {
        this.asset.building.name = buildingName;
      }
    } else if (
      this.asset.building &&
      this.asset.building.name === "" &&
      this.asset.building.monosite_perimeter
    ) {
      this.asset.building.name = this.asset.building.monosite_perimeter.name;
    }
    this.getLastAuditInformation();
    this.countDefectsForAssetAndChildren(this.asset);
    this.totalInvestmentChildren(this.asset);

    /** Import icon and CMMS sync are removed from migration (for now) */

    // this.offlineService
    //   .getConfig("disableImportIcon")
    //   .subscribe((disableImportIcon) => {
    //     this.disableImportIcon = disableImportIcon;
    //   });
    // this.cmmsSync =
    //   this.itemOptions &&
    //   this.itemOptions.synchronize &&
    //   this.asset.importStatuses &&
    //   this.asset.importStatuses.length > 0 &&
    //   this.asset.level &&
    //   this.asset.level !== this.asset.levelFromExternalBase;
  }

  /**
   * Triggered when the whole item is clicked.
   * Sends an `assetClick` event only when investments toggling is disabled
   * so that it allows the user to click anywhere while not disturbing the investments feature when active.
   */
  public onItemClick(event: Event): void {
    if (!this.investmentsToggle) {
      this.onAssetClick(event);
    }
  }

  /**
   * Triggered when a zone concerning the asset is clicked.
   */
  public onAssetClick(event: Event): void {
    event.stopPropagation();
    this.assetClick.emit(this.asset);
  }

  public onChildClick(event) {
    this.childrenClicked.emit(event);
  }

  /**
   * Refresh the asset picture url (30min token)
   */
  public reloadPicture(): void {
    if (this.picturesOn) {
      // We only try to reloaded the picture a first time so we don't start an infinite loop
      if (!this.pictureReloaded) {
        this.assetPicturesService
          .refreshAssetPictures([this.asset.id])
          .subscribe((updatedAssets) => {
            this.asset = updatedAssets[0];
            this.picture =
              this.asset.pictures.length > 0 ? this.asset.pictures[0] : null;
            this.pictureReloaded = true;
          });
      }
    }
  }

  private getLastAuditInformation() {
    let level = this.asset.level;
    if (level) {
      this.makeLastAuditSentence(level);
    } else {
      // When we've just created an asset, its level might not be
      // loaded. So we get the level from the asset's note.
      const technicalStateQuestionId =
        this.auditApi.getTechnicalStateAuditQuestionItemIdsInstant()[0];
      const note = this.asset.notes[technicalStateQuestionId];
      if (note) {
        level = this.getAssetLevelFromNote(note);
        this.makeLastAuditSentence(level);
      } else {
        // Asset has no technical state
        this.lastAuditInformation = null;
      }
    }
    this.loading = false;
  }

  private makeLastAuditSentence(level: string) {
    // If we don't have a date for the last audit, we take the import date.
    let lastAuditDate = this.asset.technical_state_changed_on
      ? this.asset.technical_state_changed_on
      : new Date(); // TODO this.asset.importedAt;
    let formattedDate;
    if (lastAuditDate === null) {
      formattedDate = "(" + this.translate.instant("Unknown") + ")";
    } else {
      formattedDate = this.datePipe.transform(lastAuditDate);
    }
    const translationParams = {
      date: formattedDate,
    };
    this.lastAuditInformation =
      this.translate.instant(firstLetterUpperCase(level)) +
      this.translate.instant(" - Last rated on {{date}}", translationParams);
    if (level === "dead" || level === "critical") {
      this.isCritical = true;
    } else {
      this.isCritical = false;
    }
  }

  public openInvestment(event): void {
    const investment: Investment = event.investment;
    this.router.navigate([
      "perimeters",
      this.multiPerimeter.id,
      "investment-detail",
      investment.id || investment.localId,
    ]);
  }

  public toggleChildren(event?): void {
    if (event) {
      event.stopPropagation();
    }
    this.showChildren = !this.showChildren;
    this.searchService.setAssetsChildrenToggled(this.asset, this.showChildren);
    this.childrenIsToggled.emit(true);
  }

  private getBuildingName(buildingId: number) {
    let building = this.multiPerimeter.sub_perimeters.find(
      (subPerimeter) => subPerimeter.id === buildingId
    );
    if (building) {
      return building.name;
    } else {
      return "";
    }
  }

  public cloneAsset(item, asset: Asset) {
    item.close();
    this.cloneAssetClicked.emit(asset);
  }

  public cloneChild(item, asset: Asset) {
    item.close();
    this.cloneAssetClicked.emit(asset);
  }

  private getDefectsCount(asset: Asset) {
    if (
      // if expertMode is on for this user and this asset
      this.user &&
      asset.expertMode &&
      asset.expertMode[this.user.get_user_id] &&
      // if notes !== {}
      asset.notes &&
      Object.keys(asset.notes).length > 0
    ) {
      this.auditApi
        .getAssetExpertKpiSections(asset)
        .subscribe((questionsSections) => {
          questionsSections.forEach((questionSection) => {
            questionSection.expertKpis.forEach((expertKpiQuestion) => {
              if (
                expertKpiQuestion.items.length &&
                asset.notes[expertKpiQuestion.items[0].id] === 1
              ) {
                this.defectsCount++;
              }
            });
          });
        });
    }
  }

  private countDefectsForAssetAndChildren(asset: Asset) {
    this.defectsCount = 0;
    if (!asset.parent) {
      this.getDefectsCount(asset);
    }
    if (asset.children.length > 0) {
      asset.children.forEach((child) => {
        this.getDefectsCount(child);
      });
    }
  }

  private totalInvestmentChildren(asset: Asset) {
    this.childInvestmentTotal = 0;
    this.numberChildInvestments = 0;

    asset.children.forEach((child) => {
      child.investments.forEach((investment) => {
        const atLeastOnePlanned = investment.slices.some(
          (s) => !investment.status.hypothesis && s.price > 0
        );
        if (atLeastOnePlanned) {
          this.numberChildInvestments += 1;
        }
        investment.slices
          .filter(
            (slice) =>
              !investment.status.hypothesis &&
              slice.price > 0 &&
              investment.status.id === slice.status.id
          )
          .forEach((slice) => {
            this.childInvestmentTotal += slice.price;
          });
      });
    });
  }

  public labelClass(): string {
    if (this.defectsCount > 0) {
      return "cropped-label";
    } else {
      return "";
    }
  }

  public openSynchronization(item: IonItemSliding, asset: Asset) {
    console.log("TODO: openSync()");
    // this.navCtrl.push(CmmsConflictPage, { asset: this.asset });
  }

  public pictureClicked(event, asset = this.asset) {
    this.pictureClick.emit({ event, asset });
  }

  // Get the lowest level perimeter name (level 2 strings can be separated as "level1 > level2")
  public getPerimeterName(asset: Asset): string {
    const name = asset.building.monosite_perimeter.name;
    const levels = name.split(">");
    if (levels.length > 0) {
      return levels[levels.length - 1];
    }
    return name;
  }

  public getAssetLevelFromNote(note: number) {
    const noteOutOf100 = LEVELS_MAP[note];
    const currentLevel = this.getStateLevelForNote(noteOutOf100);
    const stateLevelLabel = STATE_LABELS[currentLevel];
    return stateLevelLabel;
  }

  private getStateLevelForNote(note: number): number {
    const stateLevel = STATE_LIMITS.findIndex((n) => n >= note);
    return stateLevel;
  }

  public deleteAsset(item, asset: Asset) {
    item.close();
    this.deleteAssetClicked.emit(asset);
  }
}
