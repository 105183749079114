import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Observer, ReplaySubject } from "rxjs";

import CryptoJS from "crypto-js";

import { Environment } from "../app.environment";
// import { MembersGroup, makeMembersGroup } from '../structs/base';
import { User } from "../structs/auth";
import { makeMembersGroup, MembersGroup } from "../structs/base";
import { OfflineService } from "./offline.service";
import { map } from "rxjs/operators";

@Injectable()
export class AuthService {
  private secret: string = "auth$";
  private backendHost: string = "";
  private loginErrorObservable: Observable<any> = null;
  private loginErrorObserver: Observer<any> = null;

  private currentUserObserver$ = new ReplaySubject<User>(1);

  constructor(
    private http: HttpClient,
    private offlineService: OfflineService
  ) {
    this.backendHost = Environment.getBackendHost();
    this.loginErrorObservable = new Observable((observer) => {
      this.loginErrorObserver = observer;
    });
  }

  /**
   * Initialize current user value
   */
  public initCurrentUser() {
    this.getCurrentUser().then(
      (user: User) => {
        // console.log('>>> USER', user);
        if (!this.currentUserObserver$) {
          this.currentUserObserver$ = new ReplaySubject(1);
        }
        this.currentUserObserver$.next(user);
      },
      (err) => {
        this.loginErrorObserver.next(err);
        if (!this.currentUserObserver$) {
          this.currentUserObserver$ = new ReplaySubject(1);
        }
        this.currentUserObserver$.next(null);
      }
    );
  }

  /**
   * store the logged-in user in localStorage
   */
  public setCurrentUser(user: User): Promise<User> {
    return new Promise<User>((resolve) => {
      let userAsString: string = JSON.stringify(user);
      localStorage.setItem("user", this._encrypt(userAsString));
      resolve(user);
    });
  }

  /**
   * remove the logged-in user in localStorage
   */
  public removeCurrentUser() {
    localStorage.removeItem("user");
  }

  /**
   * get logged-in user from localStorage
   */
  public getCurrentUser(): Promise<User> {
    return new Promise<User>((resolve) => {
      let encrypedValue = localStorage.getItem("user");
      if (encrypedValue) {
        let value: string = "";
        try {
          value = this._decrypt(encrypedValue);
        } catch (exc1) {
          value = "";
        }
        if (value) {
          try {
            const {
              id,
              get_user_id,
              email,
              apiKey,
              uuid,
              firstName,
              lastName,
            } = JSON.parse(value);
            const user = new User(
              id,
              get_user_id,
              email,
              apiKey,
              uuid,
              firstName,
              lastName
            );
            if (user.apiKey.length > 0) {
              resolve(user);
            } else {
              resolve(null);
            }
          } catch (exc2) {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  }

  public getAuthorizationString(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getCurrentUserToken().then(
        (token: string) => {
          if (token) {
            resolve("Token " + token);
          } else {
            resolve("");
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public getMemberGroups(): Observable<MembersGroup[]> {
    return this.offlineService.getConfig("membersGroup").pipe(
      map((membersGroups) => {
        return membersGroups.map((group) => makeMembersGroup(group));
      })
    );
  }

  public getCurrentUserGroups(): Promise<MembersGroup[]> {
    return new Promise<MembersGroup[]>((resolve, reject) => {
      this.getCurrentUser().then(
        (user: User) => {
          if (user) {
            this.getMemberGroups().subscribe((groups) => {
              const currentUserGroups = [];
              for (const group of groups) {
                // "user id" is in the "team member id"
                if (
                  group.members.filter((member) => member === user.id).length >
                  0
                ) {
                  currentUserGroups.push(group);
                }
              }
              resolve(currentUserGroups);
            });
          } else {
            resolve([]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * getCurrentUserToken
   */
  public getCurrentUserToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getCurrentUser().then(
        (user: User) => {
          if (user) {
            resolve(user.apiKey);
          } else {
            resolve("");
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * calls the login API with email and password
   */
  public login(email: string, password: string): any {
    let body = { email, password };
    this.loginErrorObserver.next("");
    return this.http
      .post(this.backendHost + "/users/api/login/", body)
      .subscribe(
        (jsonData) => {
          console.log("login: jsonData", jsonData);
          this._handleLogin(jsonData);
        },
        (err) => {
          this.loginErrorObserver.next(err);
        }
      );
  }

  /**
   * calls the login API with google credentials
   */
  public googleLogin(body: any): any {
    this.loginErrorObserver.next("");
    return this.http
      .post(this.backendHost + "/users/api/google-login/", body)
      .subscribe(
        (jsonData) => {
          this._handleLogin(jsonData);
        },
        (err) => {
          this.loginErrorObserver.next(err);
        }
      );
  }

  /**
   * calls the login API with email and password
   */
  public logout(): void {
    this.removeCurrentUser();
    this.currentUserObserver$.next(null);
  }

  /**
   * returns Observable : can subscribe to it
   */
  public onCurrentUserChanged(): Observable<User> {
    return this.currentUserObserver$;
  }

  /**
   * returns Observable : can subscribe to it
   */
  public onLoginError(): Observable<any> {
    return this.loginErrorObservable;
  }

  private _encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secret).toString();
  }

  private _decrypt(value: string): string {
    let bytes = CryptoJS.AES.decrypt(value, this.secret);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  private _handleLogin(jsonData) {
    if (jsonData.success) {
      let user = new User(
        jsonData.user.id,
        jsonData.user.get_user_id,
        jsonData.user.email,
        jsonData.token,
        jsonData.user.uuid,
        jsonData.user.first_name,
        jsonData.user.last_name
      );
      this.setCurrentUser(user).then(
        () => {
          if (!this.currentUserObserver$) {
            this.currentUserObserver$ = new ReplaySubject<User>(1);
          }
          this.currentUserObserver$.next(user);
        },
        (err) => {
          this.loginErrorObserver.next(err);
        }
      );
    } else {
      this.loginErrorObserver.next(jsonData.message);
    }
  }
}
