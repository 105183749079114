import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as R from "ramda";
import {
  Observable,
  ReplaySubject,
  combineLatest,
  BehaviorSubject,
  of,
} from "rxjs";
import { switchMap, map, tap, first } from "rxjs/operators";

import { AssetSearch, TaskSearch, InvestmentSearch } from "../structs/search";
import { Asset, Category, Perimeter, Zone } from "../structs/assets";
import {
  Investment,
  InvestmentCategory,
  InvestmentPriority,
  InvestmentReason,
  InvestmentStatus,
} from "../structs/investments";
import { Task } from "../structs/tasks";
import { AssetsService } from "./assets.service";
import { InvestmentsService } from "./investments.service";
import { OfflineService } from "./offline.service";
import { ScopeService } from "./scope.service";
import { SynthesisService } from "./synthesis.service";
import { TasksService } from "./tasks.service";

@Injectable()
export class SearchService {
  public assetsFilters: AssetSearch.AssetSearchFilter<any>[];
  public assetsFiltersSelected = new ReplaySubject<boolean>();
  public investmentsFilters: InvestmentSearch.InvestmentSearchFilter<any>[] =
    [];
  public investmentsFiltersSelected = new ReplaySubject<boolean>();
  public tasksFilters: TaskSearch.TaskSearchFilter<any>[];
  public tasksFiltersSelected = new ReplaySubject<boolean>();

  constructor(
    private assetsApi: AssetsService,
    private investmentsApi: InvestmentsService,
    private offlineApi: OfflineService,
    private scope: ScopeService,
    protected synthesisService: SynthesisService,
    private tasksService: TasksService,
    private translate: TranslateService
  ) {}

  /**
   * Generate all the available assets filters.
   */
  public generateAssetsFilters(
    initialize?: boolean
  ): Observable<AssetSearch.AssetSearchFilter<any>[]> {
    return new Observable((observer) => {
      if (!this.assetsFilters || initialize) {
        combineLatest(
          this.scope.getCurrentMultiPerimeter(),
          this.assetsApi.getCategories(),
          this.assetsApi.getZones(),
          this.synthesisService.getAuditStates()
        ).subscribe(([perimeter, categories, zones, auditStates]) => {
          const currentYear = new Date().getFullYear();

          this.assetsFilters = [
            new AssetSearch.PerimeterSearchFilter(
              this.translate.instant("Perimeter"),
              perimeter.sub_perimeters
            ),
            new AssetSearch.CategorySearchFilter(
              this.translate.instant("Category"),
              categories
            ),
            new AssetSearch.SubCategorySearchFilter(
              this.translate.instant("Sub-category"),
              [] // Available values will be filled dynamically given category
            ),
            new AssetSearch.AssetTypeSearchFilter(
              this.translate.instant("Asset type"),
              [] // Available values will be filled dynamically given sub-category
            ),
            new AssetSearch.ZoneSearchFilter(
              this.translate.instant("Zone"),
              zones
            ),
            new AssetSearch.RemainingLifetimeSearchFilter(
              this.translate.instant("Remaining lifetime"),
              R.range(1, 21).map((value) => ({
                value,
                name:
                  value +
                  " " +
                  (value > 1
                    ? this.translate.instant("years")
                    : this.translate.instant("year")),
              }))
            ),
            new AssetSearch.EndOfLifeYearSearchFilter(
              this.translate.instant("End of life year"),
              R.range(currentYear, currentYear + 21)
            ),
            new AssetSearch.TechnicalStateSearchFilter(
              this.translate.instant("Technical state"),
              auditStates
            ),
            new AssetSearch.HasInvestmentsSearchFilter(
              this.translate.instant("With or without investment"),
              [
                { name: this.translate.instant("With"), value: true },
                { name: this.translate.instant("Without"), value: false },
              ]
            ),
          ];

          observer.next(this.assetsFilters);
          observer.complete();
        });
      } else {
        observer.next(this.assetsFilters);
        observer.complete();
      }
    });
  }

  /**
   * Clear all selected values from assets filters.
   */
  public clearAssetsFilters(): void {
    this.assetsFilters?.forEach((filter) => {
      filter.clearSelected();
      this.assetFilterSelected(filter);
    });
    this.assetsFiltersSelected?.next(false);
  }

  /**
   * Apply logic given a newly selected filter.
   *
   * @param filter The filter that has been selected.
   */
  public assetFilterSelected(filter: AssetSearch.AssetSearchFilter<any>): void {
    // Get sub-categories for selected categories
    if (filter instanceof AssetSearch.CategorySearchFilter) {
      const subCategorySearchFilter = this.assetsFilters.find(
        (filter) => filter instanceof AssetSearch.SubCategorySearchFilter
      );
      if (subCategorySearchFilter) {
        const availableSubCategories = R.flatten(
          filter.selectedValues.map((category) => category.children)
        );
        subCategorySearchFilter.updateAvailableValues(availableSubCategories);
        this.assetFilterSelected(subCategorySearchFilter);
      }
      // Get asset types for selected sub-categories
    } else if (filter instanceof AssetSearch.SubCategorySearchFilter) {
      const assetTypeSearchFilter = this.assetsFilters.find(
        (filter) => filter instanceof AssetSearch.AssetTypeSearchFilter
      );
      if (assetTypeSearchFilter) {
        const availableAssetTypes = R.flatten(
          filter.selectedValues.map((subCategory) => subCategory.children)
        );
        assetTypeSearchFilter.updateAvailableValues(availableAssetTypes);
        this.assetFilterSelected(assetTypeSearchFilter);
      }
    }

    // Update the selected status
    this.assetsFiltersSelected.next(
      this.assetsFilters.some((filter) => filter.hasSelected())
    );
  }

  /**
   * Search assets given the current enabled filters.
   * @param sortBy: "id" | "label" | "technical_state_changed_on"
   * default sort is desc
   */
  public searchAssets(
    sortBy:
      | "id"
      | "label"
      | "technical_state_changed_on"
      | "offlineId" = "offlineId"
  ): Observable<Asset[]> {
    if (!this.assetsFilters) {
      // fix: for reloading the asset tab
      return of(null);
    }
    const predicate = R.allPass(
      this.assetsFilters
        .filter((filter) => filter.hasSelected())
        .map((filter) => filter.getPredicate())
    );
    return combineLatest([
      this.scope.getCurrentMultiPerimeter(),
      this.scope.getSynthesisYear(),
    ])
      .pipe(
        switchMap(([mainPerimeter, year]) =>
          this.offlineApi.getAuditSynthesis(mainPerimeter, year)
        )
      )
      .pipe(
        map((synthesis) => {
          return synthesis.assets.filter(predicate);
        })
      )
      .pipe(
        tap((elements) =>
          elements.forEach((element) => {
            element.lastAccess = new Date(element.lastAccess);
          })
        )
      )
      .pipe(
        map((elements) =>
          R.sortWith(
            [R.descend(R.prop(sortBy)), R.descend(R.prop("id"))],
            elements
          )
        )
      )
      .pipe(
        map((assets) => {
          return assets.filter((asset) => asset.parent === null);
        })
      );
  }

  /**
   * Generate all the available investments filters.
   */
  public generateInvestmentsFilters(
    initialize?: boolean,
    defaultFilters?: any[]
  ): Observable<InvestmentSearch.InvestmentSearchFilter<any>[]> {
    const investmentFilters$ = combineLatest([
      this.investmentsApi.getInvestmentCategories(),
      this.investmentsApi.getInvestmentPriorities(),
      this.investmentsApi.getInvestmentStatus(),
      this.investmentsApi.getInvestmentReasons(),
    ]);
    const assetFilters$ = combineLatest([
      this.assetsApi.getCategories(),
      this.assetsApi.getZones(),
    ]);
    return new Observable((observer) => {
      if (!this.investmentsFilters || initialize) {
        combineLatest([
          this.scope.getCurrentMultiPerimeter(),
          assetFilters$,
          investmentFilters$,
        ]).subscribe(([perimeter, assetFilters, investmentFilters]) => {
          const currentYear = new Date().getFullYear();
          const [categories, zones] = assetFilters;
          const [
            investmentCategories,
            investmentPriorities,
            investmentStatuses,
            investmentReasons,
          ] = investmentFilters;

          this.investmentsFilters = [
            new InvestmentSearch.PerimeterSearchFilter(
              this.translate.instant("Perimeter"),
              perimeter.sub_perimeters
            ),
            new InvestmentSearch.CategorySearchFilter(
              this.translate.instant("Category"),
              categories
            ),
            new InvestmentSearch.SubCategorySearchFilter(
              this.translate.instant("Sub-category"),
              [] // Available values will be filled dynamically given category
            ),
            new InvestmentSearch.InvestmentCategorySearchFilter(
              this.translate.instant("Investment category"),
              investmentCategories
            ),
            new InvestmentSearch.YearSearchFilter(
              this.translate.instant("Budget year"),
              R.range(currentYear - 10, currentYear + 21)
            ),
            new InvestmentSearch.PrioritySearchFilter(
              this.translate.instant("Priority"),
              investmentPriorities
            ),
            new InvestmentSearch.StatusSearchFilter(
              this.translate.instant("Status"),
              investmentStatuses
            ),
            new InvestmentSearch.ZoneSearchFilter(
              this.translate.instant("Linked element's zone"),
              zones
            ),
            new InvestmentSearch.IsGlobalSearchFilter(
              this.translate.instant("Is global"),
              [
                { name: this.translate.instant("Yes"), value: true },
                { name: this.translate.instant("No"), value: false },
              ]
            ),
            new InvestmentSearch.InvestmentReasonSearchFilter(
              this.translate.instant("Tag"),
              investmentReasons
            ),
          ];

          if (defaultFilters) {
            let statusFilterIndex = this.investmentsFilters.findIndex((iF) => {
              return iF instanceof InvestmentSearch.StatusSearchFilter;
            });
            this.investmentsFilters[statusFilterIndex].selectedValues =
              investmentStatuses.filter((status) => {
                return defaultFilters.some((defaultF) => {
                  return Object.keys(defaultF).every((dF) => {
                    return status[dF] === defaultF[dF];
                  });
                });
              });
          }

          observer.next(this.investmentsFilters);
          observer.complete();
        });
      } else {
        observer.next(this.investmentsFilters);
        observer.complete();
      }
    });
  }

  /**
   * Clear all selected values from investments filters.
   */
  public clearInvestmentsFilters(): void {
    this.investmentsFilters?.forEach((filter) => {
      filter.clearSelected();
    });
    this.investmentsFiltersSelected?.next(false);
  }

  /**
   * Apply logic given a newly selected filter.
   *
   * @param _filter The filter that has been selected.
   */
  public investmentFilterSelected(
    _filter: InvestmentSearch.InvestmentSearchFilter<any>
  ): void {
    // Update the selected status
    this.investmentsFiltersSelected.next(
      this.investmentsFilters.some((filter) => filter.hasSelected())
    );
    // Get sub-categories for selected categories
    if (_filter instanceof InvestmentSearch.CategorySearchFilter) {
      const subCategorySearchFilter = this.investmentsFilters.find(
        (filter) => filter instanceof InvestmentSearch.SubCategorySearchFilter
      );
      if (subCategorySearchFilter) {
        const availableSubCategories = R.flatten(
          _filter.selectedValues.map((category) => category.children)
        );
        subCategorySearchFilter.updateAvailableValues(availableSubCategories);
        this.investmentFilterSelected(subCategorySearchFilter);
      }
      // Get asset types for selected sub-categories
    }
  }

  /**
   * Search investments given the current enabled filters.
   */
  public searchInvestments(): Observable<Investment[]> {
    const predicate = R.allPass(
      this.investmentsFilters
        .filter((filter) => filter.hasSelected())
        .map((filter) => filter.getPredicate())
    );

    return combineLatest(
      this.scope.getCurrentMultiPerimeter(),
      this.scope.getSynthesisYear()
    )
      .pipe(
        switchMap(([perimeter, year]) =>
          combineLatest([
            this.offlineApi.getAuditSynthesis(perimeter, year),
            this.investmentsApi.getPerimeterInvestments(perimeter, true),
          ])
        )
      )
      .pipe(
        map(([synthesis, investments]) => {
          return investments.map(
            (investment: InvestmentSearch.InvestmentElement) => {
              if (investment.assetId) {
                investment.element = synthesis.assets.find((assetItem) => {
                  if (investment.assetOffline) {
                    return assetItem.offlineId === investment.assetId;
                  } else {
                    return assetItem.id === investment.assetId;
                  }
                });
              }
              return investment;
            }
          );
        })
      )
      .pipe(
        map((investments) =>
          R.sortWith(
            [R.descend(R.prop("createdOn"))],
            investments.filter(predicate)
          )
        )
      );
  }

  /**
   * Generate all the available tasks filters.
   */
  public generateTasksFilters(
    initialize?: boolean
  ): Observable<TaskSearch.TaskSearchFilter<any>[]> {
    return new Observable((observer) => {
      if (!this.tasksFilters || initialize) {
        combineLatest(
          this.scope.getCurrentMultiPerimeter(),
          this.assetsApi.getCategories(),
          this.assetsApi.getZones(),
          this.offlineApi.getConfig("taskStates")
        ).subscribe(([perimeter, categories, zones, taskStates]) => {
          this.tasksFilters = [
            new TaskSearch.PerimeterSearchFilter(
              this.translate.instant("Perimeter"),
              perimeter.sub_perimeters
            ),
            new TaskSearch.CategorySearchFilter(
              this.translate.instant("Linked element's category"),
              categories
            ),
            new TaskSearch.ZoneSearchFilter(
              this.translate.instant("Linked element's zone"),
              zones
            ),
            new TaskSearch.HasInvestmentsSearchFilter(
              this.translate.instant("Investment linked"),
              [
                { name: this.translate.instant("Yes"), value: true },
                { name: this.translate.instant("No"), value: false },
              ]
            ),
            new TaskSearch.StateSearchFilter(
              this.translate.instant("Status"),
              taskStates
            ),
          ];

          observer.next(this.tasksFilters);
          observer.complete();
        });
      } else {
        observer.next(this.tasksFilters);
        observer.complete();
      }
    });
  }

  /**
   * Clear all selected values from assets filters.
   */
  public clearTasksFilters(): void {
    this.tasksFilters.forEach((filter) => {
      filter.clearSelected();
    });
    this.tasksFiltersSelected.next(false);
  }

  /**
   * Apply logic given a newly selected filter.
   *
   * @param _filter The filter that has been selected.
   */
  public taskFilterSelected(_filter: TaskSearch.TaskSearchFilter<any>): void {
    // Update the selected status
    this.tasksFiltersSelected.next(
      this.tasksFilters.some((filter) => filter.hasSelected())
    );
  }

  /**
   * Search tasks given the current enabled filters.
   */
  public searchTasks(refresh: boolean = false): Observable<Task[]> {
    const predicate = R.allPass(
      this.tasksFilters
        .filter((filter) => filter.hasSelected())
        .map((filter) => filter.getPredicate())
    );

    return new Observable((observer) => {
      this.scope.getCurrentMultiPerimeter().subscribe((perimeter) => {
        this.tasksService
          .getAssignedTasksPerimeter(perimeter, refresh)
          .subscribe((tasks) => {
            if (tasks.length > 0) {
              combineLatest(
                tasks.map((task) => this.tasksService.mapTask(task))
              ).subscribe((mappedTasks) => {
                observer.next(mappedTasks.filter(predicate));
                observer.complete();
              });
            } else {
              observer.next([]);
              observer.complete();
            }
          });
      });
    });
  }

  /**
   * List of "assets with toggled children", used to retrieve toggled asset accross the APP
   */
  private _assetsChildrenToggled$: BehaviorSubject<Asset[]> =
    new BehaviorSubject<Asset[]>([]);

  /**
   * Retrieve the list of "assets with toggled children"
   */
  public get assetsChildrenToggled$(): Observable<Asset[]> {
    return this._assetsChildrenToggled$;
  }

  /**
   * Add or remove an asset from the "assets with toggled children" list
   * @param asset: Asset
   * @param toggled: boolean
   */
  public setAssetsChildrenToggled(asset: Asset, toggled: boolean): void {
    // Get the current list of assets with toggled children before we update it
    this.assetsChildrenToggled$
      .pipe(first())
      .subscribe((assetsChildrenToggled) => {
        if (toggled) {
          const assetAlreadyInTheList: boolean = !!assetsChildrenToggled.find(
            (a) => a.id !== asset.id && a.offlineId !== asset.offlineId
          );
          if (!assetAlreadyInTheList) {
            assetsChildrenToggled.push(asset); // Add asset to list
          }
        } else {
          const assetIndex: number = assetsChildrenToggled.findIndex(
            (a) => a.id === asset.id || a.offlineId === asset.offlineId
          );
          if (assetIndex > -1) {
            assetsChildrenToggled.splice(assetIndex, 1); // Remove asset from list
          }
        }
        this._assetsChildrenToggled$.next(assetsChildrenToggled); // Update the list
      });
  }
}
