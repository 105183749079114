import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as moment from "moment";
import {
  Document,
  DocumentType,
  DocumentRequirement,
  makeDocumentRequirement,
  DocumentPerimeterSummary,
  makeDocumentPerimeterSummary,
  DocumentMultiPerimeterSummary,
  makeDocumentMultiPerimeterSummary,
  makeDocumentInvestmentSummary,
  DocumentInvestmentSummary,
  DocumentAssetSummary,
  makeDocumentAssetSummary,
  makeDocument,
} from "../structs/documents";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { DOCUMENT_OBJECT, SuccessToastService } from "./success-toast.service";
import { Investment } from "../structs/investments";
import { Asset, Perimeter } from "../structs/assets";
import { SynchronizationService } from "./synchronization.service";
import {
  makeChange,
  saveDocumentDefaultsAction,
} from "../structs/synchronization";

@Injectable()
export class DocumentsService {
  constructor(
    private backend: BackendService,
    private offlineApi: OfflineService,
    private successToast: SuccessToastService,
    private syncApi: SynchronizationService
  ) {}

  getDocumentTypes(): Observable<Array<DocumentType>> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("documentTypes").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getDefaultsForInvestments(): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend.get("/documents/api/investment-defaults/").subscribe(
        (jsonData) => {
          let requirements: Array<DocumentRequirement> = [];
          for (let i = 0, l = jsonData.length; i < l; i++) {
            var requirement = makeDocumentRequirement(jsonData[i]);
            requirements.push(requirement);
          }

          observer.next(requirements);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getDocumentSummaryForInvestment(
    investmentId: number
  ): Observable<DocumentInvestmentSummary> {
    return new Observable((observer) => {
      this.backend
        .get("/documents/api/investment-document-summary/" + investmentId)
        .subscribe(
          (jsonData) => {
            var summary = makeDocumentInvestmentSummary(jsonData);
            observer.next(summary);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDocumentSummaryForAsset(
    assetId: number
  ): Observable<DocumentAssetSummary> {
    return new Observable((observer) => {
      this.backend
        .get("/documents/api/asset-document-summary/" + assetId)
        .subscribe(
          (jsonData) => {
            var summary = makeDocumentAssetSummary(jsonData);
            observer.next(summary);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDocumentSummaryForPerimeter(
    perimeterId: number
  ): Observable<DocumentPerimeterSummary> {
    return new Observable((observer) => {
      this.backend
        .get(
          "/documents/api/document-type-summary-for-perimeter/" + perimeterId
        )
        .subscribe(
          (jsonData) => {
            var summary = makeDocumentPerimeterSummary(jsonData);
            observer.next(summary);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDocumentSummaryForPerimeterAndDocType(
    perimeterId: number,
    docTypeId: number
  ): Observable<Array<DocumentPerimeterSummary>> {
    return new Observable((observer) => {
      this.backend
        .get(
          "/documents/api/document-type-summary-for-perimeter-and-doctype/" +
            perimeterId +
            "/" +
            docTypeId
        )
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentPerimeterSummary> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var summary = makeDocumentPerimeterSummary(jsonData[i]);
              elements.push(summary);
            }

            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getPerimeterDocumentsForDocTypes(
    perimeter: Perimeter,
    docTypes: DocumentType[]
  ): Observable<Document[]> {
    return new Observable((observer) => {
      let docTypesAsStr = docTypes.map((elt) => "" + elt.id).join("-");
      docTypesAsStr = docTypesAsStr || "0"; // if no document types : set types as 0
      let url =
        "/documents/api/documents-for-type-and-perimeter/" +
        docTypesAsStr +
        "/" +
        perimeter.id +
        "/";
      this.backend.get(url).subscribe(
        (jsonData) => {
          observer.next(jsonData.map((elt) => makeDocument(elt)));
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getDocumentSummaryForMultiPerimeter(
    perimeterId: number
  ): Observable<DocumentMultiPerimeterSummary[]> {
    return new Observable((observer) => {
      this.backend
        .get(
          "/documents/api/document-type-summary-for-multi-perimeter/" +
            perimeterId
        )
        .subscribe(
          (jsonData) => {
            let elements: DocumentMultiPerimeterSummary[] = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var summary = makeDocumentMultiPerimeterSummary(jsonData[i]);
              elements.push(summary);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDocumentsForPerimeterAndDocType(
    perimeterId: number,
    docTypeId: number
  ): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend
        .get(
          "/documents/api/document-type-for-perimeter/" +
            perimeterId +
            "/" +
            docTypeId
        )
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentRequirement> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var docList = makeDocumentRequirement(jsonData[i]);
              elements.push(docList);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDefaultDocumentTypesForInvestment(
    investmentId: number
  ): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend
        .get(
          "/documents/api/default-document-types-for-investment/" + investmentId
        )
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentRequirement> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var docList = makeDocumentRequirement(jsonData[i]);
              elements.push(docList);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDocumentTypesForInvestment(
    investmentId: number
  ): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend
        .get("/documents/api/document-types-for-investment/" + investmentId)
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentRequirement> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var docList = makeDocumentRequirement(jsonData[i]);
              elements.push(docList);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  updateInvestmentDefaultDocuments(
    investment: Investment,
    asset: Asset,
    docRequirements: Array<DocumentRequirement>
  ): Observable<void> {
    return new Observable((observer) => {
      if (docRequirements.length === 0) {
        observer.next();
      } else {
        var data = {
          defaults: [],
        };

        for (let i = 0; i < docRequirements.length; i++) {
          data.defaults.push({
            document_type: docRequirements[i].document_type.id,
            mandatory: docRequirements[i].mandatory,
          });
        }

        let url =
          "/documents/api/update-investment-document-type-defaults/" +
          investment.id +
          "/";
        this.syncApi
          .addChange(
            makeChange(
              saveDocumentDefaultsAction,
              url,
              "post",
              data,
              null,
              investment.localId
            )
          )
          .subscribe(() => {
            observer.next();
          });
      }
    });
  }

  getDefaultDocumentTypesForAsset(
    assetId: number
  ): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend
        .get("/documents/api/default-document-types-for-asset/" + assetId)
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentRequirement> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var docList = makeDocumentRequirement(jsonData[i]);
              elements.push(docList);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getDocumentTypesForAsset(
    assetId: number
  ): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend
        .get("/documents/api/document-types-for-asset/" + assetId)
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentRequirement> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var docList = makeDocumentRequirement(jsonData[i]);
              elements.push(docList);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  updateAssetDefaultDocument(
    assetId: number,
    docTypeId: number,
    mandatory: boolean
  ): Observable<number> {
    return new Observable((observer) => {
      var data = {
        document_type: docTypeId,
        mandatory: mandatory,
      };

      this.backend
        .post(
          "/documents/api/update-asset-document-type-defaults/" + assetId,
          data
        )
        .subscribe(
          (jsonData) => {
            observer.next(jsonData);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getRequirementsForPerimeter(
    perimeterId: number
  ): Observable<Array<DocumentRequirement>> {
    return new Observable((observer) => {
      this.backend
        .get(
          "/documents/api/document-requirements-for-perimeter/" + perimeterId
        )
        .subscribe(
          (jsonData) => {
            let elements: Array<DocumentRequirement> = [];
            for (let i = 0, l = jsonData.length; i < l; i++) {
              var docList = makeDocumentRequirement(jsonData[i]);
              elements.push(docList);
            }
            observer.next(elements);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getUrl(perimeterId: number, investmentId: number, assetId: number): string {
    let url =
      "/documents/api/document-types-for-perimeter-action/" + perimeterId;
    if (!perimeterId && investmentId) {
      url =
        "/documents/api/document-types-for-investment-action/" + investmentId;
    }
    if (!perimeterId && !investmentId && assetId) {
      url = "/documents/api/document-types-for-asset-action/" + assetId;
    }
    return url;
  }

  deleteDocument(
    docTypeId: number,
    doc: Document,
    perimeterId: number,
    investmentId: number,
    assetId: number
  ): Observable<void> {
    return Observable.create((observer) => {
      var data = {
        action: "delete",
        document_type: docTypeId,
        document: {
          id: doc.id,
          title: doc.title,
          link: doc.link,
          equipment: doc.equipment,
          comments: doc.comments,
        },
      };

      this.backend
        .post(this.getUrl(perimeterId, investmentId, assetId), data)
        .subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  saveDocument(
    docType: DocumentType,
    doc: Document,
    perimeterId: number,
    investmentId: number,
    assetId: number
  ): Observable<number> {
    return Observable.create((observer) => {
      var action: string = doc.id == null ? "create" : "update";
      var data = {
        action: action,
        document_type: docType.id,
        document: {
          id: doc.id,
          title: doc.title,
          link: doc.link,
          equipment: doc.equipment,
          comments: doc.comments,
          expiry_date: doc.expiry_date
            ? moment(doc.expiry_date).format("YYYY-MM-DD")
            : null,
        },
      };

      if (action == "create") {
        delete data.document["id"];
      }

      this.backend
        .post(this.getUrl(perimeterId, investmentId, assetId), data)
        .subscribe(
          (jsonData) => {
            if (action == "create") {
              this.successToast.showObjectCreated(DOCUMENT_OBJECT);
            }
            observer.next(jsonData);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }
}
