import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  NavController,
  NavParams,
  AlertController,
  ModalController,
} from "@ionic/angular";

import { Asset, Category, Perimeter, SubCategory } from "../../structs/assets";
import { AssetDetailPage } from "../asset-detail/asset-detail.page";
import { TranslateService } from "@ngx-translate/core";
// import { AssetAddPage } from '../asset-add/asset-add';
import { ScopeService } from "../../services/scope.service";
import { AssetsService } from "../../services/assets.service";
import { SearchService } from "../../services/search.service";
import { Investment } from "../../structs/investments";
import { Observable, Subscription } from "rxjs";
import { AssetSearch } from "../../structs/search";
import { Events } from "src/app/services/events.service";
import { NavigationExtras, Router } from "@angular/router";
import { AssetAddPage } from "../asset-add/asset-add.page";

@Component({
  selector: "app-assets-list",
  templateUrl: "./assets-list.page.html",
  styleUrls: ["./assets-list.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AssetsListPage implements OnInit, OnDestroy {
  // TODO : The modal needs these parameters to be inputs
  // But if you open the page with the router, we will need
  // these as navigation parameters.
  @Input() title: string = "";
  @Input() selectMode: boolean = false;
  @Input() allowAllPerimeters: boolean = false;
  @Input() investment: Investment = null;
  @Input() perimeter: Perimeter = null;

  private assets: Array<Asset> = [];
  private otherBuildingAssets: Array<Asset> = [];
  public message: string = "";
  public onlyCategory: Category = null;
  public onlySubCategory: SubCategory = null;
  showAllAssets: boolean = true;
  private assetsCount = 0;
  private buildingId: number = null;
  private loading: boolean = false;
  public searchText: string;
  public searchResults: Asset[] = [];

  public hasFiltersSelected: boolean = false;
  private hasFilterSelectedSubscription: Subscription;
  private currentMultiperimeter: Perimeter;

  constructor(
    public navCtrl: NavController,
    private navParams: NavParams,
    private translate: TranslateService,
    private scope: ScopeService,
    private event: Events,
    private alertCtrl: AlertController,
    private assetsApi: AssetsService,
    private modalCtrl: ModalController,
    private searchService: SearchService,
    private router: Router
  ) {}

  ngOnInit() {
    // this.message = this.navParams.get('message') || '';
    this.onlyCategory = this.investment.category || null;
    this.onlySubCategory = this.investment.subCategory || null;
    if (this.title === null) {
      this.title = this.translate.instant("Assets");
    }
    // this.allowAllPerimeters = !!this.navParams.get('allowAllPerimeters');
    // this.buildingId = this.navParams.get('buildingId');
    this.initAssetFilters().subscribe(() => {
      this.searchAssets();
      let observable: Observable<boolean> =
        this.searchService.assetsFiltersSelected;
      this.hasFilterSelectedSubscription = observable.subscribe(
        (hasFiltersSelected) => {
          this.hasFiltersSelected = hasFiltersSelected;
        }
      );
    });
    this.event.subscribe("newAsset", (newAsset: Asset) => {
      if (this.buildingId && newAsset.building.id === this.buildingId) {
        this.assets.push(newAsset);
      } else {
        this.otherBuildingAssets.push(newAsset);
      }
      this.searchAssets();
    });
    this.scope.getCurrentMultiPerimeter().subscribe((multiperimeter) => {
      this.currentMultiperimeter = multiperimeter;
    });
  }

  ngOnDestroy() {
    this.event.unsubscribe("newAsset");
    if (this.hasFilterSelectedSubscription) {
      this.hasFilterSelectedSubscription.unsubscribe();
    }
    this.hasFiltersSelected = false;
    this.searchService.clearAssetsFilters();
  }

  public cancel() {
    if (this.selectMode) {
      this.modalCtrl.dismiss({});
    }
  }

  public onAssetClicked(asset) {
    if (this.selectMode) {
      this.selectAsset(asset);
    } else {
      this.showAssetDetail(asset);
    }
  }

  private async selectAsset(asset: Asset) {
    if (
      (!this.onlyCategory || asset.category.id === this.onlyCategory.id) &&
      (!this.onlySubCategory ||
        asset.subCategory.id === this.onlySubCategory.id)
    ) {
      this.modalCtrl.dismiss({ asset: asset });
    } else {
      const nomenclatureAlert = await this.alertCtrl.create({
        header: this.translate.instant("For information"),
        subHeader: this.translate.instant("nomenclature_warning"),
        buttons: [
          {
            text: "OK",
            handler: () => {
              this.modalCtrl.dismiss({ asset: asset });
            },
          },
        ],
      });
      await nomenclatureAlert.present();
    }
  }

  private showAssetDetail(asset: Asset) {
    this.router
      .navigate([
        "perimeters",
        this.currentMultiperimeter.id,
        "asset-detail",
        asset.id,
      ])
      .then();
  }

  async addAsset() {
    let assetsModal = await this.modalCtrl.create({
      component: AssetAddPage,
      componentProps: {
        title: this.translate.instant("Attach an asset"),
        selectMode: true,
        allowAllPerimeters: true,
        investment: this.investment,
        currentPerimeter: this.perimeter,
        runAsModalMode: true,
      },
    });
    await assetsModal.present();
  }

  public searchByKeywords() {
    this.removeDuplicateChildren();
    if (this.searchText) {
      let assetList = this.assets;
      if (this.showAllAssets) {
        assetList = assetList.concat(this.otherBuildingAssets);
      }
      this.searchResults = this.assetsApi.searchAssetsByKeyWords(
        assetList,
        this.searchText
      );
    } else {
      this.initSearchResults();
    }
  }

  initSearchResults() {
    this.removeDuplicateChildren();
    this.searchResults = this.assets;
    if (this.showAllAssets) {
      this.searchResults = this.searchResults.concat(this.otherBuildingAssets);
    }

    // Make
    this.searchResults = this.searchResults.sort((a, b) => {
      if (!a.lastAccess) {
        return 1;
      } else if (!b.lastAccess) {
        return -1;
      } else {
        const dateA =
          b.lastAccess instanceof Date ? a.lastAccess : new Date(a.lastAccess);
        const dateB =
          b.lastAccess instanceof Date ? b.lastAccess : new Date(b.lastAccess);
        return dateB.getTime() - dateA.getTime();
      }
    });
  }

  // Make sure we don't have duplicate asset in the list
  // (A children could be displayed inside + outside its parent...)
  private removeDuplicateChildren() {
    this.assets = this.assets.filter(
      (elt) =>
        !this.assets.some((asset) =>
          asset.children.some(
            (c) => c.offlineId === elt.offlineId && c.offline && elt.offline
          )
        )
    );

    this.otherBuildingAssets = this.otherBuildingAssets.filter(
      (elt) =>
        !this.otherBuildingAssets.some((asset) =>
          asset.children.some(
            (c) => c.offlineId === elt.offlineId && c.offline && elt.offline
          )
        )
    );
  }

  public openAssetsFilters(): void {
    // TODO
    // const filtersModal = this.modalCtrl.create('assets-filters');
    // filtersModal.onDidDismiss(() => this.searchAssets());
    // filtersModal.present();
  }

  searchAssets(refresher?) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.assets = [];
    this.otherBuildingAssets = [];
    this.searchService.searchAssets().subscribe((assets) => {
      // if "AssetAffiliation" (assets having children assets) is enabled
      // We don't show the assets which having a parent
      assets.map((elt) => {
        const isBuildingAsset = elt.building.id === this.investment.buildingId;
        if (isBuildingAsset) {
          this.assets.push(elt);
        } else {
          this.otherBuildingAssets.push(elt);
        }
      });
      this.searchByKeywords();

      this.loading = false;
      if (refresher) {
        refresher.complete();
      }
      // this.onSearchFinished.emit({assets: this.assets});
    });
  }

  private initAssetFilters(): Observable<void> {
    // For now we only need to initialise the perimeter filter, so this function seems useless.
    // But if we are told to initialise the category, subCategory or another filter, this will be
    // a good place to do it.
    return new Observable((observer) => {
      if (!this.hasFiltersSelected) {
        this.searchService.generateAssetsFilters(true).subscribe(
          (filters) => {
            this.searchService.clearAssetsFilters();
            this.setPerimetersFilter(filters);
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          },
          () => {
            observer.complete();
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  private setPerimetersFilter(filters) {
    // Perimeter filter
    let perimeterFilter = null;
    perimeterFilter = filters.find(
      (filter) => filter instanceof AssetSearch.PerimeterSearchFilter
    );

    if (perimeterFilter) {
      let selectedPerimeters = [];
      // If showAllAssets = true, we show the assets of all available perimeters
      if (this.showAllAssets) {
        selectedPerimeters = perimeterFilter.availableValues;
      } else {
        selectedPerimeters = perimeterFilter.availableValues.filter((val) => {
          return val.id === this.investment.buildingId;
        });
      }
      if (selectedPerimeters.length > 0) {
        perimeterFilter.selectedValues = selectedPerimeters;
        this.searchService.assetFilterSelected(perimeterFilter);
      }
    }
  }
}
