import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActionSheetController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Environment } from "src/app/app.environment";
import { AuthService } from "src/app/services/auth.service";
import { BrowserService } from "src/app/services/browser.service";
import { ErrorsService } from "src/app/services/errors.service";
import { ExportsService } from "src/app/services/exports.service";
import { PerimetersService } from "src/app/services/perimeters.service";
import { UsersService } from "src/app/services/users.service";
import { Perimeter } from "src/app/structs/assets";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "perimeters-list-item",
  templateUrl: "./perimeters-list-item.component.html",
  styleUrls: ["./perimeters-list-item.component.scss"],
})
export class PerimetersListItemComponent {
  @Input() public perimeter: Perimeter;
  // @Input() public tasks: Task[] = [];
  @Output() public activate = new EventEmitter<void>();

  private actionExport: string;
  private actionInvestments: string;
  private actionAssets: string;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private auth: AuthService,
    private browser: BrowserService,
    private errorsService: ErrorsService,
    private exportsService: ExportsService,
    private translate: TranslateService,
    private usersService: UsersService,
    private alertCtrl: AlertController
  ) {
    this.actionExport = this.translate.instant("Excel export");
    this.actionInvestments = this.translate.instant("Investments");
    this.actionAssets = this.translate.instant("Assets");
  }

  public clickItem(): void {
    this.activate.emit();
  }

  public async exportExcel(): Promise<void> {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.actionExport,
      buttons: [
        {
          text: this.actionInvestments,
          handler: () => this.exportInvestmentPlan(),
        },
        {
          text: this.actionAssets,
          handler: () => this.exportAssets(),
        },
      ],
    });
    await actionSheet.present();
  }

  public exportTechnicalPortfolio(): void {
    this.usersService.generateJwt().subscribe((jwt) => {
      const url = `${Environment.getCAPEXIntelligenceHost()}/#/reports/overview?jwt=${jwt}&perimeter=${
        this.perimeter.id
      }`;
      this.browser.openUrl(url);
    });
  }

  private exportInvestmentPlan(): void {
    this.auth.getCurrentUser().then((user) => {
      const url = `${Environment.getBackendHost()}/reporting/investments-report/${
        user.uuid
      }/?perimeter_id=${this.perimeter.id}`;
      this.browser.openUrl(url);
    });
  }

  private exportAssets(): void {
    this.auth.getAuthorizationString().then((authString) => {
      const buildings = PerimetersService.getBuildingsIds([
        this.perimeter,
      ]).join(",");
      this.exportsService
        .downloadAssetsExport(authString, { buildings })
        .subscribe(
          () => {},
          (err) => this.errorsService.signalError(err)
        );
    });
  }

  public async exportPictures(): Promise<void> {
    let confirm = await this.alertCtrl.create({
      header: this.translate.instant("Donwload the pictures"),
      message: this.translate.instant("Feature coming soon"),
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant("OK"),
        },
      ],
    });
    await confirm.present();
  }
}
