<legend-item
  [legend]="legend"
  (click)="onPictureClick($event)"
  [showArrow]="true"
>
  <span *ngIf="syncInProgress">-</span>
  <ng-container *ngIf="!syncInProgress">
    <span *ngIf="pictures.length > 0">{{ pictures.length }}</span>
    <span *ngIf="pictures.length === 0">-</span>
  </ng-container>
</legend-item>
