<ng-container *ngIf="priceSheets$ | async as priceSheets">
  <ion-list lines="full" *ngIf="priceSheets.length > 0">
    <ion-item *ngFor="let priceSheet of priceSheets">
      <!-- Check icons. We can't use a regular ion-checkbox because it messes
      with the click function that we use to open the priceSheet. 
      The ion-checkbox takes any click on the whole item -->
      <i
        class="fa-regular fa-circle-dot"
        *ngIf="priceSheet.id === selectedPriceSheetId"
        (click)="selectPriceSheet(priceSheet.id)"
        slot="start"
      ></i>
      <i
        class="fa-regular fa-circle"
        *ngIf="priceSheet.id !== selectedPriceSheetId"
        (click)="selectPriceSheet(priceSheet.id)"
        slot="start"
      ></i>
      <ion-label (click)="openPriceSheet(priceSheet, $event)">
        <ion-text color="secondary" class="text-overflow-ellipsis">
          <h2>{{ priceSheet.label }}</h2>
        </ion-text>
        <ion-text color="primary"
          >{{ priceSheet.unit_price | currency }} /
          {{ priceSheet.quantity_unit.name }}</ion-text
        >
        <ion-text color="primary" id="quantity" *ngIf="showQuantity(priceSheet)"
          >({{ priceSheet.quantity }}
          {{ priceSheet.quantity_unit.name }})</ion-text
        >
        <br />
        <ion-text color="unknown">{{ priceSheet.modified_on | date }}</ion-text
        ><br />
        <ion-text color="children"
          >{{ "Source" | translate }} :
          {{ priceSheet.author | username }}</ion-text
        >
      </ion-label>
    </ion-item>
  </ion-list>

  <div *ngIf="priceSheets.length === 0" class="empty-value">
    <div class="no-results-icon">
      <i class="fa-light fa-file fa-3x"></i>
    </div>
    <div class="no-results-text">
      {{ "No price sheet" | translate }}
    </div>
    <div class="no-results-text" *ngIf="!asset">
      {{ "Please attach the investment to an equipment" | translate }}
    </div>
  </div>
</ng-container>
