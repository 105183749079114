import * as moment from "moment";
import { v4 as UUID } from "uuid";
import { Asset } from "./assets";
import { Investment } from "./investments";

export const addAssetAction = "addAsset";
export const saveAssetAction = "saveAsset";
export const deleteAssetAction = "deleteAsset";
export const addAssetPictureAction = "addAssetPicture";
export const deleteAssetPictureAction = "deleteAssetPicture";
export const setAuditNoteAction = "setAuditNote";
export const saveInvestmentAction = "saveInvestment";
export const addInvestmentAction = "addInvestment";
export const deleteInvestmentAction = "deleteInvestment";
export const addInvestmentPictureAction = "addInvestmentPicture";
export const deleteInvestmentPictureAction = "deleteInvestmentPicture";
export const addPerimeterPictureAction = "addPerimeterPicture";
export const deletePerimeterPictureAction = "deletePerimeterPicture";
export const assetAccessAction = "assetAccess";
export const setAuditNoteAfterInvestmentAction = "setAuditNoteAfterInvestment";
export const setRoadmapAnswersAction = "setRoadmapAnswers";
export const saveControlPointAction = "saveControlPoint";
export const deleteControlPointAction = "deleteControlPointAction";
export const addTaskAction = "addTask";
export const saveTaskAction = "saveTask";
export const deleteTaskAction = "deleteTask";
export const saveDocumentDefaultsAction = "saveDocumentDefaults";
export const addPostponementAction = "addPostponement";
export const attachInvestmentAction = "attachInvestment";
export const addMonoPerimeterAction = "addMonoPerimeter";
export const saveMonoPerimeterAction = "saveMonoPerimeter";
export const deleteMonoPerimeterAction = "deleteMonoPerimeter";
export const saveMultiPerimeterAction = "saveMultiPerimeter";
export const saveReferenceDataAction = "saveReferenceData";
export const setAuditExpertModeAction = "setAuditExpertMode";
export const addAuditQuestionPictureAction = "addAuditQuestionPictureAction";
export const deleteAuditQuestionPictureAction =
  "deleteAuditQuestionPictureAction";
export const updatePerimeterCoverImageAction =
  "updatePerimeterCoverImageAction";
export const putAssetEnergyConsumption = "putAssetEnergyConsumption";

export class Change {
  constructor(
    public timestamp: number,
    public type: string,
    public url: string,
    public method: string,
    public data: any,
    public asset: Asset,
    public assetOfflineId: number,
    public localId: string,
    public investment: Investment = null,
    public perimeterLocalId: string = "",
    public uuid: UUID = null
  ) {}

  public static toLogObject(change: Change): object {
    if (!change.uuid) {
      throw TypeError("Change object without UUID");
    }
    return {
      timestamp: change.timestamp,
      type: change.type,
      url: change.url,
      method: change.method,
      data: change.data,
      assetOfflineId: change.assetOfflineId,
      localId: change.localId,
      uuid: change.uuid,
      ...(change.asset ? { asset: change.asset.id } : {}),
    };
  }
}

export function makeChange(
  type: string,
  url: string,
  method: string,
  data: any,
  asset: Asset | null = null,
  localId: string = "",
  investment: Investment = null,
  perimeterLocalId: string = ""
): Change {
  let timestamp: number = moment.now().valueOf(),
    uuid = UUID();
  return new Change(
    timestamp,
    type,
    url,
    method,
    data,
    asset,
    asset !== null ? asset.offlineId : 0,
    localId,
    investment,
    perimeterLocalId,
    uuid
  );
}

export enum SynchronizationStatus {
  STARTED = "STARTED", // synchronization in progress
  DONE = "DONE", // all changes have been synchronized
  POSTPONED = "POSTPONED", // Network error : synchronization is postponed
  CHANGE_TO_DO_ADDED = "CHANGE_TO_DO_ADDED", // synchronization added to the list
  CHANGE_DONE = "CHANGE_DONE", // Deleted after success
  CHANGE_DELETED = "CHANGE_DELETED", // Deleted after server error
  PUSH = "PUSH", // request a new push
  VERBOSE = "VERBOSE", // display sync event in toast
}

export class SynchronizationState {
  constructor(
    public status: SynchronizationStatus,
    public change: Change = null
  ) {}
}
