<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClicked()" *ngIf="!runAsModalMode">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
      <ion-button *ngIf="runAsModalMode" (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{ getPageTitle() }} </ion-title>

    <ion-buttons class="top-buttons" slot="end">
      <ion-button
        ion-button
        (click)="toggleSearchMode()"
        *ngIf="activeStep === Steps.ASSETTYPE && !fromRoadmap"
        class="search-mode-button"
      >
        <img src="assets/img/sitemap-light.svg" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="activeStep === Steps.CAMERA">
    <camera (changed)="detailsChanged()"> </camera>
  </ng-container>
  <ng-container *ngIf="activeStep === Steps.PERIMETER">
    <ion-list lines="full">
      <ion-item
        *ngFor="let perimeter of perimeters"
        (click)="perimeterChanged(perimeter)"
      >
        {{ perimeter.name }}
      </ion-item>
    </ion-list>
  </ng-container>
  <ng-container *ngIf="activeStep === Steps.ASSETTYPE">
    <asset-type-selector
      [parent]="parent"
      (detailsChanged)="detailsChanged($event)"
      [autoSelectCategoryId]="autoSelectCategoryId"
      [autoSelectSubCategoryId]="autoSelectSubCategoryId"
      [autoSelectAssetTypeId]="autoSelectAssetTypeId"
      [searchMode]="searchMode"
      #assetTypeSelectorComponent
    >
    </asset-type-selector>
  </ng-container>
  <ng-container *ngIf="activeStep === Steps.DETAIL">
    <asset-detail-segment
      (changed)="detailsChanged($event)"
      (pageChanged)="pageChanged()"
    ></asset-detail-segment>
  </ng-container>
  <ng-container
    *ngIf="assetEditService.getAssetType() !== null && activeStep === Steps.LIFECYCLE"
  >
    <asset-lifecycle-segment
      [hidden]="notationTab !== 'lifecycle'"
      (changed)="detailsChanged($event)"
      [hasEnergyTrajectory]="needsEnergyTrajectory"
    ></asset-lifecycle-segment>
  </ng-container>
  <ng-container
    *ngIf="assetEditService.getAssetType() !== null && activeStep === Steps.OTHERNOTATIONS"
  >
    <kpi-notations
      [asset]="assetEditService.asset"
      [questions]="otherNotationsQuestions"
      (changed)="notationsChanged($event)"
      (initEvent)="detailsChanged($event)"
      [hasEnergyTrajectory]="needsEnergyTrajectory"
    >
    </kpi-notations>
  </ng-container>
  <ng-container *ngIf="activeStep === Steps.EXPERT">
    <asset-expert-notation-segment
      (expertModeChanged)="expertModeChanged($event)"
      (notationsChanged)="notationsChanged($event)"
      (changed)="detailsChanged($event)"
      [hasEnergyTrajectory]="needsEnergyTrajectory"
    >
    </asset-expert-notation-segment>
  </ng-container>
  <ng-container *ngIf="activeStep === Steps.PARENT">
    <asset-parent-selector
      [asset]="assetEditService.asset"
      (parentSelected)="onParentSelected($event, false)"
      (autoParentSelected)="onParentSelected($event, true)"
    ></asset-parent-selector>
  </ng-container>

  <ng-container *ngIf="activeStep === Steps.CONSUMPTION">
    <asset-consumption
      [monoPerimeter]="assetEditService.buildingMonoPerimeter"
      (assetConsumptionChange)="assetEnergyConsumption = $event"
      (initEvent)="detailsChanged($event)"
    ></asset-consumption>
  </ng-container>
</ion-content>

<ion-footer>
  <app-footer-next-button
    nextButton
    (next)="next($event)"
    [label]="nextLabel"
    [enabled]="!!nextEnabled"
    color="asset"
    (disabledClicked)="disabledClicked()"
    [labelChangedEvent]="nextLabelChanged"
  ></app-footer-next-button>
</ion-footer>
