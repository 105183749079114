<div class="picture-container" *ngIf="url$ | async as urls; else noPicture">
  <ng-container *ngIf="picture && urls && picture.id !== -1; else noPicture">
    <ng-container
      *ngTemplateOutlet="
        thumbnail ? asThumbnail : asFullPicture;
        context: { urls: urls }
      "
    >
    </ng-container>
  </ng-container>
</div>

<ng-template #noPicture>
  <div class="no-picture">
    <div class="content" *ngIf="picturesOn">
      {{ "No photo" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #asFullPicture let-urls="urls">
  <ion-slides [options]="zoomOptions" zoom>
    <ion-slide>
      <img class="picture" [src]="urls.fullPicture" />
    </ion-slide>
  </ion-slides>
</ng-template>

<ng-template #asThumbnail let-urls="urls">
  <img
    [class.select]="selected"
    class="picture thumbnail"
    [src]="urls.thumbnail"
    (error)="backupThumbnail({ el: $event, fullPicture: urls.fullPicture })"
    [attr.width]="thumbnail ? '50' : null"
  />
</ng-template>
