import { DecimalPipe } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { I18nService } from "src/app/services/i18n.service";
import { PerimeterReferenceData } from "src/app/structs/assets";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "reference-data-value",
  template: `
    <span [ngClass]="{ aggregated: perimeterReferenceData.aggregated }">
      {{ displayValue }}
    </span>
  `,
  styleUrls: ["./reference-data-value.component.scss"],
})
export class ReferenceDataValueComponent implements OnChanges {
  @Input() public perimeterReferenceData: PerimeterReferenceData;
  public displayValue: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private i18nService: I18nService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.perimeterReferenceData) {
      if (Array.isArray(this.perimeterReferenceData.value)) {
        const displayValues = this.perimeterReferenceData.value.map((value) =>
          this.getDisplayValue(value)
        );
        this.displayValue = displayValues.join(", ");
      } else {
        this.displayValue = this.getDisplayValue(
          this.perimeterReferenceData.value
        );
      }
    }
  }

  private getDisplayValue(value: any): string {
    if (isNaN(value)) {
      return value;
      // Format number values
    } else {
      return this.decimalPipe.transform(
        value,
        null,
        this.i18nService.getLanguage()
      );
    }
  }
}
