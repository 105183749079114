import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  AlertController,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import { InvestmentsService } from "../../../services/investments.service";
import { OfflineService } from "../../../services/offline.service";
import {
  INVESTMENT_OBJECT,
  SuccessToastService,
} from "../../../services/success-toast.service";
import { TitleService } from "../../../services/title.service";
import { Asset, Perimeter } from "../../../structs/assets";
import { Investment } from "../../../structs/investments";
import { Events } from "src/app/services/events.service";

@Component({
  selector: "app-investment-modal",
  templateUrl: "./investment-modal.component.html",
  styleUrls: ["./investment-modal.component.scss"],
})
export class InvestmentModalComponent implements OnInit {
  showInvestments: boolean = false;
  investments: Investment[] = [];
  private perimeter: Perimeter = null;
  private asset: Asset = null;
  loading: boolean;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private investmentsService: InvestmentsService,
    private offlineApi: OfflineService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private events: Events,
    private successToast: SuccessToastService
  ) {}

  ngOnInit() {
    this.perimeter = this.navParams.get("perimeter");
    this.asset = this.navParams.get("asset");
    console.log("perimeter", this.perimeter);
    console.log("perimeter", this.asset);
    this.loadInvestments();
  }

  backClicked() {
    if (this.showInvestments) {
      this.showInvestments = false;
    } else {
      this.dismiss();
    }
  }

  dismiss(newInvestment?: boolean) {
    let data = { newInvestment: newInvestment };
    this.modalCtrl.dismiss(data);
  }

  showInvestmentsList() {
    // A timeout so the user can see his choice checked before going to the next step
    setTimeout(() => {
      this.showInvestments = true;
    }, 300);
  }

  loadInvestments() {
    this.investments = [];
    if (this.perimeter) {
      this.perimeter.sub_perimeters.forEach((subPerimeter) => {
        this.offlineApi
          .getGlobalInvestments(subPerimeter.building_id)
          .subscribe(
            (investments) => {
              this.investments = this.investments.concat(investments);
            },
            (err) => {
              console.log(err);
            }
          );
      });
    }
  }

  async attachInvestment(investment: Investment) {
    if (
      (investment.category &&
        this.asset.category.id !== investment.category.id) ||
      (investment.subCategory &&
        this.asset.subCategory.id !== investment.subCategory.id)
    ) {
      let nomenclatureAlert = await this.alertCtrl.create({
        header: this.translate.instant("For information"),
        subHeader: this.translate.instant("nomenclature_warning"),
        buttons: [
          {
            text: "OK",
            handler: () => {
              this.doAttachInvestment(investment);
            },
          },
        ],
      });
      await nomenclatureAlert.present();
    } else {
      this.doAttachInvestment(investment);
    }
  }

  private doAttachInvestment(investment: Investment) {
    this.investmentsService
      .attachInvestmentToAsset(investment, this.asset)
      .subscribe((invest) => {
        this.events.publish(
          "updateAssetsInvestmentsTotal",
          this.asset.investments.length
        );
        const investmentAttachedMessage = this.translate.instant(
          "The investment has been attached"
        );
        this.successToast.showSuccessToast(
          INVESTMENT_OBJECT,
          investmentAttachedMessage
        );
        this.dismiss();
      });
  }

  public getTitle() {
    let title: string;
    if (!this.showInvestments) {
      title = this.translate.instant("Attach an investment to the asset");
    } else {
      title =
        this.perimeter.name + " > " + this.translate.instant("New investment");
    }
    return title;
  }
}
