import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

// import { map } from 'rxjs/operators';

import { OfflineService } from "./offline.service";
import { AppRelease } from "../structs/base";
import { Platform } from "@ionic/angular";

type PlatformName =
  | "android"
  | "ios"
  | "cordova"
  | "desktop"
  | "ipad"
  | "iphone"
  | "mobile"
  | "mobileweb"
  | "phablet"
  | "tablet";

@Injectable()
export class VersionsService {
  constructor(private offlineApi: OfflineService, private platform: Platform) {}

  /**
   *
   * @returns {string} name of the platform
   */
  getPlatform(): string {
    let platformNames: PlatformName[] = [
      "android",
      "ios",
      "cordova",
      "desktop",
      "ipad",
      "iphone",
      "mobile",
      "mobileweb",
      "phablet",
      "tablet",
    ];
    for (let i = 0, l = platformNames.length; i < l; i++) {
      if (this.platform.is(platformNames[i])) {
        return platformNames[i];
      }
    }
    return "-";
  }

  /**
   *
   * @returns {string} name of the platform
   */
  isMobileDevice(): boolean {
    let platform: string = this.getPlatform();
    return platform === "android" || platform === "ios";
  }

  /**
   * Returns last release for the version
   * @returns Observable<AppRelease>>
   */
  getLastReleaseVersion(): Observable<AppRelease> {
    return new Observable((observer) => {
      this.offlineApi.getConfig("appReleases").subscribe(
        (jsonData: any) => {
          let release: AppRelease = null;
          if (jsonData) {
            let platform: string = this.getPlatform();
            for (let i = 0; i < jsonData.length; i++) {
              if (jsonData[i].platform === platform) {
                let elt = jsonData[i];
                release = new AppRelease(
                  elt.id,
                  elt.version,
                  elt.platform,
                  elt.url,
                  elt.notes
                );
                break;
              }
            }
          }
          observer.next(release);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Check if the app required to be upgraded
   * @param {string} currentVersion
   * @param {AppRelease} lastRelease
   * @returns {AppRelease}
   */
  checkRelease(
    currentVersion: string,
    lastRelease: AppRelease
  ): Observable<AppRelease> {
    return new Observable((observer) => {
      let newRelease: AppRelease = null;
      if (lastRelease) {
        let currentVersionNumber = currentVersion.split(".");
        let lastReleaseNumber = lastRelease.version.split(".");
        for (
          let i = 0;
          i < currentVersionNumber.length && i < lastReleaseNumber.length;
          i++
        ) {
          if (+currentVersionNumber[i] !== +lastReleaseNumber[i]) {
            if (+currentVersionNumber[i] > +lastReleaseNumber[i]) {
              // Do not propose upgrade if the current version is more recent to the last release on server
              break;
            }
            newRelease = lastRelease;
            if (i >= 2) {
              newRelease.isMinorUpgrade = true;
            } else {
              newRelease.isMajorUpgrade = true;
            }
            break;
          }
        }
      }
      observer.next(newRelease);
      observer.complete();
    });
  }
}
