import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  ActionSheetController,
  AlertController,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import { AssetEditService } from "../../services/asset-edit.service";
import { Asset, Perimeter } from "../../structs/assets";
import { NavigationExtras, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Picture } from "src/app/structs/base";
import { PerimeterPicturesService } from "@services/perimeter-pictures.service";
import { PicturesService } from "@services/pictures.service";
import { map } from "rxjs/operators";
import { BrowserService } from "@services/browser.service";

export interface PictureViewerOptions {
  asset?: Asset;
  perimeter?: Perimeter;
  multiPerimeter?: Perimeter;
  hideLinkToDetails?: boolean;
  pictureOnly?: boolean;
  type?: string;
  title?: string;
}
@Component({
  selector: "app-pic-viewer",
  templateUrl: "./pictures-viewer.component.html",
  styleUrls: ["./pictures-viewer.component.scss"],
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PicturesViewerComponent implements OnInit, OnDestroy {
  public title: string = "...";
  @Input() options: PictureViewerOptions;
  public pictures: Picture[] = [];
  public selectedId: number;
  private subscriptions: Subscription[] = [];
  public inBrowser: boolean = true;
  @ViewChild("browserFileUpload") browserFileUpload: ElementRef;
  private sourceTypeTitle: string = "";
  private sourceTypeCameraButton: string = "";
  private sourceTypeGalleryButton: string = "";
  private cancelLabel: string = "";
  public showNextButton = false;

  constructor(
    public navCtrl: NavController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    public navParams: NavParams,
    private translate: TranslateService,
    private assetEditService: AssetEditService,
    private perimeterPicturesService: PerimeterPicturesService,
    private picturesService: PicturesService,
    private actionSheetCtrl: ActionSheetController,
    private router: Router,
    private browserService: BrowserService
  ) {}

  public ngOnInit() {
    this.inBrowser = this.browserService.inBrowser();
    this.subscriptions.push(
      this.translate
        .get([
          "Source type",
          "Camera",
          "Photo library",
          "Cancel",
          "Confirmation",
          "Would you like to leave the site ?",
          "OK",
          "Add to the perimeter",
        ])
        .subscribe((translations) => {
          this.sourceTypeTitle = translations["Source type"];
          this.sourceTypeCameraButton = translations["Camera"];
          this.sourceTypeGalleryButton = translations["Photo library"];
          this.cancelLabel = translations["Cancel"];
        })
    );
    if (this.options) {
      switch (this.options.type) {
        case "asset":
          this.assetEditService.setAsset(this.options.asset, false);
          this.pictures = this.assetEditService.asset.pictures;
          this.title = this.options.asset?.label;
          if (this.options.asset.pictures.length > 0) {
            this.selectedId = 0;
          }
          break;
        case "perimeter":
          this.pictures = this.options.perimeter.pictures;
          this.title = this.options.perimeter.name;
          if (this.pictures.length > 0) {
            this.selectedId = 0;
          }
          break;
        default:
          break;
      }
    }
  }

  public selectPicture(index: number): void {
    this.selectedId = index;
  }

  public nextPicture(): void {
    this.selectedId = (this.selectedId + 1) % this.pictures.length;
  }

  public previousPicture(): void {
    this.selectedId =
      this.selectedId - 1 < 0 ? this.pictures.length - 1 : this.selectedId - 1;
  }

  public async deletePicture() {
    let confirm = await this.alertCtrl.create({
      header: this.translate.instant("Delete picture"),
      message: this.translate.instant(
        "The picture will be permanently deleted"
      ),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("Cancel"),
          handler: () => {},
        },
        {
          text: this.translate.instant("Ok"),
          handler: () => {
            switch (this.options.type) {
              case "asset":
                this.assetEditService.deletePicture(
                  this.options.asset.pictures[this.selectedId]
                );
                if (this.options.asset.pictures.length == this.selectedId) {
                  if (this.selectedId == 0) {
                    this.selectedId = null;
                  } else {
                    this.selectedId -= 1;
                  }
                }
                this.pictures = this.assetEditService.asset.pictures;
                break;

              case "perimeter":
                this.perimeterPicturesService
                  .deletePerimeterPicture(
                    {
                      ...this.pictures[this.selectedId],
                      perimeter: this.options.perimeter.id,
                    },
                    this.options.perimeter
                  )
                  .subscribe((p) => {
                    if (this.pictures.length - 1 == this.selectedId) {
                      if (this.selectedId == 0) {
                        this.selectedId = null;
                      } else {
                        this.selectedId -= 1;
                      }
                    }
                    if (!this.options.perimeter.is_monosite) {
                      this.pictures = p.pictures;
                    } else {
                      this.pictures = p.sub_perimeters.find(
                        (sP) =>
                          sP.id === this.options.perimeter.id ||
                          (sP.localId &&
                            sP.localId === this.options.perimeter.localId)
                      ).pictures;
                    }
                    this.options.perimeter.pictures = this.pictures.map((p) => {
                      return { ...p, perimeter: this.options.perimeter.id };
                    });
                  });
                break;

              default:
                break;
            }
          },
        },
      ],
    });
    await confirm.present();
  }

  /**
   * Adds the picture to the perimeter
   *
   * @param filePath
   * @param browserFile
   */
  private addPictureToPerimeter(filePath?: string, browserFile?: File): void {
    const newPicture = this.perimeterPicturesService.createPerimeterPicture(
      this.options.perimeter,
      filePath,
      browserFile
    );
    this.pictures.push(newPicture);
    this.options.perimeter.pictures = this.pictures.map((p) => {
      return { ...p, perimeter: this.options.perimeter.id };
    });
    this.selectedId = this.pictures.length - 1;
    this.perimeterPicturesService
      .addPerimeterPicture(newPicture, this.options.perimeter)
      .subscribe();
  }

  public async addPicture(): Promise<void> {
    // On mobile, ask to open the camera or the file library
    if (!this.inBrowser) {
      const actionSheet = await this.actionSheetCtrl.create({
        header: this.sourceTypeTitle,
        buttons: [
          {
            text: this.sourceTypeCameraButton,
            handler: () => {
              this.picturesService
                .captureFromCamera()
                .pipe(
                  map((filePath) => {
                    this.addPictureTo(filePath);
                  })
                )
                .subscribe();
            },
          },
          {
            text: this.sourceTypeGalleryButton,
            handler: () => {
              this.picturesService
                .captureFromLibrary()
                .pipe(
                  map((filePath) => {
                    this.addPictureTo(filePath);
                  })
                )
                .subscribe();
            },
          },
          {
            text: this.cancelLabel,
            role: "cancel",
          },
        ],
      });
      await actionSheet.present();
      // On desktop, trigger the file upload
    } else {
      this.browserFileUpload.nativeElement.click();
    }
  }

  /**
   * Adds a picture to the perimeter using the browser upload file
   */
  public addBrowserPicture(event): void {
    const files: FileList = event.target.files;
    if (files.length !== 0) {
      const file = files[0];
      switch (this.options.type) {
        case "asset":
          for (let i = 0; i < files.length; i++) {
            this.assetEditService.addPicture(null, files[i]);
          }
          this.pictures = this.assetEditService.asset.pictures;
          this.showNextButton = true;
          this.selectedId = this.pictures.length - 1;
          break;
        case "perimeter":
          this.addPictureToPerimeter(null, file); // TODO: add callback here
          break;
        default:
          break;
      }
    }
  }

  public addPictureTo(file): void {
    switch (this.options.type) {
      case "asset":
        this.addPictureToAsset(file);
        break;

      case "perimeter":
        this.addPictureToPerimeter(file);
        break;

      default:
        break;
    }
  }

  public openDetails(): void {
    let navExtras: NavigationExtras;
    switch (this.options.type) {
      case "asset":
        navExtras = {
          state: this.options,
        };
        this.router
          .navigate(
            [
              "perimeters",
              this.options.multiPerimeter.id,
              "asset-detail",
              this.options.asset.id,
            ],
            navExtras
          )
          .finally(() => {
            this.leave();
          });
        break;

      case "perimeter":
        navExtras = {
          state: this.options,
        };
        this.router
          .navigate(
            ["perimeter", "mono-perimeters", this.options.perimeter.id],
            navExtras
          )
          .finally(() => {
            this.leave();
          });
        break;

      default:
        break;
    }
  }

  public leave(): void {
    this.modalCtrl.dismiss();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private addPictureToAsset(filePath) {
    if (filePath) {
      this.assetEditService.addPicture(filePath);
      this.pictures = this.assetEditService.asset.pictures;
      this.showNextButton = true;
      this.selectedId = this.pictures.length - 1;
    }
  }

  public validateChanges() {
    if (this.showNextButton) {
      switch (this.options.type) {
        case "asset":
          this.assetEditService.saveAsset().subscribe(
            () => {},
            () => {},
            () => {
              this.showNextButton = false;
              if (this.options.pictureOnly) {
                this.leave();
              }
            }
          );
          break;
        case "perimeter":
          break;
      }
    }
  }
}
