<ion-list lines="full">
  <ion-item *ngIf="showChildrenInvestmentsToggle">
    <ion-label>{{
      "View investments of children asset" | translate
    }}</ion-label>
    <ion-toggle
      [(ngModel)]="showChildrenInvestments"
      (ngModelChange)="investmentDeleted()"
    >
    </ion-toggle>
  </ion-item>

  <div class="empty-value" *ngIf="!loading && investments.length === 0">
    <div class="no-results-icon">
      <i class="fal fa-folder-open fa-2x"></i>
    </div>
    <div>{{ "No investments" | translate }}</div>
  </div>

  <investment-item
    *ngFor="let investment of investments"
    [investment]="investment"
    (investmentClick)="showInvestment(investment)"
    (investmentDeleted)="investmentDeleted()"
    [showAttachedAsset]="false"
  >
  </investment-item>
  <div class="space"></div>
</ion-list>
