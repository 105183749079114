import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";
import * as IonicFile from "@ionic-native/file";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
  FileTransferError,
} from "@awesome-cordova-plugins/file-transfer/ngx";
import { Observable, from, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Environment } from "../app.environment";
import {
  Change,
  addAssetPictureAction,
  addPerimeterPictureAction,
  addAuditQuestionPictureAction,
} from "../structs/synchronization";
import { AuthService } from "./auth.service";
import { Picture, makePicture } from "../structs/base";
import { makeAssetPicture, makePerimeterPicture } from "../structs/assets";
import { makeInvestmentPicture } from "../structs/investments";

@Injectable()
export class PicturesService {
  private options: CameraOptions = {
    destinationType: this.camera.DestinationType.FILE_URI,
    targetWidth: 2048,
    targetHeight: 2048,
    correctOrientation: true,
  };
  /**
   * Keep browser File upload references, because change serialization breaks them.
   */
  private browserFiles: { [localId: string]: File } = {};

  constructor(
    private authService: AuthService,
    private camera: Camera,
    // private file: IonicFile,
    private httpClient: HttpClient,
    private transfer: FileTransfer
  ) {}

  public captureFromCamera(): Observable<string> {
    return this.capture(this.camera.PictureSourceType.CAMERA);
  }

  public captureFromLibrary(): Observable<string> {
    return this.capture(this.camera.PictureSourceType.PHOTOLIBRARY);
  }

  public upload(change: Change): Observable<Picture> {
    const { browserFile }: Picture = change.data;

    if (browserFile) {
      return this.browserUpload(change);
    } else {
      return this.nativeUpload(change);
    }
  }

  public getBrowserFile(localId: string): File {
    return this.browserFiles[localId];
  }

  public setBrowserFile(browserFile: File, localId: string): void {
    this.browserFiles[localId] = browserFile;
  }

  public nativeUpload(change: Change): Observable<Picture> {
    const { localPath, localId }: Picture = change.data;

    let asset: number = null,
      investment: number = null,
      perimeter: number = null,
      question_item: number = null;

    if (change.type === addAssetPictureAction) {
      asset = change.data.asset;
    } else if (change.type === addPerimeterPictureAction) {
      perimeter = change.data.perimeter;
    } else if (change.type === addAuditQuestionPictureAction) {
      question_item = change.data.questionItem;
      asset = change.data.asset;
    } else {
      investment = change.data.investment;
    }
    const fileName: string = localPath.substring(
      localPath.lastIndexOf("/") + 1
    );

    return from(this.authService.getAuthorizationString()).pipe(
      switchMap((token) => {
        const fileTransfer = this.transfer.create();
        const options: FileUploadOptions = {
          fileKey: "picture",
          fileName,
          params: {
            asset,
            investment,
            perimeter,
            question_item,
            local_id: localId,
          },
          httpMethod: change.method,
          headers: {
            Authorization: token,
          },
          chunkedMode: false,
        };

        return from(
          fileTransfer.upload(
            localPath,
            `${Environment.getBackendHost()}${change.url}`,
            options
          )
        ).pipe(
          map((result) => {
            if (asset) {
              return makeAssetPicture(JSON.parse(result.response));
            }

            if (investment) {
              return makeInvestmentPicture(JSON.parse(result.response));
            }

            if (perimeter) {
              return makePerimeterPicture(JSON.parse(result.response));
            }

            return makePicture(JSON.parse(result.response));
          })
        );
      })
    );
  }

  public browserUpload(change: Change): Observable<Picture> {
    const { localId }: Picture = change.data;

    let asset: number = null,
      investment: number = null,
      perimeter: number = null,
      question_item: number = null;

    if (change.type === addAssetPictureAction) {
      asset = change.data.asset;
    } else if (change.type === addPerimeterPictureAction) {
      perimeter = change.data.perimeter;
    } else if (change.type === addAuditQuestionPictureAction) {
      question_item = change.data.questionItem;
      asset = change.data.asset;
    } else {
      investment = change.data.investment;
    }
    const browserFile = this.getBrowserFile(localId);

    // We lost the browser file reference, discard it
    if (!browserFile) {
      return of(null);
    }

    return from(this.authService.getAuthorizationString()).pipe(
      switchMap((token) => {
        const formData = new FormData();
        formData.append("picture", browserFile, browserFile.name);
        if (asset) {
          formData.append("asset", asset.toString());
        } else if (investment) {
          formData.append("investment", investment.toString());
        } else if (perimeter) {
          formData.append("perimeter", perimeter.toString());
        }
        if (question_item) {
          formData.append("question_item", question_item.toString());
        }
        formData.append("local_id", localId);

        return this.httpClient
          .post<Picture>(
            `${Environment.getBackendHost()}${change.url}`,
            formData,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .pipe(
            map((result) => {
              if (asset) {
                return makeAssetPicture(result);
              }

              if (investment) {
                return makeInvestmentPicture(result);
              }

              if (perimeter) {
                return makePerimeterPicture(result);
              }

              return makePicture(result);
            })
          );
      })
    );
  }

  public isNetworkError(error: FileTransferError): boolean {
    return error.code === this.transfer.FileTransferErrorCode.CONNECTION_ERR;
  }

  private capture(source: number): Observable<string> {
    return from(
      this.camera.getPicture({
        ...this.options,
        sourceType: source,
      })
      // By default, the picture is stored in cache. Copy it into persistent storage.
    )
      .pipe(
        switchMap((cachedPath: string) => {
          // Resolve the path to get a truly native one
          return from(IonicFile.File.resolveLocalFilesystemUrl(cachedPath));
        })
      )
      .pipe(
        switchMap((fileEntry) => {
          const nativePath = fileEntry.nativeURL;
          const directoryPath = nativePath.substring(
            0,
            nativePath.lastIndexOf("/") + 1
          );
          const newName = `${new Date().getTime()}.jpg`;
          return from(
            IonicFile.File.moveFile(
              directoryPath,
              fileEntry.name,
              IonicFile.File.dataDirectory,
              newName
            )
          ).pipe(map(() => `${IonicFile.File.dataDirectory}${newName}`));
        })
      );
  }
}
