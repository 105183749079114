<legend-item
  [legend]="legend"
  (click)="onDocumentClick($event)"
  [showArrow]="true"
>
  <span *ngIf="syncInProgress">-</span>
  <ng-container *ngIf="!syncInProgress">
    <span *ngIf="documentCount > 0">{{ documentCount }}</span>
    <span *ngIf="documentCount === 0">-</span>
  </ng-container>
</legend-item>
