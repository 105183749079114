<ion-list lines="full">
  <ng-container *ngFor="let question of questions">
    <ng-container *ngIf="question.question_type === 'stars'">
      <ion-item>
        <stars-note
          [question]="question"
          (clicked)="noteUpdated($event)"
          [readOnly]="readOnly"
        ></stars-note>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="question.question_type === 'sliders'">
      <ion-item *ngIf="question.mnemonic !== 'technical_state'">
        <range-note
          [question]="question"
          (clicked)="noteUpdated($event)"
          [readOnly]="readOnly"
        ></range-note>
      </ion-item>
      <ng-container *ngIf="question.mnemonic === 'technical_state'">
        <ng-container *ngIf="!investment.hasNoImpact">
          <input-item
            class="technical-state-input"
            [lines]="'none'"
            [legend]="editableSliderLabel"
          >
            <technical-state-input
              [asset]="asset"
              [investment]="investment"
              [readOnly]="readOnly"
              (onNoteChanged)="noteUpdated($event)"
            ></technical-state-input>
          </input-item>
        </ng-container>

        <ng-container *ngIf="hasNote">
          <ion-row>
            <ion-col class="ion-no-padding" size="9">
              <input-item
                [lines]="'none'"
                [legend]="remainingYearsLabel"
                class="remaining-years"
              >
                <ion-input
                  type="number"
                  step="1"
                  min="1"
                  [(ngModel)]="remainingLifetimeAfterInvestment"
                  (keyup)="inputNumberCheck($event)"
                  (ngModelChange)="remainingLifetimeUpdated($event)"
                  placeholder="-"
                  [disabled]="readOnly"
                ></ion-input>
              </input-item>
            </ion-col>
            <ion-col class="ion-align-self-center ion-padding-end">
              <ion-note class="item-end ion-text-wrap" role="end">
                <ion-row>
                  {{ "Source" | translate }} :
                  <span class="source">{{ remainingLifetimeSource }}</span>
                </ion-row>
              </ion-note>
            </ion-col>
          </ion-row>
        </ng-container>

        <ion-item *ngIf="hasNote && pointlessInvestment">
          <span class="pointless-investment" text-wrap>{{
            pointlessInvestmentMessage
          }}</span>
        </ion-item>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="question.question_type === 'on-off'">
      <ion-item>
        <on-off-note
          [question]="question"
          (clicked)="noteUpdated($event)"
          [readOnly]="readOnly"
        ></on-off-note>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="question.question_type === 'yes-no-idk'">
      <yes-no-idk-note
        [question]="question"
        [asset]="asset"
        (clicked)="noteUpdated($event)"
        [readOnly]="readOnly"
      ></yes-no-idk-note>
    </ng-container>
  </ng-container>
</ion-list>
