import { Injectable } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { BackendService } from "./backend.service";
import { HistoryObj, makeHistoryObj } from "../structs/history";
import { AppUser } from "../structs/base";
import { OfflineService } from "./offline.service";
import { map } from "rxjs/operators";

export interface DocumentModification {
  user: AppUser;
  date: Date;
}

/**
 * Get and format history of a document
 */
@Injectable()
export class DocumentHistoryService {
  constructor(
    private backend: BackendService,
    private offlineService: OfflineService
  ) {}

  /**
   * Get the history of the document passed in parameter from backend
   *
   * @param docId
   */
  public getHistoryForDocument(docId: number): Observable<HistoryObj> {
    return new Observable((observer) => {
      this.backend.get(`/documents/api/documents/${docId}/history/`).subscribe(
        (jsonData) => {
          observer.next(makeHistoryObj(jsonData));
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Get the information (user-date) about the last modification
   *
   * @param docId the document's id
   */
  public getLastDocumentModificationInfo(
    docId: number
  ): Observable<DocumentModification> {
    return combineLatest(
      this.getHistoryForDocument(docId),
      this.offlineService.getConfig("users")
    ).pipe(
      map(([history, users]) => {
        return this.recoverFieldModificationFromHistory(history, users);
      })
    );
  }

  /**
   * Recover the information about the last modification to the document's history
   *
   * @param hist the document's history
   * @param users the list of users
   */
  private recoverFieldModificationFromHistory(
    hist: HistoryObj,
    users: AppUser[]
  ) {
    // We sort the history item by date
    const historyItems = hist.historyItems.sort(
      (item1, item2) => item2.date.getTime() - item1.date.getTime()
    );

    // and just throw back the first one
    let historyItem = historyItems[0];
    return {
      user: users.find((user) => user.get_user_id === historyItem.user),
      date: historyItem.date,
    };
  }
}
