import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import {
  IonicModule,
  IonicRouteStrategy,
  IonNav,
  NavParams,
} from "@ionic/angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthService } from "./services/auth.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ConfigService } from "./services/config.service";
import { BackendService } from "./services/backend.service";
import { ErrorsService } from "./services/errors.service";
import { OfflineService } from "./services/offline.service";
import { StorageService } from "./services/storage.service";
import { TitleService } from "./services/title.service";
import { LoadingStateService } from "./services/loading-state.service";
import { ScopeService } from "./services/scope.service";
import { IonicStorageModule } from "@ionic/storage-angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { I18nService } from "./services/i18n.service";
import { SynthesisService } from "./services/synthesis.service";
import { SynchronizationService } from "./services/synchronization.service";
import { PerimeterBudgetPlansService } from "./services/perimeter-budget-plan.service";
import { PerimeterPicturesService } from "./services/perimeter-pictures.service";
import { VersionsService } from "./services/versions.service";
import { TasksService } from "./services/tasks.service";
import { SearchService } from "./services/search.service";
import { AssetsService } from "./services/assets.service";
import { InvestmentsService } from "./services/investments.service";
import { SuccessToastService } from "./services/success-toast.service";
import { CurrencyPipeModule } from "./pipes/currency/currency.module";
import { CurrencyPipe } from "./pipes/currency/currency.pipe";
import { ImportStatusService } from "./services/import-status.service";
import { PicturesService } from "./services/pictures.service";
import { PicturesLoaderService } from "./services/pictures-loader.service";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { SettingsService } from "./services/settings.service";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { AssetPicturesService } from "./services/asset-pictures.service";
import { InvestmentPicturesService } from "./services/investment-pictures.service";
import { ComponentsModule } from "./components/components.module";
import { DatePipe, DecimalPipe } from "@angular/common";
import { FormBuilder, FormsModule } from "@angular/forms";
import {
  EventService,
  InitiativeService,
  SaveToLocalService,
} from "./services";
// import { Network } from "@ionic-native/network/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { AuditService } from "./services/audit.service";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { AssetEditService } from "./services/asset-edit.service";
import { PerimetersService } from "./services/perimeters.service";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";
import { Events } from "./services/events.service";
import { KPISubscriptionService } from "./services/kpisubscription.service";
import { ConsistencyTestService } from "./services/consistency-test.service";
import { UsersService } from "./services/users.service";
import { RoadmapChaptersService } from "./services/roadmap-chapters.service";
import { RoadmapSearchService } from "./services/roadmap-search.service";
import { FullTextSearchService } from "./services/full-text-search.service";
import { RoadmapService } from "./services/roadmap.service";
import { PerimetersFilterService } from "./services/perimeters-filter.service";
import { Drivers } from "@ionic/storage";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { DocumentsService } from "./services/documents.service";
import { ImportStatusBatchService } from "./services/import-status-batch.service";
import { ScanAndSearchService } from "./services/scanandsearch.service";
import { ComingSoonService } from "./services/coming-soon.service";
import { DefaultValueModule } from "./pipes/default-value/default-value.module";
import { AttachInvestmentsService } from "./services/attach-investment.service";
import { InvestmentHistoryService } from "./services/investment-history.service";
import { AssetNotationService } from "./services/asset-notation.service";
import { BrowserService } from "./services/browser.service";
import { InformationService } from "./services/information.service";
import { EmailComposer } from "@awesome-cordova-plugins/email-composer/ngx";
import { DocumentHistoryService } from "./services/document-history.service";
import { ExportsService } from "./services/exports.service";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import * as localforage from "localforage";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { LoggingService, LoggingServiceModule } from "ionic-logging-service";
import * as Sentry from "sentry-cordova";
import { Environment } from "./app.environment";
import { VERSION } from "./app.version";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SentryIonicErrorHandler } from "./sentry";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

Sentry.init({
  dsn: Environment.getSentryDSN(),
  environment: Environment.getName(),
  release: "audit@" + VERSION,
});

const getConfig = () => {
  return {
    swipeBackEnabled: false,
    backButtonText: "",
  };
};

export function configureLogging(loggingService: LoggingService): () => void {
  return () =>
    loggingService.configure({
      logLevels: [
        {
          loggerName: "root",
          logLevel: "DEBUG",
        },
      ],
      localStorageAppender: {
        localStorageKey: "kpi.logging.default",
        threshold: "INFO",
        maxMessages: 500,
      },
    });
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(getConfig()),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: "capexdb_v4",
      driverOrder: [
        CordovaSQLiteDriver._driver,
        localforage.WEBSQL,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
    TranslateModule.forRoot({
      defaultLanguage: "fr",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CurrencyPipeModule,
    ComponentsModule,
    DefaultValueModule,
    LoggingServiceModule,
    ScrollingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    HttpClient,
    ConfigService,
    BackendService,
    ErrorsService,
    OfflineService,
    StorageService,
    TitleService,
    LoadingStateService,
    ScopeService,
    I18nService,
    SynthesisService,
    SynchronizationService,
    PerimeterBudgetPlansService,
    PerimeterPicturesService,
    VersionsService,
    TasksService,
    SearchService,
    AssetsService,
    InvestmentsService,
    SuccessToastService,
    CurrencyPipe,
    ImportStatusService,
    PicturesService,
    PicturesLoaderService,
    Camera,
    FileTransfer,
    SettingsService,
    File,
    AssetPicturesService,
    InvestmentPicturesService,
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (i18nService) => i18nService.getLanguage(),
    },
    DecimalPipe,
    Events,
    EventService,
    Network,
    AuditService,
    DatePipe,
    BarcodeScanner,
    AssetEditService,
    PerimetersService,
    NavParams,
    Deeplinks,
    InitiativeService,
    KPISubscriptionService,
    UsersService,
    RoadmapChaptersService,
    RoadmapSearchService,
    FullTextSearchService,
    RoadmapService,
    ConsistencyTestService,
    UsersService,
    DocumentsService,
    PerimetersFilterService,
    ImportStatusService,
    ImportStatusBatchService,
    ScanAndSearchService,
    ComingSoonService,
    DocumentsService,
    IonNav,
    AttachInvestmentsService,
    InvestmentHistoryService,
    AssetNotationService,
    BrowserService,
    InformationService,
    EmailComposer,
    DocumentHistoryService,
    ExportsService,
    FileOpener,
    GooglePlus,
    FormBuilder,
    {
      deps: [LoggingService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: configureLogging,
    },
    SaveToLocalService,
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    InAppBrowser,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
