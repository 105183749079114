<ion-item-sliding #item *ngIf="!investment.deleting">
  <ion-item (click)="onItemClick($event)">
    <ion-label class="investment">
      <!--The following line is commented out, but should be removed cleanly-->
      <div *ngIf="false" class="picture">
        <app-picture
          [picture]="picture"
          (error)="reloadPicture()"
        ></app-picture>
      </div>

      <div class="info">
        <!--show the item label & the icon with its import status-->
        <div class="title">
          <span class="text-style1 color-primary text-overflow-ellipsis">{{
            investment.label || investment.investmentType.name
          }}</span>
          <import-status-badge [investment]="investment"></import-status-badge>
        </div>

        <!--show the budget & the current investment status-->
        <investment-item-budget class="text-style3" [investment]="investment">
        </investment-item-budget>

        <!--show the name of the asset it is attached to-->
        <div class="linked-asset-info">
          <div
            *ngIf="showAttachedAsset === true && asset"
            class="text-style3 is-linked"
          >
            <span>{{ "Attached to" | translate }} : {{ asset.label }}</span>
            <button
              class="paperclip"
              (click)="
                $event.preventDefault(); $event.stopPropagation(); showAsset()
              "
            >
              <i class="fal fa-paperclip"></i>
            </button>
          </div>

          <!--inform the user that the investment is not attached to an asset-->
          <div *ngIf="!asset" class="text-style3 not-linked">
            <span>{{ "Attached to no equipment" | translate }}</span>
          </div>
        </div>
      </div>
    </ion-label>
  </ion-item>
  <ion-item-options side="end" class="slide-buttons">
    <ion-item-option
      *ngIf="asset"
      color="primary"
      (click)="detachInvestment(item)"
    >
      <i class="fal fa-unlink"></i>
    </ion-item-option>
    <ion-item-option color="danger" (click)="delete(item)">
      <i class="fal fa-trash-alt"></i>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>

<!-- <investment-add-action-sheet
  #investmentAddActionSheet
></investment-add-action-sheet> -->
