import { Injectable } from "@angular/core";
import { Observable, empty, of } from "rxjs";
import {
  map,
  switchMap,
  tap,
  concatMap,
  expand,
  filter,
  toArray,
  catchError,
} from "rxjs/operators";

import { Environment } from "../app.environment";
import { Asset, AssetPicture, makeAssetPicture } from "../structs/assets";
import {
  addAssetPictureAction,
  deleteAssetPictureAction,
  makeChange,
} from "../structs/synchronization";
import { getLocalId } from "../structs/utils";
import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { PicturesService } from "./pictures.service";
import { PicturesLoaderService } from "./pictures-loader.service";
import { SynchronizationService } from "./synchronization.service";

/**
 * Structure of a paginated API response.
 */
interface Pagination<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

@Injectable()
export class AssetPicturesService {
  constructor(
    private backendService: BackendService,
    private offlineApi: OfflineService,
    private picturesLoaderService: PicturesLoaderService,
    private picturesService: PicturesService,
    private synchronizationService: SynchronizationService
  ) {}

  public createAssetPicture(
    asset: Asset,
    filePath?: string,
    browserFile?: File
  ): AssetPicture {
    return new AssetPicture(
      0,
      asset.id,
      null,
      null,
      null,
      "",
      getLocalId(),
      "",
      filePath,
      browserFile
    );
  }

  // I'm keeping the old version of this function here because I had to make changes
  // because of rxjs new syntax, but I'm not sure my changes will work

  // public addAssetPicture(assetPicture: AssetPicture, asset: Asset): Observable<Asset> {
  //   if (assetPicture.browserFile) {
  //     this.picturesService.setBrowserFile(assetPicture.browserFile, assetPicture.localId);
  //   }

  //   return this.synchronizationService.addChange(
  //     makeChange(
  //       addAssetPictureAction,
  //       '/assets/api/asset-pictures/',
  //       'POST',
  //       assetPicture,
  //       asset,
  //       assetPicture.localId,
  //     )
  //   // Add the new picture to the local asset...
  //   ).map(() => ({
  //     ...asset,
  //     pictures: [
  //       ...asset.pictures,
  //       // ...only if not already there
  //       ...asset.pictures.findIndex((picture) => picture.localId === assetPicture.localId) < 0 ? [assetPicture] : [],
  //     ],
  //   // Signal offline changes
  //   })).concatMap((updatedAsset) => this.synchronizationService.signalOfflineChanges().map(() => updatedAsset))
  //   // Save the updated asset in the offline cache
  //   .concatMap((updatedAsset) => this.offlineApi.storeAsset(updatedAsset))
  // }

  public addAssetPicture(
    assetPicture: AssetPicture,
    asset: Asset
  ): Observable<Asset> {
    if (assetPicture.browserFile) {
      this.picturesService.setBrowserFile(
        assetPicture.browserFile,
        assetPicture.localId
      );
    }

    return (
      this.synchronizationService
        .addChange(
          makeChange(
            addAssetPictureAction,
            "/assets/api/asset-pictures/",
            "POST",
            assetPicture,
            asset,
            assetPicture.localId
          )
          // Add the new picture to the local asset...
        )
        .pipe(
          map(() => ({
            ...asset,
            pictures: [
              ...asset.pictures,
              // ...only if not already there
              ...(asset.pictures.findIndex(
                (picture) => picture.localId === assetPicture.localId
              ) < 0
                ? [assetPicture]
                : []),
            ],
            // Signal offline changes
          }))
        )
        .pipe(
          concatMap((updatedAsset) =>
            this.synchronizationService
              .signalOfflineChanges()
              .pipe(map(() => updatedAsset))
          )
        )
        // Save the updated asset in the offline cache
        .pipe(
          concatMap((updatedAsset) => this.offlineApi.storeAsset(updatedAsset))
        )
    );
  }

  // I'm keeping the old version of this function here because I had to make changes
  // because of rxjs new syntax, but I'm not sure my changes will work

  // public deleteAssetPicture(assetPicture: AssetPicture, asset: Asset): Observable<Asset> {
  //   // Look for changes that are adding this picture
  //   return this.synchronizationService.getChanges(true)
  //     .map((changes) => changes.find(
  //       (change) => change.type === addAssetPictureAction && change.localId === assetPicture.localId),
  //     )
  //     .switchMap((change) => {
  //       // There is changes: remove them, so we don't need to add a delete change
  //       if (change) {
  //         return this.synchronizationService.removeChange(change);
  //       // There is no change. If it has an id., it means it has been synchronized so needs to be deleted
  //       } else if (assetPicture.id !== 0) {
  //         return this.synchronizationService.addChange(
  //           makeChange(
  //             deleteAssetPictureAction,
  //             `/assets/api/asset-pictures/${assetPicture.id}/`,
  //             'delete',
  //             {},
  //             asset,
  //             assetPicture.localId,
  //           )
  //         );
  //       // There is no change and the picture has no id. Do nothing.
  //       } else {
  //         return of(null);
  //       }
  //     // Remove the picture to the local asset
  //     }).map(() => {
  //       const updatedPictures = asset.pictures.filter((picture) => picture.localId !== assetPicture.localId);
  //       return {
  //         ...asset,
  //         pictures: updatedPictures,
  //       };
  //     // Signal offline changes
  //     }).concatMap((updatedAsset) => this.synchronizationService.signalOfflineChanges().map(() => updatedAsset))
  //     // Save the updated asset in the offline cache
  //     .concatMap((updatedAsset) => this.offlineApi.storeAsset(updatedAsset));
  // }

  public deleteAssetPicture(
    assetPicture: AssetPicture,
    asset: Asset
  ): Observable<Asset> {
    // Look for changes that are adding this picture
    return (
      this.synchronizationService
        .getChanges(true)
        .pipe(
          map((changes) =>
            changes.find(
              (change) =>
                change.type === addAssetPictureAction &&
                change.localId === assetPicture.localId
            )
          )
        )
        .pipe(
          switchMap((change) => {
            // There is changes: remove them, so we don't need to add a delete change
            if (change) {
              return this.synchronizationService.removeChange(change);
              // There is no change. If it has an id., it means it has been synchronized so needs to be deleted
            } else if (assetPicture.id !== 0) {
              return this.synchronizationService.addChange(
                makeChange(
                  deleteAssetPictureAction,
                  `/assets/api/asset-pictures/${assetPicture.id}/`,
                  "delete",
                  {},
                  asset,
                  assetPicture.localId
                )
              );
              // There is no change and the picture has no id. Do nothing.
            } else {
              return of(null);
            }
            // Remove the picture to the local asset
          })
        )
        .pipe(
          map(() => {
            const updatedPictures = asset.pictures.filter(
              (picture) => picture.localId !== assetPicture.localId
            );
            return {
              ...asset,
              pictures: updatedPictures,
            };
            // Signal offline changes
          })
        )
        .pipe(
          concatMap((updatedAsset) =>
            this.synchronizationService
              .signalOfflineChanges()
              .pipe(map(() => updatedAsset))
          )
        )
        // Save the updated asset in the offline cache
        .pipe(
          concatMap((updatedAsset) => this.offlineApi.storeAsset(updatedAsset))
        )
    );
  }

  /**
   * Refreshes the asset pictures from the API.
   * This way, we get again pictures URL with a fresh access token (which expires every 30 minutes).
   *
   * @param assetsIds The ids of the assets to refresh the pictures for.
   */
  public refreshAssetPictures(assetsIds: number[]): Observable<Asset[]> {
    const assetPicturesObservable: Observable<Pagination<AssetPicture>> =
      this.backendService.get("/assets/api/asset-pictures/", {
        assets: assetsIds.join(","),
        size: 500,
      });

    // Call the API recursively to get all pages of asset pictures
    return (
      assetPicturesObservable
        .pipe(
          expand(({ next }) => {
            if (next) {
              const nextURL = next.replace(Environment.getBackendHost(), "");
              return <Observable<Pagination<AssetPicture>>>(
                this.backendService.get(nextURL)
              );
            } else {
              return empty();
            }
          })
        )
        .pipe(concatMap(({ results }) => results))
        // Retrieve the offline asset and update its pictures
        .pipe(
          concatMap((assetPicture) =>
            this.offlineApi
              .getAsset(assetPicture.asset)
              .pipe(filter((asset) => asset !== null))
              .pipe(
                map((asset) => {
                  const assetPictureObject = makeAssetPicture(assetPicture);
                  const assetPictureIndex = asset.pictures.findIndex(
                    (picture) =>
                      picture.id === assetPicture.id ||
                      picture.localId === assetPictureObject.localId
                  );
                  if (assetPictureIndex > -1) {
                    asset.pictures[assetPictureIndex] = assetPictureObject;
                  } else {
                    asset.pictures.push(assetPictureObject);
                  }
                  return asset;
                })
              )
              // Save the updated asset in offline cache
              .pipe(
                concatMap((updatedAsset) =>
                  this.offlineApi.storeAsset(updatedAsset)
                )
              )
          )
        )
        .pipe(toArray())
        .pipe(catchError(() => []))
    );
  }
}
