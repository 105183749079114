import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@ionic/angular";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { SettingsService } from "./settings.service";
import { SynchronizationService } from "./synchronization.service";
import { VersionsService } from "./versions.service";
import { BrowserService } from "./browser.service";

@Injectable()
export class InformationService {
  constructor(
    private auth: AuthService,
    private platform: Platform,
    private settingsService: SettingsService,
    private synchronizationService: SynchronizationService,
    private translate: TranslateService,
    private versionsService: VersionsService,
    private browserService: BrowserService
  ) {}

  public recoverApplicationDetails(): Observable<string> {
    const inBrowser = this.browserService.inBrowser();
    return combineLatest(
      this.translate.get(
        "Please describe the issue in as much detail as possible"
      ),
      this.translate.get("Technical details, please don't modify them"),
      this.auth.getCurrentUser(),
      this.settingsService.getAppSettings(),
      this.formatChanges(inBrowser)
    ).pipe(
      map(([description, technical, user, appSettings, changes]) => {
        let applicationDetails = "";
        if (inBrowser) {
          applicationDetails = `
${description}








------------------------------ ${technical}
- User details: ${user.getFullName()}: ${user.email}
- Device details: ${this.versionsService.getPlatform()}
- Current version: ${appSettings.version}
- Latest available version: ${appSettings.lastRelease}
- Online: ${appSettings.online}
- Offline storage size: ${appSettings.offlineStorageSize}
- Free Disk Space: ${appSettings.freeDiskSpace}
- Connected to database: ${appSettings.backendHost}
- Synchronization enabled: ${appSettings.synchroEnabled}
- Most recent Response Time: ${appSettings.lastHelloDelay}
- Storage type: ${appSettings.storageType}
${changes}
`;
        } else {
          applicationDetails = `
              <p><i>${description}</i></p>
              <br><br><br><br><br><br>
              <p>------------------------------ ${technical}</p>
              <ul>
                <li>User details: ${user.getFullName()}: ${user.email}</li>
                <li>Device details: ${this.versionsService.getPlatform()}</li>
                <li>Current version: ${appSettings.version}</li>
                <li>Latest available version: ${appSettings.lastRelease}</li>
                <li>Online: ${appSettings.online}</li>
                <li>Offline storage size: ${appSettings.offlineStorageSize}</li>
                <li>Free Disk Space: ${appSettings.freeDiskSpace}</li>
                <li>Connected to database: ${appSettings.backendHost}</li>
                <li>Synchronization enabled: ${appSettings.synchroEnabled}</li>
                <li>Most recent Response Time: ${
                  appSettings.lastHelloDelay
                }</li>
                <li>Storage type: ${appSettings.storageType}</li>
              </ul>
              ${changes}
            `;
        }
        return applicationDetails;
      })
    );
  }

  private formatChanges(inBrowser: boolean): Observable<string> {
    return combineLatest(
      this.translate.get("Changes to synchronise"),
      this.synchronizationService.getChanges()
    ).pipe(
      map(([changeStr, changes]) => {
        let formatedChanges = "";
        if (changes) {
          if (inBrowser) {
            formatedChanges += `
------------------------------
${changeStr} (${changes.length}):
          `;
            changes.forEach((change) => {
              let assetChange = "";
              if (change.asset) {
                assetChange = `- Asset: ${change.asset.assetType.name} - ${
                  change.asset.label
                } - ${change.asset.id}
- AssetOfflineId: ${change.assetOfflineId}
- Building: ${JSON.stringify(change.asset.building)}
              `;
              }
              formatedChanges += `

- Timestamp: ${change.timestamp}
- Method: ${change.method}
- Type: ${change.type}
- Url: ${change.url}
- Data: ${JSON.stringify(change.data)}
- LocalId: ${change.localId}
${assetChange}
-------------------
`;
            });
          } else {
            formatedChanges += `
            <p>------------------------------</p>
            <h2>${changeStr} (${changes.length}): <h2>
            <ul>
          `;
            changes.forEach((change) => {
              let assetChange = "";
              if (change.asset) {
                assetChange = `
                <li>Asset: ${change.asset.assetType.name} - ${
                  change.asset.label
                } - ${change.asset.id}</li>
                <li>AssetOfflineId: ${change.assetOfflineId}</li>
                <li>Building: ${JSON.stringify(change.asset.building)}</li>
              `;
              }
              formatedChanges += `
                <li>
                 <ul>
                    <li>Timestamp: ${change.timestamp}</li>
                    <li>Method: ${change.method}</li>
                    <li>Type: ${change.type}</li>
                    <li>Url: ${change.url}</li>
                    <li>Data: ${JSON.stringify(change.data)}</li>
                    <li>LocalId: ${change.localId}</li>
                    ${assetChange}
                  </ul>
                </li>
                <li>-------------------</li>
            `;
            });

            formatedChanges += "</ul>";
          }
        }
        return formatedChanges;
      })
    );
  }
}
