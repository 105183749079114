import * as R from "ramda";

import { Asset } from "./assets";
import { AppUser } from "./base";
import { Investment } from "./investments";

/**
 * State of a `Task`.
 */
export enum TaskState {
  STATE_PENDING,
  STATE_IN_PROGRESS,
  STATE_DONE,
  STATE_CANCELLED,
}

/**
 * Object associating a `TaskState` with its display value.
 */
export interface TaskStateDisplay {
  id: TaskState;
  name: string;
}

/**
 * Due task for a `User`.
 */
export interface Task {
  id: number;
  /** Local id. used for offline synchronization. */
  local_id?: string;
  label: string;
  description?: string;
  created_by: number;
  /** Id. of the assigned `User`. */
  assigned: number;
  state: TaskState;
  done: boolean;
  deadline: string;
  /** Id. of the related `Perimeter`. */
  perimeter?: number;
  /** Id. of the related `Investment`. */
  asset?: number;
  /** Id. of the related `Investment`. */
  investment?: number;
}

/**
 * `Task` object with related objects attached.
 */
export interface MappedTask extends Task {
  created_by_object: AppUser;
  assigned_object: AppUser;
  state_object: TaskStateDisplay;
  deadline_date: Date;
  asset_object?: Asset;
  investment_object?: Investment;
}

/**
 * Splits a list of tasks into two lists:
 *   * The first list contains the tasks that were assigned to me by other people
 *   * The second list contains the tasks that were created by me, for me ("reminders")
 */
export const splitAssignedPrivateTasks = (tasks: Task[]): [Task[], Task[]] => {
  const assignedTasks = [];
  const privateTasks = [];

  for (const task of tasks) {
    if (task.assigned !== task.created_by) {
      assignedTasks.push(task);
    } else {
      privateTasks.push(task);
    }
  }

  return [assignedTasks, privateTasks];
};

/**
 * Returns `true` if the task is late.
 */
export const isLate = (task: Task): boolean =>
  new Date() > new Date(task.deadline);

/**
 * Returns `true` if the task is done.
 */
export const isDone = (task: Task): boolean =>
  task.state > TaskState.STATE_IN_PROGRESS;

/**
 * Returns `true` if the task is undone.
 */
export const isNotDone = (task: Task): boolean => R.complement(isDone)(task);

/**
 * Returns the related object of the task (investment, asset...).
 */
export const getRelatedObject = (
  mappedTask: MappedTask
): Asset | Investment => {
  if (mappedTask.asset_object) {
    return mappedTask.asset_object;
  } else if (mappedTask.investment_object) {
    return mappedTask.investment_object;
  }
};

export const taskAddType = {
  type: "task",
  label: "Task",
};
