import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavigationExtras } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Picture } from "@structs";
import { Index } from "lunr";
import { PicturesViewerComponent } from "src/app/pages/pictures-viewer/pictures-viewer.component";
import {
  ChaptersGroup,
  RoadmapChaptersService,
} from "../../services/roadmap-chapters.service";
import { Perimeter, Zone } from "../../structs/assets";
import { AccessLevel, Roadmap, Theme } from "../../structs/roadmap";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "roadmap-list-item",
  templateUrl: "./roadmap-list-item.component.html",
})
export class RoadmapListItemComponent implements OnInit {
  @Input() public chaptersGroup: ChaptersGroup<
    Perimeter | Theme | Zone | AccessLevel
  > = null;
  @Input() public perimeter: Perimeter = null;
  @Input() public showPicture: boolean = true;
  @Input() public roadmap: Roadmap = null;
  @Input() public query: string = "";
  @Input() public forceLabel: string = "";
  @Input() public subTitle: string = "";
  @Input() public index: number = 0;
  @Input() public itemOptions = null;
  @Input() public groupName;
  @Input() public perimeterPrefix: string;
  @Input() public padding: string = "";

  @Input() public showChevronButtons: boolean = false;
  @Input() public hasChildren: boolean = false;
  @Input() public isCollapsed: boolean = false;
  @Output() public toggleCollapse = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onItemClicked = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onPictureClicked = new EventEmitter<any>();

  public itemLabel: string = "";
  public pictureEmpty: Picture = new Picture(
    -1,
    null,
    null,
    null,
    null,
    null,
    null
  );

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController
  ) {}

  public ngOnInit(): void {
    if (this.forceLabel) {
      this.itemLabel = this.forceLabel;
    } else {
      this.itemLabel = this.chaptersGroup.group.name;
    }
  }

  public openChaptersGroup(
    chaptersGroup: ChaptersGroup<Perimeter | Theme | Zone>
  ): void {
    let title: string;
    const chapterName = this.perimeterPrefix + " " + chaptersGroup.group.name;
    if (this.groupName) {
      title = this.groupName + " > " + chapterName;
    } else {
      title = chapterName;
    }
    let params: any = {
      chaptersGroup: chaptersGroup,
      titleTheme: title,
      roadmap: this.roadmap,
      perimeter: RoadmapChaptersService.isPerimeterChaptersGroup(chaptersGroup)
        ? chaptersGroup.group
        : this.perimeter,
      multiPerimeter: this.perimeter,
      chapters: chaptersGroup.chapters,
      previousQuery: this.query,
    };
    this.onItemClicked.emit(params);
  }

  public toggleMultiPerimeterChildren(): void {
    this.toggleCollapse.emit();
  }
}
