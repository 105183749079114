import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  makeChange,
  addPerimeterPictureAction,
  deletePerimeterPictureAction,
} from "../structs/synchronization";
import { getLocalId } from "../structs/utils";
import { PicturesService } from "./pictures.service";
import { SynchronizationService } from "./synchronization.service";
import { Perimeter, PerimeterPicture } from "../structs/assets";
import { OfflineService } from "./offline.service";
import { ScopeService } from "./scope.service";

@Injectable()
export class PerimeterPicturesService {
  constructor(
    private picturesService: PicturesService,
    private synchronizationService: SynchronizationService,
    private offline: OfflineService,
    private scope: ScopeService
  ) {}

  public createPerimeterPicture(
    perimeter?: Perimeter,
    filePath?: string,
    browserFile?: File
  ): PerimeterPicture {
    return new PerimeterPicture(
      0,
      perimeter?.id,
      null,
      null,
      null,
      "",
      getLocalId(),
      "",
      filePath,
      browserFile
    );
  }

  public addPerimeterPicture(
    perimeterPicture: PerimeterPicture,
    perimeter: Perimeter
  ): Observable<Perimeter> {
    if (perimeterPicture.browserFile) {
      this.picturesService.setBrowserFile(
        perimeterPicture.browserFile,
        perimeterPicture.localId
      );
    }

    return new Observable((observer) => {
      this.scope.getCurrentMultiPerimeter().subscribe(
        (currentPerimeter: Perimeter) => {
          let pictures = [
            ...perimeter.pictures,
            // ...only if not already there
            ...(perimeter.pictures.findIndex(
              (picture) => picture.localId === perimeterPicture.localId
            ) < 0
              ? [perimeterPicture]
              : []),
          ];
          if (!perimeter.is_monosite) {
            currentPerimeter.pictures = pictures;
          } else {
            currentPerimeter.sub_perimeters =
              currentPerimeter.sub_perimeters.map((subP: Perimeter) =>
                subP.id == perimeter.id ||
                (subP.localId && subP.localId == perimeter.localId)
                  ? { ...subP, pictures: pictures }
                  : subP
              );
          }
          this.scope.setCurrentMultiPerimeter(currentPerimeter).subscribe(
            () => {
              this.synchronizationService
                .addChange(
                  makeChange(
                    addPerimeterPictureAction,
                    "/structures/api/perimeter-pictures/",
                    "POST",
                    perimeterPicture,
                    undefined,
                    undefined,
                    undefined,
                    perimeter.localId
                  )
                )
                .subscribe(
                  () => {
                    this.synchronizationService
                      .pushOfflineChanges(true)
                      .subscribe(
                        () => {
                          observer.next(currentPerimeter);
                          observer.complete();
                        },
                        (err) => {
                          observer.error(err);
                          observer.complete();
                        }
                      );
                  },
                  (err) => {
                    observer.error(err);
                    observer.complete();
                  }
                );
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  public deletePerimeterPicture(
    perimeterPicture: PerimeterPicture,
    perimeter: Perimeter
  ): Observable<Perimeter> {
    return new Observable((observer) => {
      const updatedPictures = perimeter.pictures.filter((picture) => {
        return !(
          (picture.localId && picture.localId === perimeterPicture.localId) ||
          (picture.id > 0 && picture.id === perimeterPicture.id)
        );
      });
      this.scope.getCurrentMultiPerimeter().subscribe(
        (currentPerimeter: Perimeter) => {
          if (!perimeter.is_monosite) {
            currentPerimeter.pictures = updatedPictures;
          } else {
            currentPerimeter.sub_perimeters =
              currentPerimeter.sub_perimeters.map((subP: Perimeter) =>
                subP.id == perimeter.id ||
                (subP.localId && subP.localId == perimeter.localId)
                  ? { ...subP, pictures: updatedPictures }
                  : subP
              );
          }

          this.scope.setCurrentMultiPerimeter(currentPerimeter).subscribe(
            () => {
              let url = "";
              if (perimeterPicture.id > 0) {
                url = `/structures/api/perimeter-pictures/${perimeterPicture.id}/`;
              } else {
                url = `/structures/api/perimeter-pictures/delete-local/${perimeterPicture.localId}/`;
              }
              this.synchronizationService
                .addChange(
                  makeChange(deletePerimeterPictureAction, url, "delete", {})
                )
                .subscribe(
                  () => {
                    this.synchronizationService
                      .pushOfflineChanges(true)
                      .subscribe(
                        () => {
                          observer.next(currentPerimeter);
                          observer.complete();
                        },
                        (err) => {
                          observer.error(err);
                          observer.complete();
                        }
                      );
                  },
                  (err) => {
                    observer.error(err);
                    observer.complete();
                  }
                );
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}
