<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="backClicked()">
        <i class="far fa-arrow-left"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ getTitle() }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list
    lines="full"
    *ngIf="!showInvestments"
    lines="none"
    class="ion-padding-left"
  >
    <p class="text-style1">{{ "You want to attach" | translate }} :</p>
    <ion-item>
      <ion-checkbox
        color="investment"
        (ionChange)="showInvestmentsList()"
        slot="start"
      ></ion-checkbox>
      <ion-label>{{ "An existing investment" | translate }}</ion-label>
    </ion-item>
    <ion-item (click)="dismiss(true)">
      <ion-checkbox
        color="investment"
        (ionChange)="dismiss(true)"
        slot="start"
      ></ion-checkbox>
      <ion-label>{{ "A new investment" | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list lines="full" *ngIf="showInvestments">
    <loader *ngIf="loading"></loader>

    <investment-item
      *ngFor="let investment of investments"
      [investment]="investment"
      (investmentClick)="attachInvestment(investment)"
    >
    </investment-item>
  </ion-list>

  <div
    class="empty-value"
    *ngIf="showInvestments && !loading && investments.length === 0"
  >
    <div class="no-results-icon">
      <i class="fal fa-tools fa-3x"></i>
    </div>
    <div>{{ "No investments" | translate }}</div>
  </div>

  <!-- Investment add button. -->
  <ion-fab
    *ngIf="showInvestments && !loading && investments.length === 0"
    vertical="center"
    horizontal="end"
  >
    <ion-fab-button (click)="dismiss(true)" color="investment">
      <i class="fal fa-2x fa-plus"></i>
    </ion-fab-button>
  </ion-fab>
</ion-content>
