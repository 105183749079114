import { Component, Input, OnInit } from "@angular/core";
import { Navigation, Router } from "@angular/router";
import { NavController } from "@ionic/angular";

/**
 * This component is a solution to the loss of parameters after
 * refreshing a page. It provides automatic redirection on refresh
 * and a navigateBack() function when clicking on the backbutton
 * when there is no previous navigation.
 *
 * Beware, some pages require that the redirection is made quickly,
 * in the constructor. But we can't read the inputs in the constructor,
 * so the component will be useless in that case.
 */
@Component({
  selector: "app-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit {
  @Input() defaultRedirection: string = "perimeter";
  @Input() redirectOnRefresh: boolean = false;
  private previousPage: Navigation = null;

  constructor(private router: Router, private navCtrl: NavController) {}

  ngOnInit() {
    this.previousPage = this.router.getCurrentNavigation().previousNavigation;
    if (!this.previousPage && this.redirectOnRefresh) {
      this.navCtrl.navigateBack(this.defaultRedirection, { animated: false });
    }
  }

  public backButtonClicked() {
    if (this.previousPage) {
      this.navCtrl.pop();
    } else {
      this.navCtrl.navigateBack(this.defaultRedirection, { animated: false });
    }
  }
}
