import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IonItemSliding } from "@ionic/angular";
import { DocumentsService } from "../../services/documents.service";
import { Document, DocumentType } from "../../structs/documents";
import { ErrorsService } from "../../services/errors.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "document-item",
  templateUrl: "./document-item.component.html",
  styleUrls: ["./document-item.component.scss"],
})
export class DocumentItemComponent {
  @Input() public document: Document;
  @Input() public docType: DocumentType;
  @Input() public legend: string;
  @Input() public title: string;
  @Input() public perimeterId: number;
  @Input() public investmentId: number;
  @Input() public assetId: number;
  @Output() public documentClick = new EventEmitter<{
    doc: Document;
    docType: DocumentType;
  }>();
  @Output() public documentDeleted = new EventEmitter<void>();

  constructor(
    private documentService: DocumentsService,
    private errors: ErrorsService
  ) {}

  public onDocumentClick(event: Event): void {
    event.stopPropagation();
    this.documentClick.emit({ doc: this.document, docType: this.docType });
  }

  public delete(item: IonItemSliding): void {
    this.documentService
      .deleteDocument(
        this.docType.id,
        this.document,
        this.perimeterId,
        this.investmentId,
        this.assetId
      )
      .subscribe(
        () => {
          item.close();
          this.documentDeleted.emit();
        },
        (err) => {
          item.close();
          this.errors.signalError(err);
        }
      );
  }
}
