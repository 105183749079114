<div class="text-style1 color-primary budget-plan">
  <span class="empty-value" *ngIf="slices.length === 0">-</span>

  <!-- Display the status & the budget origin -->

  <span *ngIf="showStatus === true && slices.length !== 0">
    {{ "Status" | translate }} : {{ investment.status.name }}
    <i
      *ngIf="investment.status.hypothesis"
      class="fal fa-question-circle text-style2"
    ></i>
  </span>

  <div *ngFor="let slice of slices; index as i">
    <ng-container *ngIf="slice.price + (slice.additionalPrice || 0) !== 0">
      {{ slice.price + (slice.additionalPrice || 0) | currency }}

      <ng-container *ngIf="slice.additionalPrice">
        ({{ slice.price | currency }} +
        {{ slice.additionalPrice | currency }})</ng-container
      >

      <span>{{ "in" | translate }} {{ slice.year }}</span>
      <span
        *ngIf="
          i === 0 && investment.investmentBudgetOrigin && showOrigin === true
        "
      >
        ({{ investment.investmentBudgetOrigin.name }})</span
      >

      <ng-container *ngIf="slice.isExtraWork">
        <span class="extraWork"
          >({{ "Extra work" | translate }})</span
        ></ng-container
      >
    </ng-container>
  </div>
</div>
