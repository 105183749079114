<ion-list lines="full">
  <ng-container *ngFor="let question of questions">
    <ng-container *ngIf="question.question_type === 'stars'">
      <ion-item>
        <stars-note
          [question]="question"
          (clicked)="noteUpdated($event)"
          [readOnly]="readOnly"
        ></stars-note>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="question.question_type === 'sliders'">
      <range-note
        [question]="question"
        (clicked)="noteUpdated($event)"
        [readOnly]="readOnly"
      ></range-note>
    </ng-container>

    <ng-container *ngIf="question.question_type === 'on-off'">
      <ion-item>
        <on-off-note
          [question]="question"
          (clicked)="noteUpdated($event)"
          [readOnly]="readOnly"
        ></on-off-note>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="question.question_type === 'yes-no-idk'">
      <yes-no-idk-note
        [question]="question"
        [asset]="asset"
        (clicked)="noteUpdated($event)"
        [readOnly]="readOnly"
        [ngClass]="{ 'active-background': highlighted[question.items[0]?.id] }"
      >
      </yes-no-idk-note>
    </ng-container>
  </ng-container>
</ion-list>
