<ion-list lines="full">
  <ng-container>
    <ion-item margin-bottom no-lines>
      <ion-label>{{ "Activate expert mode" | translate }}</ion-label>
      <ion-toggle
        [disabled]="readOnly"
        [(ngModel)]="isExpertModeOn"
        (ngModelChange)="onExpertModeChanged()"
        slot="end"
      ></ion-toggle>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="isExpertModeOn">
    <ng-container
      *ngFor="let section of questionSections; index as sectionIndex"
    >
      <ion-list lines="full">
        <ion-item-divider
          color="light-gray"
          (click)="visibilityToggle(sectionIndex)"
          [ngClass]="
            isVisible[sectionIndex] ? 'unfolded-section' : 'folded-section'
          "
        >
          <ion-label class="ion-text-center" style="width: 90%">
            <span *ngIf="isVisible[sectionIndex]"
              >(<i class="fal fa-minus"></i>)</span
            >
            <span *ngIf="!isVisible[sectionIndex]"
              >(<i class="fal fa-plus"></i>)</span
            >
            {{ section.name }}
            <span [hidden]="!defects[sectionIndex]" class="danger-class">
              ({{ defects[sectionIndex] }})
            </span>
          </ion-label>
        </ion-item-divider>
        <kpi-notations
          [asset]="asset"
          [questions]="section.expertKpis"
          (changed)="onNotationsChanged($event)"
          [hidden]="!isVisible[sectionIndex]"
          (defectsCount)="updateTotalDefects($any($event), sectionIndex)"
        ></kpi-notations>
      </ion-list>
    </ng-container>
  </ng-container>
</ion-list>
<div class="bottom-spacer"></div>
