import { Injectable } from "@angular/core";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileEntry } from "@awesome-cordova-plugins/file/ngx";
import { saveAs } from "file-saver";

@Injectable()
export class SaveToLocalService {
  // Ionic platform is not reliable detecting browser/android/io
  platform: string;

  constructor(private file: File) {
    try {
      // @ts-ignore
      this.platform = device.platform;
    } catch (error) {
      this.platform = null;
    }
  }

  /**
   * Save file to Download directory. Support Android, iOS and use fallback `saveAs` method for browser
   * @param filename
   * @param content
   */
  saveToDownload(
    filename: string,
    content: string | Blob | ArrayBuffer
  ): Promise<FileEntry | null> {
    let storeLocation = "";
    const options = { replace: true, append: false };
    switch (this.platform) {
      case "Android":
        storeLocation = "file:///storage/emulated/0/Download";
        return this.file.writeFile(storeLocation, filename, content, options);
      case "iOS":
        storeLocation = this.file.documentsDirectory;
        return this.file.writeFile(storeLocation, filename, content, options);
      default:
        saveAs(content, filename);
        return Promise.resolve(null);
    }
  }
}
