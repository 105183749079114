import { Investment, InvestmentType } from "./investments";
import { AssetType, Asset } from "./assets";

export class DocumentType {
  constructor(public id: number, public label: string, public order: number) {}
}

export function makeDocumentType(jsonData: any): DocumentType {
  return new DocumentType(jsonData.id, jsonData.label, jsonData.order);
}

// Context: Document
export class PerimeterDocument {
  constructor(public id: number, public name: string) {}
}

export class DocumentRequirement {
  constructor(
    public perimeter: PerimeterDocument,
    public investment: Investment,
    public investmentType: InvestmentType,
    public asset: Asset,
    public assetType: AssetType,
    public document_type: DocumentType,
    public mandatory: boolean,
    public documents: Array<Document>,
    public suggestAutomaticReminder: boolean
  ) {}
}

export class Document {
  constructor(
    public id: number,
    public title: string,
    public created_on: any,
    public created_by: string,
    public link: string,
    public equipment: string,
    public comments: string,
    public expiry_date: Date = null,
    // document_type is most of time null
    // some apis (roadmap item) may need it. IN this case, it will be initialized
    public document_type: DocumentType | null = null
  ) {}
}

export function makeDocument(jsonData: any): Document {
  return new Document(
    jsonData.id,
    jsonData.title,
    jsonData.created_on,
    jsonData.created_by,
    jsonData.link,
    jsonData.equipment,
    jsonData.comments,
    jsonData.expiry_date,
    jsonData.document_type ? makeDocumentType(jsonData.document_type) : null
  );
}

export class DocumentRequirementCount {
  constructor(public documentTypeId: number, public documentCount: number) {}
}

abstract class DocumentSummary {
  public documentPercent: number = 0;

  constructor(
    public documentCount: number,
    public mandatoryCount: number,
    public mandatoryCompletedCount: number
  ) {
    if (this.mandatoryCount) {
      this.documentPercent =
        (this.mandatoryCompletedCount / this.mandatoryCount) * 100;
    } else {
      this.documentPercent = 100;
    }
  }
}

export class DocumentInvestmentSummary extends DocumentSummary {
  constructor(
    public investmentId: number,
    public investmentLabel: string,
    public documentCount: number,
    public mandatoryCount: number,
    public mandatoryCompletedCount: number,
    public documentRequirementCounts: DocumentRequirementCount[]
  ) {
    super(documentCount, mandatoryCount, mandatoryCompletedCount);
  }
}

export class DocumentAssetSummary extends DocumentSummary {
  constructor(
    public assetId: number,
    public assetLabel: string,
    public documentCount: number,
    public mandatoryCount: number,
    public mandatoryCompletedCount: number
  ) {
    super(documentCount, mandatoryCount, mandatoryCompletedCount);
  }
}

export class DocumentPerimeterSummary extends DocumentSummary {
  constructor(
    public perimeterId: number,
    public perimeterName: string,
    public documentCount: number,
    public mandatoryCount: number,
    public mandatoryCompletedCount: number
  ) {
    super(documentCount, mandatoryCount, mandatoryCompletedCount);
  }
}

export class DocumentMultiPerimeterSummary extends DocumentSummary {
  constructor(
    public documentTypeId: number,
    public documentTypeName: string,
    public documentCount: number,
    public mandatoryCount: number,
    public mandatoryCompletedCount: number
  ) {
    super(documentCount, mandatoryCount, mandatoryCompletedCount);
  }
}

export function makeDocumentRequirement(jsonData: any): DocumentRequirement {
  var docType = jsonData.document_type;
  var documents = jsonData.documents;
  var mandatory = jsonData.mandatory;
  var perimeter = jsonData.perimeter;
  var investment = jsonData.investment;
  var investmentType = jsonData.investmentType;
  var asset = jsonData.asset;
  var assetType = jsonData.assetType;
  var suggestAutomaticReminder = jsonData.suggest_automatic_reminder;

  return new DocumentRequirement(
    perimeter,
    investment,
    investmentType,
    asset,
    assetType,
    docType,
    mandatory,
    documents,
    suggestAutomaticReminder
  );
}

export function makeDocumentRequirementCount(
  jsonData: any
): DocumentRequirementCount {
  return new DocumentRequirementCount(
    jsonData.documentTypeId,
    jsonData.documentCount
  );
}

export function makeDocumentInvestmentSummary(
  jsonData: any
): DocumentInvestmentSummary {
  let requirementCounts: Array<DocumentRequirementCount> = [];
  if (jsonData.documentRequirementCounts) {
    for (let i = 0; i < jsonData.documentRequirementCounts.length; i++) {
      requirementCounts.push(
        makeDocumentRequirementCount(jsonData.documentRequirementCounts[i])
      );
    }
  }

  return new DocumentInvestmentSummary(
    jsonData.investmentId,
    jsonData.investmentLabel,
    jsonData.documentCount,
    jsonData.mandatoryCount,
    jsonData.mandatoryCompletedCount,
    requirementCounts
  );
}

export function makeDocumentAssetSummary(jsonData: any): DocumentAssetSummary {
  return new DocumentAssetSummary(
    jsonData.assetId,
    jsonData.assetLabel,
    jsonData.documentCount,
    jsonData.mandatoryCount,
    jsonData.mandatoryCompletedCount
  );
}

export function makeDocumentPerimeterSummary(
  jsonData: any
): DocumentPerimeterSummary {
  return new DocumentPerimeterSummary(
    jsonData.perimeterId,
    jsonData.perimeterName,
    jsonData.documentCount,
    jsonData.mandatoryCount,
    jsonData.mandatoryCompletedCount
  );
}

export function makeDocumentMultiPerimeterSummary(
  jsonData: any
): DocumentMultiPerimeterSummary {
  return new DocumentMultiPerimeterSummary(
    jsonData.documentTypeId,
    jsonData.documentTypeName,
    jsonData.documentCount,
    jsonData.mandatoryCount,
    jsonData.mandatoryCompletedCount
  );
}
