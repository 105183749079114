import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

import { BackendService } from "./backend.service";
import { ErrorsService } from "./errors.service";
import { I18nService } from "./i18n.service";
import { OfflineService } from "./offline.service";

@Injectable()
export class ConfigService {
  constructor(
    private backend: BackendService,
    private errors: ErrorsService,
    private i18n: I18nService,
    private offlineApi: OfflineService,
    private translate: TranslateService
  ) {}

  public loadConfig(): Observable<void> {
    return new Observable((observer) => {
      console.info("CONFIG_LOAD_CONFIG");
      this.getFullConfig().subscribe(
        // getFullConfig success
        (fullConfig) => {
          console.info("CONFIG_LOAD_CONFIG_LOADED_FROM_SERVER");
          this.i18n.setCurrency(fullConfig.currency);

          this.offlineApi.storeConfig(fullConfig).subscribe(
            () => {
              observer.next();
              observer.complete();
            },
            () => {
              this.translate
                .get("Unable to save configuration data due to a network error")
                .subscribe((text) => this.errors.signalError(text));
            }
          );
        },
        // getFullConfig error
        () => {
          // Check that we have a config stored offline
          this.offlineApi.getConfigNoRefresh("zones", true).subscribe(
            () => {
              console.info("CONFIG_LOAD_CONFIG_LOADED_FROM_STORAGE");
              this.translate
                .get(
                  "Unable to refresh configuration data due to a network error"
                )
                .subscribe((text) => this.errors.signalError(text, true));
              observer.next();
              observer.complete();
            },
            (err) => {
              console.info("CONFIG_LOAD_CONFIG_CANNOT_LOAD", { error: err });
              // Error no config : inform user
              this.translate
                .get("Unable to load configuration data due to a network error")
                .subscribe((text) => this.errors.signalError(text));
              observer.error(err);
            }
          );
        }
      );
    });
  }

  private getFullConfig(): Observable<any> {
    return new Observable((observer) => {
      this.backend.get("/audit/api/full-config/").subscribe(
        (jsonData) => {
          observer.next(jsonData);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}
