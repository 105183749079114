<ion-grid no-padding>
  <ion-list no-lines *ngIf="question">
    <div class="question-header">
      {{ question.text }}
    </div>
    <div class="help-text color-unknown" *ngIf="question.help_text_subject">
      {{ question.help_text_subject }}
    </div>
    <div class="help-text color-unknown" *ngIf="question.help_text">
      {{ question.help_text }}
    </div>

    <div *ngFor="let item of question.items">
      <ion-item>
        <ion-label class="item-text">{{ item.year_number }}</ion-label>
        <ion-toggle
          class="toggle"
          color="good"
          [(ngModel)]="notes[item.id]"
          [disabled]="readOnly"
          (ngModelChange)="setNote($event, item.id)"
        >
        </ion-toggle>
      </ion-item>
    </div>
  </ion-list>
</ion-grid>
