import { Component, NgZone, OnInit } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ErrorsService } from "./services/errors.service";
import {
  combineLatest,
  forkJoin,
  from,
  Observable,
  of,
  Subscription,
} from "rxjs";
// import { Network } from "@ionic-native/network/ngx";
import { BackendService } from "./services/backend.service";
import { SynchronizationService } from "./services/synchronization.service";
import { SynchronizationStatus } from "./structs/synchronization";
import { SynthesisService } from "./services/synthesis.service";
import { AuditSynthesis, AuditSynthesisChange } from "./structs/audit";
import { OfflineService } from "./services/offline.service";
import { Router } from "@angular/router";
import { User } from "./structs/auth";
import { ConfigService } from "./services/config.service";
import { ScopeService } from "./services/scope.service";
import { AppRelease } from "./structs/base";
import { VersionsService } from "./services/versions.service";
import { VERSION } from "./app.version";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { ToastController, IonApp, Platform } from "@ionic/angular";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";
import {
  catchError,
  endWith,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Perimeter } from "./structs/assets";
import { PerimeterBudgetPlansService } from "./services/perimeter-budget-plan.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  defaultTitle: string = "capex intelligence";
  title: string = this.defaultTitle;
  backLink: any = null;
  version = "0.0.0";
  screenLevel: string = "level1";
  cssStyle: string = "";
  showHeader: boolean = true;
  isBackToControlPointActive: boolean = false;

  private onConnectSubscription: Subscription;
  private onDisconnectSubscription: Subscription;
  private watchSynchronizationSubscription: Subscription;
  private isSynchroVerbose: boolean;
  private synchronizationStartedMessage: string = "";
  private hasSynchroError: boolean;
  private synchronizationDoneMessage: string;
  private synchronizationPostponedMessage: string;
  private synchronizationElementDeletedtMessage: string;
  private auditSynthesisSubscription: Subscription;
  private onCurrentUserChangedSubscription: Subscription;

  constructor(
    private authApi: AuthService,
    private translate: TranslateService,
    private errors: ErrorsService,
    private network: Network,
    private backend: BackendService,
    private syncApi: SynchronizationService,
    private synthesis: SynthesisService,
    private offlineApi: OfflineService,
    private toastCtrl: ToastController,
    private router: Router,
    private config: ConfigService,
    private scope: ScopeService,
    private versionsApi: VersionsService,
    // private app: IonApp,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    private platform: Platform,
    private sync: SynchronizationService,
    private perimeterBudgetPlan: PerimeterBudgetPlansService
  ) {
    this.translate.setDefaultLang("en");
    this.translate.use(translate.getBrowserLang());

    this.platform.ready().then(() => {
      this.setupDeeplink();
    });
  }

  ngOnInit() {
    this.onConnectSubscription = this.network.onConnect().subscribe((event) => {
      console.log("APP_NETWORK_CONNECTED", event);
      this.backend.setConnected(true);
      this.backend.setNetworkStatus(this.network.type);
      this.displayNetworkStatus(event);
      this.syncApi.signalNetworkReadyForSynchronization(this.network.type);
    });

    this.onDisconnectSubscription = this.network
      .onDisconnect()
      .subscribe((event) => {
        console.log("APP_NETWORK_DISCONNECTED", event);
        this.backend.setConnected(false);
        this.backend.setNetworkStatus(this.network.type);
        this.syncApi.signalNetworkReadyForSynchronization("");
        this.displayNetworkStatus(event);
      });

    this.watchSynchronizationSubscription = this.syncApi
      .watchSynchronizationState()
      ?.subscribe(
        (synchronizationState) => {
          console.log("APP_SYNCHRONIZATION_STATE", synchronizationState);

          if (synchronizationState.status === SynchronizationStatus.PUSH) {
            console.log(">sync push");
            this.syncApi.pushOfflineChanges().subscribe(
              () => {
                // console.log(">sync push done");
              },
              (err) => {
                // console.error(">sync push error", err);
              }
            );
          }

          if (synchronizationState.status === SynchronizationStatus.VERBOSE) {
            this.isSynchroVerbose = true;
          }

          if (synchronizationState.status === SynchronizationStatus.STARTED) {
            // console.debug(">sync push started");
            if (this.isSynchroVerbose) {
              this.displaySynchronizationMessage(
                this.synchronizationStartedMessage,
                false,
                true,
                false
              );
            }
          }

          if (synchronizationState.status === SynchronizationStatus.DONE) {
            // console.debug(">sync push done", synchronizationState);
            if (this.hasSynchroError || this.isSynchroVerbose) {
              this.isSynchroVerbose = false;
              this.displaySynchronizationMessage(
                this.synchronizationDoneMessage,
                false,
                true,
                false
              );
            }
            this.hasSynchroError = false;
          }

          if (synchronizationState.status === SynchronizationStatus.POSTPONED) {
            // console.debug("sync push posponed", synchronizationState);
            this.hasSynchroError = true;
            if (this.isSynchroVerbose) {
              this.isSynchroVerbose = false;
              this.displaySynchronizationMessage(
                this.synchronizationPostponedMessage,
                false,
                true,
                false
              );
            }
          }

          if (
            synchronizationState.status ===
            SynchronizationStatus.CHANGE_TO_DO_ADDED
          ) {
            console.debug(">sync push added", synchronizationState);
          }

          if (
            synchronizationState.status === SynchronizationStatus.CHANGE_DONE
          ) {
            // console.log(">sync push: change done", synchronizationState);
          }

          if (
            synchronizationState.status === SynchronizationStatus.CHANGE_DELETED
          ) {
            console.debug(">sync push: deleted", synchronizationState);
            if (this.isSynchroVerbose) {
              this.displaySynchronizationMessage(
                this.synchronizationElementDeletedtMessage,
                false,
                true,
                true
              );
            }
          }
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );

    this.auditSynthesisSubscription = this.synthesis.watchSynthesis().subscribe(
      (synthesisChange: AuditSynthesisChange) => {
        // store assets and investments for offline Mode
        if (synthesisChange) {
          this.offlineApi.storeAuditElements(synthesisChange).subscribe(
            () => {},
            (err) => this.errors.signalError(err),
            () => {}
          );
        }
      },
      (err) => this.errors.signalError(err)
    );

    this.errors.watchErrors().subscribe(async (err: any) => {
      console.error("APP_GENERAL_ERROR", { error: err });

      if (err) {
        if (err.status && err.status === 401) {
          this.router.navigate(["/"]);
          // this.navCtrl.popToRoot();
        } else if (err.message !== "Offline") {
          const toast = await this.toastCtrl.create({
            message: err.message,
            duration: 3000,
            cssClass: err.offline ? "offline-toast" : "successToast audit",
            position: "bottom",
          });
          await toast.present();
        }
      }
    });

    this.onCurrentUserChangedSubscription = this.authApi
      .onCurrentUserChanged()
      .subscribe((user) => {
        this.authenticate(user);
      });
    this.authApi.initCurrentUser();
    this.onConnectSubscription = this.network.onConnect().subscribe((event) => {
      console.log("APP_NETWORK_CONNECTED", event);
      this.backend.setConnected(true);
      this.backend.setNetworkStatus(this.network.type);
      this.displayNetworkStatus(event);
      this.syncApi.signalNetworkReadyForSynchronization(this.network.type);
    });

    this.onDisconnectSubscription = this.network
      .onDisconnect()
      .subscribe((event) => {
        console.log("APP_NETWORK_DISCONNECTED", event);
        this.backend.setConnected(false);
        this.backend.setNetworkStatus(this.network.type);
        this.syncApi.signalNetworkReadyForSynchronization("");
        this.displayNetworkStatus(event);
      });

    this.watchSynchronizationSubscription = this.syncApi
      .watchSynchronizationState()
      .subscribe(
        (synchronizationState) => {
          console.log("APP_SYNCHRONIZATION_STATE", synchronizationState);

          if (synchronizationState.status === SynchronizationStatus.PUSH) {
            console.log(">sync push");
            this.syncApi.pushOfflineChanges().subscribe(
              () => {
                // console.log(">sync push done");
              },
              (err) => {
                // console.error(">sync push error", err);
              }
            );
          }

          if (synchronizationState.status === SynchronizationStatus.VERBOSE) {
            this.isSynchroVerbose = true;
          }

          if (synchronizationState.status === SynchronizationStatus.STARTED) {
            // console.debug(">sync push started");
            if (this.isSynchroVerbose) {
              this.displaySynchronizationMessage(
                this.synchronizationStartedMessage,
                false,
                true,
                false
              );
            }
          }

          if (synchronizationState.status === SynchronizationStatus.DONE) {
            // console.debug(">sync push done", synchronizationState);
            if (this.hasSynchroError || this.isSynchroVerbose) {
              this.isSynchroVerbose = false;
              this.displaySynchronizationMessage(
                this.synchronizationDoneMessage,
                false,
                true,
                false
              );
            }
            this.hasSynchroError = false;
          }

          if (synchronizationState.status === SynchronizationStatus.POSTPONED) {
            // console.debug("sync push posponed", synchronizationState);
            this.hasSynchroError = true;
            if (this.isSynchroVerbose) {
              this.isSynchroVerbose = false;
              this.displaySynchronizationMessage(
                this.synchronizationPostponedMessage,
                false,
                true,
                false
              );
            }
          }

          if (
            synchronizationState.status ===
            SynchronizationStatus.CHANGE_TO_DO_ADDED
          ) {
            console.debug(">sync push added", synchronizationState);
          }

          if (
            synchronizationState.status === SynchronizationStatus.CHANGE_DONE
          ) {
            // console.log(">sync push: change done", synchronizationState);
          }

          if (
            synchronizationState.status === SynchronizationStatus.CHANGE_DELETED
          ) {
            console.debug(">sync push: deleted", synchronizationState);
            if (this.isSynchroVerbose) {
              this.displaySynchronizationMessage(
                this.synchronizationElementDeletedtMessage,
                false,
                true,
                true
              );
            }
          }
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );

    this.auditSynthesisSubscription = this.synthesis.watchSynthesis().subscribe(
      (synthesisChange: AuditSynthesisChange) => {
        // store assets and investments for offline Mode
        if (synthesisChange) {
          this.offlineApi.storeAuditElements(synthesisChange).subscribe(
            () => {},
            (err) => this.errors.signalError(err),
            () => {}
          );
        }
      },
      (err) => this.errors.signalError(err)
    );

    this.errors.watchErrors().subscribe(async (err) => {
      console.error("APP_GENERAL_ERROR", { error: err });

      if (err) {
        if (err.status && err.status === 401) {
          this.router.navigate(["/"]);
        } else if (err.message !== "Offline") {
          const toast = await this.toastCtrl.create({
            message: err.message,
            duration: 3000,
            cssClass: err.offline ? "offline-toast" : "successToast audit",
            position: "bottom",
          });
          await toast.present();
        }
      }
    });
    this.authApi.initCurrentUser();

    // If we think an app-component is a shell to include all child component
    // we need to make sure the shell loads its content
    // loading the config will include: load the currency, i18n,...
    this.loadConfig();
  }

  displayNetworkStatus(event) {
    // CAP-120: Don't display the toast control.
    // let message = event.type;
    // if (event.type === 'online') {
    //   message += ': ' + this.network.type;
    // }
    //
    // let toast = this.toastCtrl.create({
    //   message: message,
    //   duration: 3000,
    //   position: 'bottom'
    // });
    // // code left here in case we change our minds!
    // toast.present();
  }

  displaySynchronizationMessage(
    message: string,
    permanent: boolean,
    hidePermanent: boolean,
    showCloseButton: boolean
  ) {
    // For the moment, we decide to hide the synchronization messages
    console.log(message, permanent);

    // if (hidePermanent && this.synchronizationProgressToast) {
    //   this.synchronizationProgressToast.dismiss();
    //   this.synchronizationProgressToast = null;
    // }
    //
    // if (permanent && this.synchronizationProgressToast) {
    //   return;
    // }
    //
    // let options: any = {
    //   message: message,
    //   cssClass: 'offline-toast',
    //   position: 'bottom'
    // };
    // if (!permanent && !showCloseButton) {
    //   options.duration = 5000;
    // }
    // if (showCloseButton) {
    //   options.showCloseButton = true;
    // }
    //
    // let toast = this.toastCtrl.create(options);
    // toast.present();
    // if (permanent) {
    //   this.synchronizationProgressToast = toast;
    // }
  }

  authenticate(user: User) {
    if (user && user.apiKey) {
      this.loadConfig();
    } else {
      this.scope.clearPerimetersCache();
    }
  }

  loadConfig() {
    this.config.loadConfig().subscribe(() => {
      this.versionsApi
        .getLastReleaseVersion()
        .subscribe((release: AppRelease) => {
          if (release) {
            this.versionsApi
              .checkRelease(VERSION, release)
              .subscribe(async (newRelease: AppRelease) => {
                if (newRelease) {
                  const updateMessage =
                    this.translate.instant("A new version is available") +
                    ": " +
                    newRelease.version;
                  const toast = await this.toastCtrl.create({
                    message: updateMessage,
                    duration: 3000,
                    position: "bottom",
                    translucent: true,
                  });
                  await toast.present();
                }
              });
          }
        });
    });
  }

  private catchError(err: any): Observable<null> {
    console.warn("Deeplink error: " + err);
    return of(null);
  }

  private setupDeeplink() {
    const deeplinkRoute$ = this.deeplinks.route({
      // mapping between deepllink and angular route
      "/perimeters/:multiPerimeterId/asset-detail/:assetId": "AssetDetail",
      "/perimeters/:multiPerimeterId/investment-detail/:investId":
        "InvestmentDetail",
    });

    deeplinkRoute$
      .pipe(
        filter((match) => !!match),
        switchMap((match) => {
          const route = match.$route;
          if (route === "AssetDetail") {
            return from(this.router.navigate([match.$link.path]));
          }
          if (route === "InvestmentDetail") {
            return from(this.router.navigate([match.$link.path]));
          }
        }),
        catchError((err) => this.catchError(err))
      )
      .subscribe();
  }

  private getPerimeterById(perimeterId: number): Observable<Perimeter> {
    return new Observable((observer) => {
      this.scope.getPerimeters().subscribe(
        (perimeters) => {
          let perimeter = perimeters.find((p) => p.id === perimeterId);
          observer.next(perimeter);
        },
        (err) => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  /**
   * Get synthesis online and make sure all changes are pushed
   * @param perimeter
   * @param year
   * @private
   */
  private getOnlineSynthesis(
    perimeter: Perimeter,
    year: number
  ): Observable<AuditSynthesis> {
    return this.sync.pushOfflineChanges().pipe(
      filter((changes) => changes),
      switchMap(() => this.synthesis.getAuditSynthesis(perimeter, year))
    );
  }
}
