import * as Sentry from "sentry-cordova";
import { Environment } from "./app.environment";
import { ErrorHandler } from "@angular/core";

export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      if (Environment.getSentryDSN()) {
        Sentry.captureException(error.originalError || error);
      }
    } catch (e) {
      console.warn("Could not send to sentry");
      console.error(e);
    }
  }
}
