<ion-item-sliding #item>
  <ion-item
    (click)="onItemClick($event)"
    [style]="assetStyle"
    [ngClass]="highlighted ? 'highlighted' : null"
  >
    <ion-label class="asset">
      <!-- Thumbnail picture -->
      <div class="picture" (click)="pictureClicked($event)">
        <app-picture
          [picture]="picture"
          [picturesOn]="picturesOn"
        ></app-picture>
      </div>

      <!-- Show the asset label with a baby icon if this is a child + the import / sync icon-->
      <div class="info" *ngIf="asset">
        <div
          class="text-overflow-ellipsis cropped-label"
          (click)="onAssetClick($event)"
        >
          <ng-container *ngIf="asset.label">
            <span
              *ngIf="asset.quantity > 1 && !asset.assetType.quantity_unit"
              class="asset-quantity"
            >
              {{ asset.quantity }}</span
            >
            <span *ngIf="!asset.parent" class="asset-label">
              {{ asset.label }}</span
            >
            <span *ngIf="asset.parent" class="child-label">
              <i class="fal fa-baby children-icon"></i> {{ asset.label }}
            </span>
          </ng-container>

          <span *ngIf="!asset.label">{{ asset.assetType.name }}</span>
        </div>

        <!--Show the quantity if there ARE units -->
        <div
          class="perimeter-label text-overflow-ellipsis"
          (click)="onAssetClick($event)"
        >
          <span *ngIf="asset.assetType.quantity_unit"
            >{{ "Quantity" | translate }} : {{ asset.quantity }}
            {{ asset.assetType.quantity_unit.name }}
          </span>
          <span
            *ngIf="asset.assetType.quantity_unit"
            style="padding-left: 1em"
          ></span>
          <span
            >{{ "Perimeter" | translate }} : {{ getPerimeterName(asset) }}</span
          >
        </div>

        <!--The details of previous audits-->
        <!-- The text needs to be bold if the asset is in a critical state -->
        <div
          class="last-audit-informations"
          *ngIf="lastAuditInformation"
          (click)="onAssetClick($event)"
        >
          <span
            *ngIf="isCritical"
            class="text-overflow-ellipsis heavy-caption"
            >{{ lastAuditInformation }}</span
          >
          <span *ngIf="!isCritical" class="text-overflow-ellipsis">{{
            lastAuditInformation
          }}</span>
        </div>

        <!-- If there was no audit, only put the text in RED if this asset is NOT a child (level 3 or 4)-->
        <div
          *ngIf="!lastAuditInformation && !loading"
          (click)="onAssetClick($event)"
          class="last-audit-informations text-overflow-ellipsis"
        >
          <span *ngIf="!isComponentLevel" class="no-audit-informations">{{
            "No technical state" | translate
          }}</span>
          <span *ngIf="isComponentLevel">{{
            "No technical state" | translate
          }}</span>
        </div>

        <div *ngIf="loading">
          <ion-spinner name="lines" color="secondary"></ion-spinner>
        </div>

        <!-- Show the number & total price of investments -->
        <div class="investment-label">
          <span *ngIf="asset.investments.length === 0">
            {{ "No investment planned" | translate }}
          </span>
          <span *ngIf="asset.investments.length > 0">
            {{ asset.investments.length }}
            <span *ngIf="asset.investments.length === 1">
              {{ "investment" | translate }}
              <span style="text-transform: lowercase">{{
                asset.investments[0].status.name
              }}</span>
            </span>
            <span *ngIf="asset.investments.length > 1">
              {{ "investment(s)" | translate }}
            </span>
            {{ "in" | translate }} {{ investmentSliceYears }}
            {{ "for" | translate }} {{ investmentsSum | currency }}
          </span>
        </div>

        <!-- if there are child investments, show the number & total -->
        <div *ngIf="childInvestmentTotal > 0" class="investment-label">
          {{ numberChildInvestments }}
          {{ "child investment(s) for a total of" | translate }}
          {{ childInvestmentTotal | currency }}
        </div>
      </div>
    </ion-label>

    <!-- show an alarm when there are defects (KPI Expert) -->
    <div class="left-buttons defects-count" item-content *ngIf="defectsCount">
      <i class="fal fa-bell defect-icon"></i> ({{ defectsCount }})
    </div>
    <div class="right-buttons">
      <!-- on the same line show the import / synchronisation status -->
      <!-- (removed from migration) -->
      <!-- <div class="import-status">
        <import-status-badge
          [asset]="asset"
          *ngIf="!disableImportIcon"
          [cmmsSync]="cmmsSync"
        ></import-status-badge>
      </div> -->

      <!-- Show the child icon -->
      <div class="children-button" slot="content">
        <div
          *ngIf="asset.children.length && childrenMode"
          (click)="toggleChildren($event)"
          [ngClass]="showChildren ? 'color-child-asset' : 'color-gray'"
        >
          <i class="fal fa-baby children-icon"></i> ({{
            asset.children.length
          }})
        </div>
      </div>
    </div>
  </ion-item>

  <!-- item slide actions -->
  <ion-item-options *ngIf="itemOptions" class="slide-buttons">
    <ion-item-option
      (click)="openSynchronization(item, asset)"
      *ngIf="cmmsSync"
    >
      <i class="fal fa-sync"></i>
    </ion-item-option>
    <ion-item-option
      (click)="deleteAsset(item, asset)"
      color="primary"
      *ngIf="itemOptions.deleteAsset"
    >
      <i class="fa-light fa-trash"></i>
    </ion-item-option>
    <ion-item-option
      (click)="cloneAsset(item, asset)"
      *ngIf="itemOptions?.cloneAsset"
      color="asset"
    >
      <i class="fa-thin fa-clone"></i>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>

<ng-container *ngIf="asset.children.length && showChildren && childrenMode">
  <asset-item
    *ngFor="let child of asset.children"
    [asset]="child"
    [parent]="asset"
    (assetClick)="onChildClick($event)"
    (investmentClick)="openInvestment($event)"
    [picturesOn]="picturesOn"
    [itemOptions]="itemOptions"
    (cloneAssetClicked)="cloneChild(item, child)"
    (pictureClick)="pictureClick.emit($event)"
    (deleteAssetClicked)="deleteAsset(item, child)"
  >
  </asset-item>
</ng-container>
