import { Injectable } from "@angular/core";

import { Observable, ReplaySubject } from "rxjs";

import { TranslateService } from "@ngx-translate/core";

export class Error {
  constructor(
    public status: number,
    public message: string,
    public offline: boolean = false
  ) {}
}

@Injectable()
export class ErrorsService {
  lastErr = null;
  errors: ReplaySubject<Error> = new ReplaySubject(1);
  constructor(private translate: TranslateService) {}

  watchErrors(): Observable<Error> {
    return this.errors.asObservable();
  }

  /**
   * handle errors : inform all
   * offline = True : means that the app has recovered using offline data
   */
  signalError(err: any, offline: boolean = false) {
    this.getErrorMessage(err).subscribe((errorText: string) => {
      this.errors.next(new Error(err.status, errorText, offline));
    });
  }

  /**
   * Try to extract a message from error object
   * @param err
   * @returns {Observable<string>}
   */
  getErrorMessage(err: any): Observable<string> {
    return Observable.create((observer) => {
      console.error(err);
      // Try to build a message error
      if (typeof err === "string") {
        observer.next(err);
        observer.complete();
      } else {
        if (err.status === 0) {
          this.translate.get("Network error").subscribe((text) => {
            // observer.next(text);  // keep it silent
            console.log(">", text);
            observer.complete();
          });
          console.log(">", "Network error");
          observer.complete();
        } else if (err.status === 404) {
          observer.next(err.statusText);
          observer.complete();
        } else if (err.status === 500) {
          observer.next(err.statusText);
          observer.complete();
        } else {
          try {
            if ("_body" in err && err._body) {
              try {
                let errorText: string = "";
                let body = JSON.parse(err._body);
                if (Array.isArray(body)) {
                  for (let i = 0, l = body.length; i < l; i++) {
                    let item = body[i];
                    for (let elt in item) {
                      if (errorText) {
                        errorText += "\n";
                      }
                      errorText += item[elt];
                    }
                  }
                } else {
                  for (let elt in body) {
                    if (errorText) {
                      errorText += "\n";
                    }
                    errorText += body[elt];
                  }
                }

                observer.next(errorText);
                observer.complete();
              } catch (exc) {
                observer.next(err._body);
                observer.complete();
              }
            } else {
              if (err.status && err.statusText) {
                observer.next(err.statusText);
                observer.complete();
              } else {
                observer.next(err);
                observer.complete();
              }
            }
          } catch (exc2) {
            if (err.status && err.statusText) {
              observer.next(err.statusText);
              observer.complete();
            } else {
              observer.next(err);
              observer.complete();
            }
          }
        }
      }
    });
  }
}
