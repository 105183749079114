import { Injectable } from "@angular/core";
// import { TranslateService } from '@ngx-translate/core';
import { Observable } from "rxjs";
import { Perimeter } from "../structs/assets";

/**
 * Service to help generate title from complex inputs.
 */
@Injectable()
export class TitleService {
  private noPerimeterLabel: string;

  constructor() {
    // private translate: TranslateService,
    // this.translate.get('services.title.no_perimeter').subscribe(
    //   (noPerimeterLabel) => this.noPerimeterLabel = noPerimeterLabel
    // );
    this.noPerimeterLabel = "services.title.no_perimeter";
  }

  /**
   * Generates a title for a set of perimeters.
   *
   * @param perimeters The perimeters to generate the perimeters from.
   * @param forceSingle If `true` (default), set with only one perimeter will give this perimeter name as title.
   */
  public fromPerimeters(
    perimeters: Perimeter[],
    forceSingle: boolean = true
  ): Observable<string> {
    return new Observable((observer) => {
      if (perimeters.length === 0) {
        observer.next(this.noPerimeterLabel);
        observer.complete();
      } else if (forceSingle && perimeters.length === 1) {
        observer.next(perimeters[0].name);
        observer.complete();
      } else {
        // return this.translate.get(
        //   'services.title.multiple_perimeters', { nbPerimeters: perimeters.length }
        // );
        observer.next("services.title.multiple_perimeters" + perimeters.length);
        observer.complete();
      }
    });
  }
}
